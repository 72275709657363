/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect, useState, Fragment } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import * as S from './utils/userList.style';
import {
  SelectMenuEnum,
  selectMenuType,
  subModules
} from './utils/userList.types';
import { useAuth } from '../../../../../../hooks/Auth';

const LayoutMenu: FC = () => {
  const { role } = useAuth();
  const navigate = useNavigate();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const subMenu: string[] = subModulo
    .split('&')
    .map((item) => item.replace(/\n\s+/g, ''))
    .filter((item) => item && `${item}` in subModules);

  const [selectedMenu, setSelectedMenu] = useState<selectMenuType>(
    isMaster ? subModules.ADM : subModules[subMenu[0]]
  );
  console.log(
    'selectedMenu',
    isMaster ? subModules.ADM : subModules[subMenu[0]]
  );
  console.log('isMaster', isMaster);
  console.log('selectedMenu', subModules.ADM);
  console.log('selectedMenu', subModules[subMenu[0]]);
  console.log(sistema);
  console.log(modulo);
  console.log(subModulo);
  console.log(subMenu);
  console.log(selectedMenu);
  useEffect(() => {
    selectedMenu === SelectMenuEnum.Users && navigate('./Users');
    selectedMenu === SelectMenuEnum.Farms && navigate('./Farms');
    selectedMenu === SelectMenuEnum.UserFarms && navigate('./UserFarms');
    selectedMenu === SelectMenuEnum.Profile && navigate('./Profile');
    selectedMenu === SelectMenuEnum.ModulesSystem &&
      navigate('./ModulesSystem');
    selectedMenu === SelectMenuEnum.UserProfile && navigate('./UserProfile');
    selectedMenu === SelectMenuEnum.ProfileModule &&
      navigate('./ProfileModule');
    selectedMenu === SelectMenuEnum.Organizacao && navigate('./Organizacao');
    selectedMenu === SelectMenuEnum.OrganizacaoProfile &&
      navigate('./OrganizacaoProfile');
    selectedMenu === SelectMenuEnum.SubModule && navigate('./SubModule');
    selectedMenu === SelectMenuEnum.Adm && navigate('./Adm');
    selectedMenu === SelectMenuEnum.Parameters && navigate('./Parameters');
    selectedMenu === SelectMenuEnum.Pragues && navigate('./Pragues');
    selectedMenu === SelectMenuEnum.Disease && navigate('./Disease');
    selectedMenu === SelectMenuEnum.Weeds && navigate('./Weeds');
    selectedMenu === SelectMenuEnum.Farms && navigate('./Farms');
    selectedMenu === SelectMenuEnum.ProfileSubModule &&
      navigate('./ProfileSubModule');
    console.log('selectedMenu', selectedMenu);
    selectedMenu === SelectMenuEnum.Safras && navigate('./Safras');
  }, [selectedMenu]);

  return (
    <S.Container>
      <S.Title>Administração</S.Title>

      <Fragment>
        <S.NavContainer>
          {subMenu.includes('ADM') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Adm}
              index={0}
              onClick={() => setSelectedMenu('adm')}
            >
              Administração
            </S.NavButton>
          )}

          {subMenu.includes('Parametros') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Parameters}
              index={1}
              onClick={() => setSelectedMenu('parameters')}
            >
              Parâmetros
            </S.NavButton>
          )}
          {subMenu.includes('Safras') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Safras}
              index={2}
              onClick={() => setSelectedMenu('Safras')}
            >
              Safras
            </S.NavButton>
          )}
          {subMenu.includes('Pragas') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Pragues}
              index={3}
              onClick={() => setSelectedMenu('pragues')}
            >
              Pragas
            </S.NavButton>
          )}
          {subMenu.includes('Doencas') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Disease}
              index={4}
              onClick={() => setSelectedMenu('disease')}
            >
              Doenças
            </S.NavButton>
          )}
          {subMenu.includes('ErvasDaninhas') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Weeds}
              index={5}
              onClick={() => setSelectedMenu('weeds')}
            >
              Ervas Daninhas
            </S.NavButton>
          )}

          {/* <S.NavButton
            active={selectedMenu === SelectMenuEnum.Farms}
            index={5}
            onClick={() => setSelectedMenu('farms')}
          >
            Cadastro de Fazenda
          </S.NavButton> */}

          {subMenu.includes('UsuarioFazendas') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.UserFarms}
              index={6}
              onClick={() => setSelectedMenu(SelectMenuEnum.UserFarms)}
            >
              Usuário x Fazendas
            </S.NavButton>
          )}
          {subMenu.includes('Usuarios') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Users}
              index={7}
              onClick={() => setSelectedMenu(SelectMenuEnum.Users)}
            >
              Usuários
            </S.NavButton>
          )}
          {subMenu.includes('UsuarioPerfil') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.UserProfile}
              index={8}
              onClick={() => setSelectedMenu(SelectMenuEnum.UserProfile)}
            >
              Usuario x Perfil
            </S.NavButton>
          )}
          {/* <S.NavButton
                        active={selectedMenu === selectMenu.farms}
                        index={1}
                        onClick={() => setSelectedMenu(selectMenu.farms)}
                    >
                        Fazendas
                    </S.NavButton> */}

          {subMenu.includes('Perfis') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Profile}
              index={9}
              onClick={() => setSelectedMenu(SelectMenuEnum.Profile)}
            >
              Perfis
            </S.NavButton>
          )}
          {/* {subMenu.includes('PerfilModulo') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.ProfileModule}
              index={10}
              onClick={() => setSelectedMenu(SelectMenuEnum.ProfileModule)}
            >
              Perfil x Módulo
            </S.NavButton>
          )} */}
          {subMenu.includes('Modulos') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.ModulesSystem}
              index={11}
              onClick={() => setSelectedMenu(SelectMenuEnum.ModulesSystem)}
            >
              Módulos
            </S.NavButton>
          )}

          {/* {subMenu.includes('PerfilSubModulo') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.ProfileSubModule}
              index={12}
              onClick={() => setSelectedMenu(SelectMenuEnum.ProfileSubModule)}
            >
              Perfil x Sub-Modulo
            </S.NavButton>
          )} */}

          {subMenu.includes('SubModulo') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.SubModule}
              index={13}
              onClick={() => setSelectedMenu(SelectMenuEnum.SubModule)}
            >
              Sub-Modulo
            </S.NavButton>
          )}
          {/* {subMenu.includes('PerfilOrganizacao') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.OrganizacaoProfile}
              index={14}
              onClick={() => setSelectedMenu(SelectMenuEnum.OrganizacaoProfile)}
            >
              Perfil x Organização
            </S.NavButton>
          )} */}
          {subMenu.includes('Organizacao') && (
            <S.NavButton
              active={selectedMenu === SelectMenuEnum.Organizacao}
              index={15}
              onClick={() => setSelectedMenu(SelectMenuEnum.Organizacao)}
            >
              Organização
            </S.NavButton>
          )}
        </S.NavContainer>
        <hr className="nav__hr" />
      </Fragment>
      <Fragment>
        <Outlet />
      </Fragment>
    </S.Container>
  );
};

export default LayoutMenu;
