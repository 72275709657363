/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { User } from '../utils/users.types';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { useUsersRequest } from './hooks/useUsersRequest';

const UsersList: FC = () => {
  const { request, onChangeFilter } = useUsersRequest();
  const uniqueUsers = request.items.filter(
    (user, index, self) =>
      index === self.findIndex((u) => u.email === user.email)
  );

  const headCells: HeadCell<User>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email'
    },
    {
      id: 'tel',
      numeric: false,
      disablePadding: false,
      label: 'Telefone'
    },
    {
      id: 'cpfCnpj',
      numeric: false,
      disablePadding: false,
      label: 'CPF/CNPJ'
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  return (
    <>
      <ResearchField onChange={onChangeFilter} />
      <OrderedTable
        rows={uniqueUsers}
        HeadCell={headCells}
        IconCreate={PersonAddAlt1Icon}
        title="Usuários"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
        selectInLine
      />

      <Outlet />
    </>
  );
};

export default UsersList;
