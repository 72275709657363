import { appConfig } from '../config';
import api from './api';

export interface Routines {
  routine_id: string;
  routine_type_id: string;
  routine_type_name: string;
  encompass_id: string;
  encompass_name: string;
  title: string;
  init_date: Date;
  end_date: Date;
  period: string;
  period_name: null;
  crop_type_name: null;
  tecnician_id: string;
  technician_name: null;
  instant_monitoring: boolean;
  note: string;
  status: string;
  status_name: string;
  farm_id: string;
  created_by: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  executor_id: string;
  executor_name: string;
  lote: null;
  recurrence: null;
  routine_field_id: string;
  routine_field_name: null;

  reference?: string;
}

export namespace HistoriesByRoutine {
  export async function getHistoriesByRoutine(
    routine_id: string,
    token: string
  ) {
    const { data } = await api.get(
      `${appConfig.apiHosts.prague}/Routine/HistoriesByRoutine?routine_id=${routine_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const { historieRoutines } = data.data;

    return historieRoutines;
  }
}
