import React from 'react';
import * as Icons from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IconProps extends SvgIconProps {
  iconName: keyof typeof Icons;
  style?: React.CSSProperties;
}

export const Icon: React.FC<IconProps> = ({ iconName, style, ...props }) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    console.warn(`Icon "${iconName}" does not exist in @mui/icons-material`);
    return null;
  }

  return (
    <SvgIcon
      component={IconComponent}
      {...props}
      style={{ color: props.color ? props.color : '#fff' }}
    />
  );
};
