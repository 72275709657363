import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../utils/profile.types';

export const useProfileListRequest = () => {
  const {
    token,
    user: { organizacao_id, user_id }
  } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const [filter, setFilter] = useState<string>('');
  const ProfileDate = useQuery([profileApi.getProfiles, { filter }], async () =>
    profileApi.ProfileList(filter, organizacao_id, user_id)
  );
  function profileMap(date: any, index): Profile {
    return {
      idEndity: date.userprofile_id,
      id: index,
      name: date.description,
      activated: date.active ? 'Ativado' : 'Desativado',
      nomeOrganizacao: date.nomeOrganizacao,
      organizacaoId: date.organizacao_id,
      isFarmOwner: date.isFarmOwner
    };
  }

  return {
    request: {
      items: ProfileDate.data?.data.data.map(profileMap) ?? [],
      refetch: ProfileDate.refetch
    },
    setFilter
  };
};
