/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
// @ts-ignore
import $ from 'jquery';
import React, { createContext, useContext, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'react-toastify';
import { MdCropLandscape, MdCropPortrait } from 'react-icons/md';
import Button from '../../../../components/Button';
import CardSelect from '../../../../components/CardSelect';
import Toast from '../../../../components/Toast';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import * as S from './styled';
import RequestOwnerNameModal from './components/RequestOwnerNameModal';
import { useModal } from '../../../../hooks/Modal';
import { reportDepthParamMultiplePages } from '../../../../reports/reportDepthParamMultiplePages';
import { reportDepthParamMultiplePagesLandscape } from '../../../../reports/reportDepthParamMultiplePagesLandscape';
import { reportDepthParam } from '../../../../reports/reportDepthParam';
import { useFullLoading } from '../../../../hooks/FullLoading';
import { getDepth, setDepth } from '../../../../helpers/parseDephPoint';
import { SwitchButton } from '../../../../components/SwitchButton';
import { reportDepthParamLandscape } from '../../../../reports/reportDepthParamLandscape';
import { appConfig } from '../../../../config';
import { ThreadUtils } from '../../../../util/Thread.Utils';
import { SubdivisionsService } from '../../../../services/Subdivisions.Service';

export const ReportContext = createContext({});

interface SubdivisonColors {
  param: string;
  depth: string;
  color: any[];
}

const subdivisonColors: SubdivisonColors[] = [];

const Report = () => {
  const [param, setParam] = useState('');
  const [layer, setLayer] = useState('');
  const [listMapsVisible, setListMapsVisible] = useState(false);

  const ParamOptions = [
    'Argila',
    'MO',
    'MO x Arg',
    'PH',
    'Ca',
    'Mg',
    'K',
    'AI',
    'CTC',
    'V%',
    'P',
    'Sal. AI',
    'S'
  ];

  const DepthOptions = [
    '0-10',
    '0-20',
    '0-20-40',
    '0-10-20',
    '0-10-20-40',
    '0-10-20-30-40',
    '0-10-20-30-40-60'
  ];

  const mapId = nextId();
  const [table, setTable] = useState('');
  const [loading, setLoading] = useState(false);

  const [cacheSubdivisions, setCacheSubdivisions] = useState(new Map());
  const [cacheFields, setCacheFields] = useState(new Map());

  const { token, user } = useAuth();

  const { setSubdivisions, setResultVisible, setFields, setShowResult, fields, showResult, resultVisible } =
    useContext(DashboardContext);

  const {
    selectedFarmId,
    setListSubdivisions,
    renderSubdivisions,
    setLegendResultsValue,
    renderSubdivisionsWithColors,
    listSubdivisions,
    removeMappingGeometries,
    removePointGeometries,
    reports,
    setListFields,
    renderFieldsNoLabel,
    renderFields,
    refMap,
    removeGeometriesColoredSubdivisions,
    removeRecommendationZone,
    removeColoredRecommendationZone,
    reportEditId,
    selectedFarmGeoJson,
    renderFarm,
    removeSubdivisionGeometries,
    removeFieldGeometries,
    croquis,
    setCroquis,
    renderCollectionPoints,
    renderSubdivisionsNoLabel,
    setReports,
    mapScale,
    scale,
    setScale,
    updateMapScale,
    zoomInGeometry,
    orientation,
    setOrientation
  } = useMap();

  const [disableUnicReport, setDisableUnicReport] = useState(true);

  useEffect(() => {
    $('#scale').on('keypress', (event) => {
      if (event.charCode < 46 || event.charCode > 57) return false;
    });
  }, []);

  useEffect(() => {
    if (reportEditId && reports) {
      setLayer(reports?.filter((value) => value.id === reportEditId)[0].layer);
      setParam(reports?.filter((value) => value.id === reportEditId)[0].param);
    }
    selectedFarmGeoJson && zoomInGeometry(selectedFarmGeoJson);
    const olScale = document.getElementsByClassName('ol-scale-text')[0];

    // setScale(Number(document.getElementsByClassName('ol-scale-text')[0].innerHTML.split(':')[1]));
    if (olScale) {
      setScale(
        document
          .getElementsByClassName('ol-scale-text')[0]
          .innerHTML.split(':')[1]
          .replaceAll('&nbsp;', '')
      );
    }

    removeRecommendationZone();
  }, []);

  const getSubdivisions = async () => {
    const hasSubdivisions = cacheSubdivisions.has('subdivisions');

    try {
      if (hasSubdivisions) {
        const subdivisions = cacheSubdivisions.get('subdivisions');
        setSubdivisions(subdivisions);
        renderSubdivisions(subdivisions, '#9FBC38');
        setListSubdivisions(subdivisions);
        return;
      }

      const data = await SubdivisionsService.getSubdivisionsByFarmId(
        selectedFarmId,
        user.user_id,
        token
      );

      setCacheSubdivisions((prev) => {
        const updatedMap = new Map(prev);
        updatedMap.set('subdivisions', data);
        return updatedMap;
      });

      setSubdivisions(data);
      renderSubdivisions(data, '#9FBC38');
      setListSubdivisions(data);
    } catch (error) {
      console.log('getSubdivisions:: ', error);
    }
  };

  const getFields = async () => {
    const hasFields = cacheFields.has('fields');

    try {
      if (hasFields) {
        const fields = cacheFields.get('fields');
        setFields(fields);
        setListFields(fields);
        renderFields(fields, '#000');
        return;
      }

      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);

      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setCacheFields((prev) => {
        const updatedMap = new Map(prev);
        updatedMap.set('fields', response.data.data);
        return updatedMap;
      });

      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data, '#000');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const handleViewParam = async (parameter: any, depth: any) => {
    try {
      if (depth === '20-40' || depth === '0-20-40' || depth === '0-10-20-40') {
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);

        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);

        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subcolor: any[] = [];
        resp02040.data.data.forEach((v) => {
          subcolor.push(v);
        });

        resp0102040.data.data.forEach((v) => {
          subcolor.push(v);
        });

        subdivisonColors.push({
          param: parameter,
          depth,
          color: subcolor
        });
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', depth]
        ]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        subdivisonColors.push({
          param: parameter,
          depth,
          color: response.data.data
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getColorOnePage = async (parameter, depth) => {
    try {
      if (depth === '20-40' || depth === '0-20-40' || depth === '0-10-20-40') {
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);
        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);

        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subdivisonsColors = [];
        subdivisonsColors.push(resp02040.data.data);

        resp0102040.data.data.forEach((color) => {
          subdivisonsColors[0].push(color);
        });

        renderSubdivisionsWithColors(
          listSubdivisions,
          subdivisonsColors[0],
          '#000'
        );
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', setDepth(depth)]
        ]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const paramsSubdivision = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['userId', user.user_id]
        ]);
        const responseSub = await api.get(
          `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
          {
            params: paramsSubdivision,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        renderSubdivisionsWithColors(
          responseSub.data.data,
          response.data.data,
          '#000'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { openModal } = useModal();

  const getPoints = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);

    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const cPoints = await response.data.data.filter(
        (value) => value.features[0].properties.type === 'REFERENCIA_COLETA'
      );

      renderCollectionPoints(cPoints, '#9FBC38');
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getSubdivisions();
    getFields();
    getColorOnePage(param, layer);
  }, [param, layer]);

  const { activeLoading, desactiveLoading, setloadingMessage } =
    useFullLoading();

  useEffect(() => {
    if (loading) {
      return activeLoading();
    }
    setloadingMessage();
    desactiveLoading();
  }, [loading]);

  useEffect(() => {
    (async () => {
      if (selectedFarmId) {
        activeLoading();
        setloadingMessage('Ajustando Mapa');
        if (croquis.length === 0 && selectedFarmGeoJson) {
          renderFarm(selectedFarmGeoJson, '#000', 14.2);

          await getSubdivisions();
          await getFields();
        }

        setScale(mapScale().replaceAll('&nbsp;', '.'));
        desactiveLoading();
        setloadingMessage();
      }
    })();

    refMap.updateSize();
  }, [orientation]);

  useEffect(() => {
    if (!selectedFarmId) {
      toast(
        <Toast
          title="Por favor selecione uma fazenda"
          content="Para que as subdivisões sejam carregadas é nescessário selecionar uma fazenda"
        />,
        { type: 'warning' }
      );
    }

    setResultVisible(true);
    setLegendResultsValue([]);
    removeMappingGeometries();
    removePointGeometries();
    removeColoredRecommendationZone();
  }, []);

  const handleClick = async () => {
    setTimeout(() => {
      const canvas = document.getElementsByTagName('canvas')[0];
      const imgData = canvas.toDataURL('image/png');
      const report: any =
        orientation === 'Retrato'
          ? reportDepthParam({
              reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
              farmName: selectedFarmGeoJson.features[0].properties.farm_name,
              depth: layer,
              param,
              mapImage: imgData,
              scale: mapScale().replaceAll('&nbsp;', '')
            })
          : reportDepthParamLandscape({
              reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
              farmName: selectedFarmGeoJson.features[0].properties.farm_name,
              depth: layer,
              param,
              mapImage: imgData,
              scale: mapScale().replaceAll('&nbsp;', '')
            });

      pdfMake.createPdf(report).open();
    }, 1000);
  };

  let ordenedReports: any = {
    argila: [],
    mo: [],
    ph: [],
    ca: [],
    mg: [],
    k: [],
    ai: [],
    ctc: [],
    v: [],
    sal: [],
    moarg: [],
    p: [],
    s: []
  };

  const exportReportPages = async (
    ownerName,
    reportPages,
    farmName,
    harvestName,
    localName,
    date,
    responsible
  ) => {
    setLoading(true);
    setloadingMessage('Gerando PDF');

    reports.forEach((value) => {
      if (value.param === 'Argila') {
        return ordenedReports.argila.push(value);
      }
      if (value.param === 'MO') {
        return ordenedReports.mo.push(value);
      }
      if (value.param === 'PH') {
        return ordenedReports.ph.push(value);
      }
      if (value.param === 'Ca') {
        return ordenedReports.ca.push(value);
      }
      if (value.param === 'Mg') {
        return ordenedReports.mg.push(value);
      }
      if (value.param === 'K') {
        return ordenedReports.k.push(value);
      }
      if (value.param === 'AI') {
        return ordenedReports.ai.push(value);
      }
      if (value.param === 'CTC') {
        return ordenedReports.ctc.push(value);
      }
      if (value.param === 'V%') {
        return ordenedReports.v.push(value);
      }
      if (value.param === 'Sal. AI') {
        return ordenedReports.sal.push(value);
      }
      if (value.param === 'MO x Arg') {
        return ordenedReports.moarg.push(value);
      }
      if (value.param === 'P') {
        return ordenedReports.p.push(value);
      }
      if (value.param === 'S') {
        return ordenedReports.s.push(value);
      }
    });

    const parsedOrdenedReport = [
      ordenedReports.argila.sort((a, b) => (a.depth < b.depth ? -1 : true))[0],
      ...ordenedReports.moarg.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.mo.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.ph.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.ca.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.mg.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.k.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.ai.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.ctc.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.v.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.sal.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.p.sort((a, b) => (a.depth < b.depth ? -1 : true)),
      ...ordenedReports.s.sort((a, b) => (a.depth < b.depth ? -1 : true))
    ];

    const report: any =
      orientation === 'Retrato'
        ? reportDepthParamMultiplePages(
            parsedOrdenedReport,
            ownerName,
            table,
            reportPages,
            croquis,
            selectedFarmGeoJson,
            fields,
            listSubdivisions,
            mapScale().replaceAll('&nbsp;', ''),
            farmName,
            harvestName,
            localName,
            date,
            responsible
          )
        : reportDepthParamMultiplePagesLandscape(
            parsedOrdenedReport,
            ownerName,
            table,
            reportPages,
            croquis,
            selectedFarmGeoJson,
            fields,
            listSubdivisions,
            mapScale().replaceAll('&nbsp;', ''),
            farmName,
            harvestName,
            localName,
            date,
            responsible
          );

    // @ts-ignore
    pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
    pdfMake
      .createPdf(report)
      .download(
        `Mapa de Espacializacao ${
          selectedFarmGeoJson?.features[0].properties?.farm_name
        }_${new Date().getFullYear()}.pdf`
      );

    ordenedReports = {
      argila: [],
      mo: [],
      ph: [],
      ca: [],
      mg: [],
      k: [],
      ai: [],
      ctc: [],
      v: [],
      sal: [],
      moarg: [],
      p: [],
      s: []
    };

    setReports([]);

    await ThreadUtils.milliseconds(5);

    setLoading(false);
  };

  const captureImage = async (paramsRel) => {
    await Promise.all(
      paramsRel.map((pRel) => handleViewParam(pRel.param, pRel.depth))
    );

    const canvas = document.getElementsByTagName('canvas')[0];

    for (const value of subdivisonColors) {
      await new Promise((resolve) => {
        renderSubdivisionsWithColors(
          cacheSubdivisions.get('subdivisions'),
          value.color,
          '#AED329'
        );
        requestAnimationFrame(resolve);
      });

      const mapImage = canvas?.toDataURL('image/png');

      reports.push({
        id: mapId,
        map: `Mapa ${reports.length + 1}`,
        param: value.param,
        layer: value.depth,
        reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
        farmName: selectedFarmGeoJson.features[0].properties.farm_name,
        depth: value.depth,
        mapImage
      });
    }

    setLoading(false);
    openModal();
  };

  const waitForCanvasRender = () => {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        setTimeout(resolve, 500);
      });
    });
  };

  const getTable = async () => {
    try {
      setloadingMessage('Colhendo Resultados');

      removeSubdivisionGeometries();
      removeFieldGeometries();
      setLoading(true);
      removeGeometriesColoredSubdivisions();

      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setTable(response.data.data);

      const canvas = document.getElementsByTagName('canvas')[0];
      const uniqueParams = new Set();

      response.data?.data?.forEach((value) => {
        if (value.prof === '0-20-40' || value.prof === '0-10-20-40') {
          value.prof = '20-40';
        }

        const paramsToCheck = [
          { key: 'argila', param: 'Argila' },
          { key: 'pH_CaCl2', param: 'PH' },
          { key: 'mo', param: 'MO' },
          { key: 'ca', param: 'Ca' },
          { key: 'mg', param: 'Mg' },
          { key: 'k', param: 'K' },
          { key: 'al', param: 'AI' },
          { key: 'ctc', param: 'CTC' },
          { key: 'v', param: 'V%' },
          { key: 'p', param: 'P' },
          { key: 'satAl', param: 'Sal. AI' },
          { key: 'argila', param: 'MO x Arg' },
          { key: 's', param: 'S' }
        ];

        paramsToCheck.forEach(({ key, param }) => {
          if (Number(value[key]) >= 0) {
            uniqueParams.add(JSON.stringify({ param, depth: value.prof }));
          }
        });
      });

      const allParamsRel = Array.from(uniqueParams).map((p: any) =>
        JSON.parse(p)
      );

      setloadingMessage('Desenhando Croqui');

      if (croquis.length === 0) {
        const croq = [];
        croq.push(canvas.toDataURL('image/png'));

        await getFields();
        await waitForCanvasRender();
        croq.push(canvas.toDataURL('image/png'));

        removeFieldGeometries();
        renderFieldsNoLabel(fields, '#000');
        await getSubdivisions();
        await waitForCanvasRender();
        croq.push(canvas.toDataURL('image/png'));

        removeSubdivisionGeometries();
        renderSubdivisionsNoLabel(
          cacheSubdivisions.get('subdivisions'),
          '#AED329'
        );
        await getPoints();
        await waitForCanvasRender();
        croq.push(canvas.toDataURL('image/png'));

        setCroquis(croq);
        await getSubdivisions();
      }

      removePointGeometries();
      setloadingMessage('Desenhando Resultados e Coletando Imagens');

      await captureImage(allParamsRel);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGenerateLegend(param);
  }, [param]);

  return (
    <ReportContext.Provider value={{ exportReportPages, orientation }}>
      <S.Container>
        <h1>Relatório</h1>
        <hr />
        <p>
          Utilize as opções abaixo para adicionar as camadas desejadas no mapa
          ao lado
        </p>
        <S.Content>
          {!selectedFarmId ? (
            <h3>Por favor selecione uma fazenda</h3>
          ) : (
            <>
              {!listMapsVisible && (
                <>
                  <span>Escala:</span>
                  <input
                    className="selectInput"
                    id="scale"
                    name="scale"
                    type="text"
                    value={scale}
                    onChange={(e) => {
                      setScale(e.target.value);
                      updateMapScale(e.target.value);
                    }}
                  />
                  <S.TitleBox>Escolha o parâmetro</S.TitleBox>
                  <S.DivTags>
                    {ParamOptions.map((value) => {
                      return (
                        <CardSelect
                          key={value}
                          onChange={() => {
                            setParam(value);
                          }}
                          text={value}
                          onClick={() => {
                            setParam(value);
                          }}
                          selected={param === value}
                        />
                      );
                    })}
                  </S.DivTags>
                  <S.TitleBox>Escolha a camada</S.TitleBox>
                  <S.DepthContainer>
                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[0]));
                        handleViewParam(param, getDepth(DepthOptions[0]));
                      }}
                      text={getDepth(DepthOptions[0])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[0]));
                        handleViewParam(param, getDepth(DepthOptions[0]));
                      }}
                      selected={layer === getDepth(DepthOptions[0])}
                    />
                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[3]));
                        handleViewParam(param, getDepth(DepthOptions[3]));
                      }}
                      text={getDepth(DepthOptions[3])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[3]));
                        handleViewParam(param, getDepth(DepthOptions[3]));
                      }}
                      selected={layer === getDepth(DepthOptions[3])}
                    />

                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[2]));
                        handleViewParam(param, getDepth(DepthOptions[2]));
                      }}
                      text={getDepth(DepthOptions[2])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[2]));
                        handleViewParam(param, getDepth(DepthOptions[2]));
                      }}
                      selected={layer === getDepth(DepthOptions[2])}
                    />
                    <br />
                  </S.DepthContainer>
                  <S.DepthContainer>
                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[1]));
                        handleViewParam(param, getDepth(DepthOptions[1]));
                      }}
                      text={getDepth(DepthOptions[1])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[1]));
                        handleViewParam(param, getDepth(DepthOptions[1]));
                      }}
                      selected={layer === getDepth(DepthOptions[1])}
                    />
                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[5]));
                        handleViewParam(param, getDepth(DepthOptions[5]));
                      }}
                      text={getDepth(DepthOptions[5])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[5]));
                        handleViewParam(param, getDepth(DepthOptions[5]));
                      }}
                      selected={layer === getDepth(DepthOptions[5])}
                    />
                    <CardSelect
                      onChange={() => {
                        setLayer(getDepth(DepthOptions[6]));
                        handleViewParam(param, getDepth(DepthOptions[6]));
                      }}
                      text={getDepth(DepthOptions[6])}
                      onClick={() => {
                        setLayer(getDepth(DepthOptions[6]));
                        handleViewParam(param, getDepth(DepthOptions[6]));
                      }}
                      selected={layer === getDepth(DepthOptions[6])}
                    />
                  </S.DepthContainer>
                </>
              )}
            </>
          )}
          <S.TextSelectorOrientation>
            Orientação do Relatório
          </S.TextSelectorOrientation>
          <SwitchButton
            iconFirstOption={<MdCropPortrait />}
            textFirstOption="Retrato"
            onClickFirstOptionAction={() => setOrientation('Retrato')}
            iconSecondOption={<MdCropLandscape />}
            textSecondOption="Paisagem"
            onClickSecondOptionAction={() => setOrientation('Paisagem')}
          />
        </S.Content>
        {!reportEditId && selectedFarmId && (
          <Button
            text={<>Imprimir único parametro</>}
            onClick={() => {
              handleClick();
            }}
            className="buttonAddMap"
            disabled={disableUnicReport}
          />
        )}
        <Button
          text={<>Imprimir relatório completo</>}
          onClick={() => getTable()}
        />
        <RequestOwnerNameModal />
      </S.Container>
    </ReportContext.Provider>
  );
};
export default Report;
