/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable prettier/prettier */
import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  FarmRouter,
  ModuleProfileRouter,
  ProfileRouter,
  OrganizacaoProfileRouter,
  OrganizacaoRouter,
  ProfileSubModuleRouter,
  SubModuleRouter,
  SystemModuleRouter,
  UserFarmsRouter,
  UserProfileRouter,
  UsersRouters
} from './components';
import Management from './components/Management';
import Parameters from './components/Parameters';
import Pragues from './components/Pragues';
import Weeds from './components/Weeds';
import Disease from './components/Disease';
import { LayoutMenu } from './components/LayoutMenu';
import Farm from '../Farm';
import { HarvestRouter } from './components/Harvest';

const DataManagement: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutMenu />}>
        <Route path="/Users/*" element={<UsersRouters />} />
        {/* <Route path="/Farms/*" element={<FarmRouter />} /> */}
        <Route path="/UserFarms/*" element={<UserFarmsRouter />} />
        <Route path="/Profile/*" element={<ProfileRouter />} />
        <Route path="/ModulesSystem/*" element={<SystemModuleRouter />} />
        <Route path="/UserProfile/*" element={<UserProfileRouter />} />
        <Route path="/ProfileModule/*" element={<ModuleProfileRouter />} />
        <Route path="/Adm" element={<Management />} />
        <Route path="/Parameters" element={<Parameters />} />
        <Route path="/Pragues" element={<Pragues />} />
        <Route path="/Weeds" element={<Weeds />} />
        <Route path="/Disease" element={<Disease />} />
        <Route path="/Safras/*" element={<HarvestRouter />} />
        <Route
          path="/ProfileSubModule/*"
          element={<ProfileSubModuleRouter />}
        />
        <Route path="/SubModule/*" element={<SubModuleRouter />} />
        <Route path="/Organizacao/*" element={<OrganizacaoRouter />} />
        <Route
          path="/OrganizacaoProfile/*"
          element={<OrganizacaoProfileRouter />}
        />
      </Route>
    </Routes>
  );
};

export default DataManagement;
