/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Tooltip, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import { EnhancedTableToolbarProps } from '../../utils/OrderedTable.types';

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = (props) => {
  const {
    numSelected,
    title,
    routeNameAdd,
    IconCreate,
    routeNameEdit,
    selectedId,
    onDelete,
    setSelected,
    deleteHitch = false,
    idaggregate
  } = props;
  const navigate = useNavigate();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
      style={{ backgroundColor: '#A0BD39' }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%', color: '#fff' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <p>
            {numSelected === 1
              ? `${numSelected} selecionado`
              : `${numSelected} selecionados`}
          </p>
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', color: '#fff' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        <div style={{ display: 'flex', gap: 5 }}>
          {/* <Tooltip title="Deletar" onClick={() => onDelete(selectedId)}>
                        <IconButton>
                            <DeleteIcon htmlColor="#fff" />
                        </IconButton>
                    </Tooltip> */}
          {routeNameEdit && !props.hideEdit && (
            <Tooltip title="Editar">
              <IconButton
                onClick={() => {
                  if (deleteHitch) {
                    navigate(`${routeNameEdit}/${selectedId}/${idaggregate}`);
                    setSelected && setSelected([]);
                    return;
                  }
                  navigate(`${routeNameEdit}/${selectedId}`);
                  setSelected && setSelected([]);
                }}
              >
                <EditIcon htmlColor="#fff" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ) : (
        <>
          {IconCreate && (
            <Tooltip title="Adicionar">
              <IconButton onClick={() => navigate(routeNameAdd)}>
                {IconCreate && <IconCreate htmlColor="#fff" />}
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
