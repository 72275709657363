import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const Title = styled.h3`
  font-size: 16px;
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
`;
export const SubTitle = styled.p`
  font-size: 12px;
  color: #777777;
  text-align: justify;
  margin-bottom: 16px;
`;

export const ActionCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  svg {
    width: 43px;
    height: 43px;
    //margin-bottom: 20px;
  }
`;

export const ActionCard = styled.button`
  width: 100%;
  min-width: 100px;
  margin-top: 10px;
  height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 1px 3px 6px #0000001a;

  & + button {
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
  }

  svg {
    width: 43px;
    height: 43px;
    padding: 0;
    margin: 0;
  }

  .label-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: #fff;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  .label-report-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: #fff;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;

export const RegisteredFields = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 280px;
  height: 100%;
  margin: 5px 0 10px;
  padding: 5px;

  .infoText {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const FarmContainer = styled.div``;

export const FieldCard = styled.div`
  display: flex;
  min-width: 220px;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 8px;

  & + div {
    margin-top: 5px;
  }

  span {
    margin-left: 5px;
  }
`;
export const CardHeader = styled.div`
  width: 100%;
  font-size: 0.7rem;
`;

export const FieldForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;

export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  color: #d62222;
  text-align: center;
  margin: 2.5px;
`;

export const FieldCardContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  padding: 0px 0px 0px 10px;
  border-radius: 8px;
  //box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

  & + div {
    margin-top: 5px;
  }

  .active {
    border: 1px solid #79ac34;
  }
`;

export const FieldCardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  button {
    border: 0;
    background: transparent;
    margin-top: 5px;
  }
`;

export const FieldCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: 5px;

  span {
    font-size: 0.7rem;
  }
`;

export const FieldCardActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    & + button {
      margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
`;

export const BtnOptionsAction = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  text-align: left;
  color: #79ac34;
  padding: 0;
  font-size: 12px;
  margin-right: 10px;

  .selectAll {
    margin-left: 4px;
    padding-top: 2px;
  }
`;

export const SelectAllFieldsToNDVIButton = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  color: #79ac34;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  justify-content: center;
`;
