/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button';
import Toast from '../../../../../../components/Toast';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const DrawRecommendationZone = () => {
  const {
    dashboardState,
    setDashboardState,
    setSubdivisions,
    resultVisible,
    setResultVisible,
    setFields
  } = useContext(DashboardContext);
  const {
    refMap,
    recommZone,
    setRecommZone,
    selectedFarmId,
    setListSubdivisions,
    setLoading,
    renderSubdivisions,
    createRecommendationZone,
    removeRecommendationZone,
    setListFields,
    renderFields,
    recommZoneDrawed,
    removeDrawer,
    recommZoneList,
    renderRecommendationZones,
    createRecommendationZoneSelect,
    finalizeCreateRecommendationZoneSelect,
    removeSubdivisionGeometries,
    removeAllInterections,
    clearDrawedZoneArray,
    parseCoordinates
  } = useMap();
  const { token, user } = useAuth();

  const getSubdivisions = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as subdivisões sejam carregadas é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const saveRecommZoneDrawed = async () => {
    recommZoneDrawed[0].features[0].properties = {
      ...recommZoneDrawed[0].features[0].properties,
      subdivisions_list: recommZoneDrawed[0].properties.subdivisions_list,
      recommendation_description: '',
      farm_id: selectedFarmId,
      created_by: user.user_id
    };

    delete recommZoneDrawed[0].properties;

    if (recommZoneDrawed[0].features[0].geometry.type !== 'MultiPolygon') {
      recommZoneDrawed[0].features[0].geometry.type = 'MultiPolygon';
      recommZoneDrawed[0].features[0].geometry.coordinates[0] = [
        ...recommZoneDrawed[0].features[0].geometry.coordinates
      ];
    }

    if (recommZoneDrawed[0].features[0].geometry?.coordinates[0][1]) {
      if (
        recommZoneDrawed[0].features[0].geometry.coordinates[0][1] ===
        recommZoneDrawed[0].features[0].geometry?.coordinates[1]
      ) {
        recommZoneDrawed[0].features[0].geometry?.coordinates.pop();
      }
    }

    try {
      const response = await api.post(
        `${appConfig.apiHosts.report}/RecommendationZone/PostRecommendationZone`,
        recommZoneDrawed[0],
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Zona de recomendação salva com sucesso!" />, {
        type: 'success'
      });
      setDashboardState('dashboard@resultsMap');
    } catch (error) {
      toast(<Toast title="Não foi possivel salvar a zona de recomendação" />, {
        type: 'error'
      });
      setDashboardState('dashboard@resultsMap');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleDrawing = () => {
    if (selectedFarmId) {
      // createRecommendationZone();
      createRecommendationZoneSelect();
      return toast(
        <InstructionsToast title="Desenhe o polígono para realizar recomendação.">
          <br />
          <p>
            1. Use o mouse para desenhar a àrea correspondente ao polígono.
            <br />
            <br />
            2. Após demarcar o polígono no mapa clique em salvar.
          </p>
        </InstructionsToast>,
        {
          type: 'default',
          autoClose: 5000,
          hideProgressBar: true,
          style: { marginTop: '25%' }
        }
      );
    }
  };

  const handleSave = () => {
    finalizeCreateRecommendationZoneSelect();
    saveRecommZoneDrawed();
    removeRecommendationZone();
  };

  const cancelRecommZone = () => {
    removeAllInterections();
    removeRecommendationZone();
    removeSubdivisionGeometries();
    setDashboardState('dashboard@resultsMap');
  };

  useEffect(() => {
    removeRecommendationZone();
    handleDrawing();

    if (recommZoneList) {
    renderRecommendationZones(recommZoneList);
  }
  }, [refMap]);

  return (
    <S.Container>
      <h1>Mapa de resultados</h1>
      <hr />
      <p>
        Desenhe o polígono no mapa ao lado referente a área desejada para criar
        a recomendação.
      </p>
      <S.Content className="positionButton">
        <S.ButtonContainer>
          <Button text="Salvar" onClick={handleSave} />
        </S.ButtonContainer>
        <S.ButtonContainer>
          <Button text="Cancelar" onClick={() => cancelRecommZone()} />
        </S.ButtonContainer>
      </S.Content>
    </S.Container>
  );
};

export default DrawRecommendationZone;
