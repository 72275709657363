/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useState, MutableRefObject, useRef } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import { Outlet } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  Harvest,
  HarvestCreateResponse,
  HarvestListApiResponse
} from 'components/ReporUpload';
import { appConfig } from 'config';
import { Content } from 'components/Toast/styled';
import api from 'services/api';
import { useAuth } from 'hooks/Auth';
import dayjs from 'dayjs';
import SortableTable, {
  Column,
  SortableTableHandles
} from 'components/SortableTable';
import axios from 'axios';
import { table } from 'console';
import { toast } from 'react-toastify';
import { Chrono } from 'react-chrono';
import { Typography } from '@mui/material';
import TimeLine from 'components/TimeLine';
import Timeline from 'components/Timeline';
import { School, Star, Work } from '@mui/icons-material';
import { dateTimeFriendly, dateTimeNow } from '../../../../../../../util';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { UserFarm } from '../utils/userFarms.types';
import { useUserFarmsListRequest } from './hooks/useUserFarmsListRequest';
import { ResearchField } from '../../../../../../../components/ResearchField';
import HarvestForm, { HarvestFormHandles } from '../HarvestForm';
import 'react-vertical-timeline-component/style.min.css';

export interface HarvestCells {
  harvest_name: string;
  start_date: string;
  end_date: string;
  active: boolean;
}
const items = [
  {
    title: 'May 1940',
    cardTitle: 'Dunkirk',
    cardSubtitle:
      'Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.',
    cardDetailedText: ['paragraph1', 'paragraph2'],
    timelineContent: (
      <div style={{ width: 900, height: 300 }}>
        <span>
          {JSON.stringify(
            {
              harvest_audit_id: 'd6539fc3-5237-4622-b2d2-2680ce94040e',
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 2026',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              created_at: '2025-03-10 16:37:04.042728',
              updated_by: null,
              updated_at: null,
              deleted_by: null,
              deleted_at: null,
              active: true,
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-10 16:37:04.042728',
              current_harvest: false,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null
            },
            null,
            2
          )}
        </span>
      </div>
    )
  }
];
// {
//   harvest_audit_id: 'd6539fc3-5237-4622-b2d2-2680ce94040e',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 2026',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-10 16:37:04.042728',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'cddbe92c-eaaf-4b3c-995c-385e4f22ee85',
//   harvest_id: 'd320018f-1c03-47e2-92ae-363d5f42325e',
//   harvest_name: '2026 - 2027',
//   start_date: '2026-09-01 00:00:00.000000',
//   end_date: '2027-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-13 16:37:04.042728',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-13 14:54:24.279115',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '90acfdfb-2c53-4311-9e0c-d97b7957c321',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 20266',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-13 16:37:04.042728',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-13 15:12:05.275102',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '58334bb9-15ec-4f69-97aa-3fd247986677',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 20266',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-13 16:37:04.042728',
//   deleted_by: null,
//   deleted_at: null,
//   active: false,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-13 15:24:03.254908',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '93e26197-a4bc-4ae6-9048-10eba3839449',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 20266',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-13 16:37:04.042728',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-13 15:25:15.034241',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'd0446ec0-708c-4730-9937-ea7ae99ffd39',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 2026',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-13 16:37:04.042728',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-14 11:30:35.281072',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '09581013-42a3-4830-8d07-2b93c9064640',
//   harvest_id: '516a67d1-71a8-4c87-ba7e-04c086e76d7b',
//   harvest_name: '2024 - 2025',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 15:52:28.514038',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-25 13:12:45.738759',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-25 13:12:45.738759',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'c84e93fa-21b2-4e10-bec8-46fe018f94ad',
//   harvest_id: 'da52ec04-47d8-4d10-8d2d-8aa7a5c0cf45',
//   harvest_name: '2026 - 2027',
//   start_date: '2026-09-01 00:00:00.000000',
//   end_date: '2027-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-13 16:37:04.042728',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-26 21:02:53.081408',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '989bffb0-5833-4c60-8b63-31325c180229',
//   harvest_id: '9d11dc1b-4e61-4596-a831-bb78daf131a8',
//   harvest_name: '2025 - 2026',
//   start_date: '2025-03-25 00:00:00.000000',
//   end_date: '2025-03-26 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-26 21:07:44.039971',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'b86316a6-4eb6-47d2-bc76-ded5f4f04403',
//   harvest_id: 'b307b3fc-0e9d-4624-83ff-c7d780ac2125',
//   harvest_name: '2026 - 2027',
//   start_date: '2026-09-01 00:00:00.000000',
//   end_date: '2027-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-13 16:37:04.042728',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 12:49:11.225554',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '38b7e8a2-ee55-4e40-900b-d06b66410dac',
//   harvest_id: '8c972111-b7e5-43ef-8a24-6617fb1e581b',
//   harvest_name: '2020 - 2021',
//   start_date: '2025-03-24 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:11:10.317819',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '4c392986-99a3-4a40-abe0-fbc2a9a9921b',
//   harvest_id: '1c6fab1b-388d-4be8-988d-3d6faee8da90',
//   harvest_name: '2020 - 2021',
//   start_date: '2025-03-24 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:11:39.027894',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '4119ea8c-77e9-41fe-8218-97278e257f91',
//   harvest_id: '095ab29d-978a-413e-b785-ba9b398dca8c',
//   harvest_name: '2022 - 2023',
//   start_date: '2025-03-05 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:18:38.945380',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'cc673aad-18e9-49a5-bc6b-6d0a99f164f1',
//   harvest_id: '670619b2-d2d5-41f4-b1b6-06fb8c7276f6',
//   harvest_name: '2019 - 2020',
//   start_date: '2025-03-17 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:20:39.796172',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '1d9030ea-9fea-4719-aae4-1009c2ac4841',
//   harvest_id: '20234995-67fb-40e2-91ec-66c8d7c62b40',
//   harvest_name: '2018 - 2019',
//   start_date: '2025-03-11 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:21:16.089910',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '9fa585d4-f7cd-44c6-b890-b5b24f8439ae',
//   harvest_id: '62e89b28-e95c-4ab5-83b4-6d83aca35cfd',
//   harvest_name: '2017 - 2018',
//   start_date: '2025-03-10 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:23:45.683074',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '3339dd2b-46ce-4e55-a9b9-68ec991da029',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2016 - 2017',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:32:34.381235',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '1b155406-737b-4d01-9dc8-3048f800760c',
//   harvest_id: 'fe1571a0-7835-4de7-99aa-558b966c08c5',
//   harvest_name: '2015 - 2016',
//   start_date: '2025-03-11 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 00:00:00.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 13:36:03.405567',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '4ce75247-b6e5-45da-a4e1-835fb651f80f',
//   harvest_id: 'fdb5fab9-6235-47b3-b75d-9fa781abed67',
//   harvest_name: '2012 - 2013',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 14:08:20.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 14:08:20.478085',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '3e3f73bf-af8a-4867-89ab-5721492c63bb',
//   harvest_id: 'a829824a-8d15-4101-9a07-9226d467660e',
//   harvest_name: '2010 - 2011',
//   start_date: '2025-03-12 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 10:10:53.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 14:10:53.681078',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '8cbef3b2-51ac-4c6d-ba38-2b312f0fbc44',
//   harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
//   harvest_name: '2009 - 2010',
//   start_date: '2025-03-06 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 10:24:17.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 14:24:18.136675',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'edbf5c23-17eb-487c-bd2c-da3895321677',
//   harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
//   harvest_name: '2025 - 2026',
//   start_date: '2024-09-01 00:00:00.000000',
//   end_date: '2025-08-31 23:59:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-10 16:37:04.042728',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-13 16:37:04.042728',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:15:43.383071',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'd0a49f47-2c6e-4235-89c7-f876a5d9a5fd',
//   harvest_id: 'fe1571a0-7835-4de7-99aa-558b966c08c5',
//   harvest_name: '2015 - 2016',
//   start_date: '2025-02-25 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:18:09.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:18:09.367000',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '4780677e-5699-4cd3-9a9c-241bbee5068f',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2007',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:20:57.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:20:58.015661',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'e121712a-2654-44bc-a386-ad5433eb347f',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:25:03.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:25:03.329149',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '51d0646a-9054-4ba9-be30-038b996cd072',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-22 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:26:21.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:26:21.756679',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '552c8bb0-49bf-417b-b526-0d21aff3c282',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-22 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:26:39.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: false,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:26:39.227852',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'c3fd9877-d766-470c-afbf-a832c67a68bc',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-22 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-27 12:26:48.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-27 16:26:48.347789',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'd5ea7198-d5ba-49ec-b4dc-523b944b7e60',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-22 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-28 16:29:44.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-28 20:29:45.015990',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'c3a9fe08-ed37-4526-8d83-6baf2ba8bda4',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-03-31 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 11:39:19.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 15:39:19.079413',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '9f2acee8-dba5-4072-96bb-244363a1032d',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-04-01 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 11:58:04.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 15:58:04.785761',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '836fc8e3-213b-4db1-8949-6062b918d637',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-04-02 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 12:02:00.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 16:02:00.811448',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'a6f78d81-ae7f-4986-a45a-b5d7b814f454',
//   harvest_id: 'd975dc9d-ef53-4f02-84ab-d243b2d543f9',
//   harvest_name: '2029 - 2030',
//   start_date: '2029-08-31 00:00:00.000000',
//   end_date: '2030-10-01 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-31 12:09:53.000000',
//   updated_by: null,
//   updated_at: null,
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'INSERT',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 16:09:53.840506',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: null,
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'eb61e969-1118-4f0f-b8ac-022afc544634',
//   harvest_id: 'd975dc9d-ef53-4f02-84ab-d243b2d543f9',
//   harvest_name: '2029 - 2030',
//   start_date: '2029-08-31 00:00:00.000000',
//   end_date: '2030-10-02 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-31 12:09:53.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 12:10:26.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 16:10:26.129282',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: '73582eba-87ef-4fd5-9406-0334a37e9a8e',
//   harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
//   harvest_name: '2006 - 2006',
//   start_date: '2025-03-04 00:00:00.000000',
//   end_date: '2025-04-02 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '0001-01-01 00:00:00.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 12:02:00.000000',
//   deleted_by: null,
//   deleted_at: null,
//   active: false,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 20:48:09.091923',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: null
// },
// {
//   harvest_audit_id: 'c863b393-890e-4c86-a29d-e4fb1a2d7455',
//   harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
//   harvest_name: '2009 - 2010',
//   start_date: '2025-03-06 00:00:00.000000',
//   end_date: '2025-03-27 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 10:24:17.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-03-31 21:19:07.939045',
//   deleted_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   deleted_at: '2025-03-31 21:19:07.939046',
//   active: false,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-03-31 21:19:08.289728',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: 'Anderson Feitosa'
// },
// {
//   harvest_audit_id: 'e798eecc-41c6-4bdf-ad43-2aa231b26820',
//   harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
//   harvest_name: '2009 - 2010',
//   start_date: '2025-03-06 00:00:00.000000',
//   end_date: '2025-03-28 00:00:00.000000',
//   created_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   created_at: '2025-03-27 10:24:17.000000',
//   updated_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   updated_at: '2025-04-01 11:55:19.000000',
//   deleted_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   deleted_at: '2025-03-31 21:19:07.939046',
//   active: true,
//   action: 'UPDATE',
//   performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
//   performed_at: '2025-04-01 15:55:19.607045',
//   current_harvest: false,
//   created_by_name: 'Anderson Feitosa',
//   updated_by_name: 'Anderson Feitosa',
//   deleted_by_name: 'Anderson Feitosa'
// }
// ];

const HarvestList: FC = () => {
  const formRef = React.useRef<HarvestFormHandles>(
    null
  ) as React.MutableRefObject<HarvestFormHandles>;
  const { request, setFilter, onDeleteUserFarm } = useUserFarmsListRequest();
  const [harvests, setHarvests] = useState<Harvest[]>([]);
  const tableRef = useRef<SortableTableHandles>(
    null
  ) as MutableRefObject<SortableTableHandles>;

  const { token, user } = useAuth();

  const getHarvests = async (page: number, itemsPerPage: number) => {
    try {
      const url = `${appConfig.apiHosts.manager}/harvest/List?page=1&itemsPerPage=25`;
      const response = await api.get<HarvestListApiResponse>(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data.data);
      return {
        documents: response.data.data.harvests,
        next: response.data.data.hasNextPage ? (page++).toString() : null,
        total: response.data.data.totalItems
      };
    } catch (error) {
      return [];
    }
  };

  const headCells: HeadCell<HarvestCells>[] = [
    {
      id: 'harvest_name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'start_date',
      numeric: false,
      disablePadding: false,
      label: 'Data de Início'
    },
    {
      id: 'end_date',
      numeric: false,
      disablePadding: false,
      label: 'Data de Fim'
    },
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    }
  ];

  useState(() => {
    getHarvests(1, 10);
  }, []);

  const transformer = (list: Harvest[]) => {
    return list.map((harvest, index) => {
      return {
        id: harvest.harvest_id,
        harvest_name: harvest.harvest_name,
        start_date: dateTimeFriendly(harvest.start_date),
        end_date: dateTimeFriendly(harvest.end_date),
        active: harvest.active ? 'Ativado' : 'Desativado'
      };
    });
  };

  const handleCreateHarvest = async (harvest: Harvest) => {
    try {
      const result = await axios<HarvestCreateResponse>(
        `${appConfig.apiHosts.manager}/harvest/Create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          data: harvest
        }
      );
      const newHarvest = result.data.data;

      tableRef.current?.append([newHarvest]);

      toast.success(`Safra ${newHarvest.harvest_name} criada com sucesso`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true
      });

      formRef.current?.close();
    } catch (error) {
      console.log('Error on create harvest:', error);
      toast.error(`Erro ao criar safra ${harvest.harvest_name}: ${error}.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true
      });
    }
  };

  const handleUpdateHarvest = async (harvest: Harvest) => {
    try {
      const result = await axios(
        `${appConfig.apiHosts.manager}/harvest/Update`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          data: harvest
        }
      );

      tableRef.current?.update(result.data.data);

      toast.success(`Safra ${harvest.harvest_name} atualizada com sucesso`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true
      });

      formRef.current?.close();
    } catch (error) {
      console.log('Error on update harAtualizado porvest:', error);
      toast.error(
        `Erro ao atualizar safra ${harvest.harvest_name}: ${error}.`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true
        }
      );
    }
  };

  const handleDeleteHarvest = async (harvestId: string) => {
    try {
      const result = await axios.get(
        `${appConfig.apiHosts.manager}/Harvest/Delete/${harvestId}?deletedBy=${user.user_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.log('create harvest error', error);
    }
  };

  const handleDetailHarvest = (harvestId: string) => {
    try {
      const result = api.get(
        `${appConfig.apiHosts.manager}/Harvest/Get/${harvestId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.log('create harvest error', error);
    }
  };

  return (
    <>
      <HarvestForm
        ref={formRef}
        onCreate={handleCreateHarvest}
        onUpdate={handleUpdateHarvest}
        onDetails={handleDetailHarvest}
      />
      <SortableTable
        ref={tableRef}
        idKey="harvest_id"
        columns={[
          {
            id: 'id',
            label: 'id',
            align: 'left',
            minWidth: 150,
            sortable: true
          } as Column,
          {
            id: 'harvest_name',
            label: 'Nome',
            align: 'left',
            minWidth: 150,
            sortable: true
          } as Column,
          {
            id: 'start_date',
            label: 'Data início',
            minWidth: 150,
            sortable: true
          } as Column,
          {
            id: 'end_date',
            label: 'Data fim',
            minWidth: 150,
            sortable: true
          } as Column,
          {
            id: 'active',
            label: 'Ativo',
            minWidth: 150,
            sortable: true
          } as Column
        ]}
        fetch={getHarvests}
        keyOrder="harvest_name"
        label="Safras"
        onCreate={{
          label: 'Cadastrar Safra',
          onClick: () => {
            formRef.current?.create();
          }
        }}
        onDelete={{
          label: 'Apagar Safra',
          onClick: handleDeleteHarvest
        }}
        onEdit={{
          label: 'Editar Safra',
          onClick: (item: any) => {
            formRef.current?.update(item);
          }
        }}
        onShow={{
          label: 'Detalhes Safra',
          onClick: (item: any) => {
            formRef.current?.details(item);
          }
        }}
        transform={transformer}
        paginated
      />
      {/* <div style={{ width: '100%', height: '400px' }}>
        {/* <Timeline
          data={[
            {
              harvest_id: '095ab29d-978a-413e-b785-ba9b398dca8c',
              harvest_name: '2022 - 2023',
              start_date: '2025-03-05 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:18:38.945380',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '1c6fab1b-388d-4be8-988d-3d6faee8da90',
              harvest_name: '2020 - 2021',
              start_date: '2025-03-24 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:11:39.027894',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '20234995-67fb-40e2-91ec-66c8d7c62b40',
              harvest_name: '2018 - 2019',
              start_date: '2025-03-11 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:21:16.089910',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-04-02 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 20:48:09.091923',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-31 12:02:00.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-04-02 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 16:02:00.811448',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-31 12:02:00.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-04-01 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 15:58:04.785761',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-31 11:58:04.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-31 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 15:39:19.079413',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-31 11:39:19.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-22 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-28 20:29:45.015990',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-28 16:29:44.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-22 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:26:48.347789',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-27 12:26:48.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-22 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:26:39.227852',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-27 12:26:39.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-22 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:26:21.756679',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-27 12:26:21.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2006',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:25:03.329149',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-27 12:25:03.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2006 - 2007',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:20:58.015661',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: '2025-03-27 12:20:57.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '43c85229-8def-4c81-96c5-d805fa707b1c',
              harvest_name: '2016 - 2017',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:32:34.381235',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 2026',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:15:43.383071',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: '2025-03-13 16:37:04.042728',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 2026',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-14 11:30:35.281072',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: '2025-03-13 16:37:04.042728',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 20266',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-13 15:25:15.034241',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: '2025-03-13 16:37:04.042728',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 20266',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-13 15:24:03.254908',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: '2025-03-13 16:37:04.042728',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 20266',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-13 15:12:05.275102',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: '2025-03-13 16:37:04.042728',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '46bd3702-edca-45f6-b383-d726610a2985',
              harvest_name: '2025 - 2026',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-10 16:37:04.042728',
              current_harvest: false,
              created_at: '2025-03-10 16:37:04.042728',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '516a67d1-71a8-4c87-ba7e-04c086e76d7b',
              harvest_name: '2024 - 2025',
              start_date: '2024-09-01 00:00:00.000000',
              end_date: '2025-08-31 23:59:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-25 13:12:45.738759',
              current_harvest: false,
              created_at: '2025-03-10 15:52:28.514038',
              updated_at: '2025-03-25 13:12:45.738759',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
              harvest_name: '2009 - 2010',
              start_date: '2025-03-06 00:00:00.000000',
              end_date: '2025-03-28 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-04-01 15:55:19.607045',
              current_harvest: false,
              created_at: '2025-03-27 10:24:17.000000',
              updated_at: '2025-04-01 11:55:19.000000',
              deleted_at: '2025-03-31 21:19:07.939046',
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: 'Anderson Feitosa',
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
              harvest_name: '2009 - 2010',
              start_date: '2025-03-06 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 21:19:08.289728',
              current_harvest: false,
              created_at: '2025-03-27 10:24:17.000000',
              updated_at: '2025-03-31 21:19:07.939045',
              deleted_at: '2025-03-31 21:19:07.939046',
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: 'Anderson Feitosa',
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '60a6ee37-4464-4243-9475-521eb1a2f436',
              harvest_name: '2009 - 2010',
              start_date: '2025-03-06 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 14:24:18.136675',
              current_harvest: false,
              created_at: '2025-03-27 10:24:17.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '62e89b28-e95c-4ab5-83b4-6d83aca35cfd',
              harvest_name: '2017 - 2018',
              start_date: '2025-03-10 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:23:45.683074',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '670619b2-d2d5-41f4-b1b6-06fb8c7276f6',
              harvest_name: '2019 - 2020',
              start_date: '2025-03-17 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:20:39.796172',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '8c972111-b7e5-43ef-8a24-6617fb1e581b',
              harvest_name: '2020 - 2021',
              start_date: '2025-03-24 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:11:10.317819',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: '9d11dc1b-4e61-4596-a831-bb78daf131a8',
              harvest_name: '2025 - 2026',
              start_date: '2025-03-25 00:00:00.000000',
              end_date: '2025-03-26 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-26 21:07:44.039971',
              current_harvest: false,
              created_at: '0001-01-01 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'a829824a-8d15-4101-9a07-9226d467660e',
              harvest_name: '2010 - 2011',
              start_date: '2025-03-12 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 14:10:53.681078',
              current_harvest: false,
              created_at: '2025-03-27 10:10:53.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'b307b3fc-0e9d-4624-83ff-c7d780ac2125',
              harvest_name: '2026 - 2027',
              start_date: '2026-09-01 00:00:00.000000',
              end_date: '2027-08-31 23:59:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 12:49:11.225554',
              current_harvest: false,
              created_at: '2025-03-13 16:37:04.042728',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'd320018f-1c03-47e2-92ae-363d5f42325e',
              harvest_name: '2026 - 2027',
              start_date: '2026-09-01 00:00:00.000000',
              end_date: '2027-08-31 23:59:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-13 14:54:24.279115',
              current_harvest: false,
              created_at: '2025-03-13 16:37:04.042728',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'd975dc9d-ef53-4f02-84ab-d243b2d543f9',
              harvest_name: '2029 - 2030',
              start_date: '2029-08-31 00:00:00.000000',
              end_date: '2030-10-02 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 16:10:26.129282',
              current_harvest: false,
              created_at: '2025-03-31 12:09:53.000000',
              updated_at: '2025-03-31 12:10:26.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'd975dc9d-ef53-4f02-84ab-d243b2d543f9',
              harvest_name: '2029 - 2030',
              start_date: '2029-08-31 00:00:00.000000',
              end_date: '2030-10-01 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-31 16:09:53.840506',
              current_harvest: false,
              created_at: '2025-03-31 12:09:53.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'da52ec04-47d8-4d10-8d2d-8aa7a5c0cf45',
              harvest_name: '2026 - 2027',
              start_date: '2026-09-01 00:00:00.000000',
              end_date: '2027-08-31 23:59:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-26 21:02:53.081408',
              current_harvest: false,
              created_at: '2025-03-13 16:37:04.042728',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'fdb5fab9-6235-47b3-b75d-9fa781abed67',
              harvest_name: '2012 - 2013',
              start_date: '2025-03-04 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 14:08:20.478085',
              current_harvest: false,
              created_at: '2025-03-27 14:08:20.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'fe1571a0-7835-4de7-99aa-558b966c08c5',
              harvest_name: '2015 - 2016',
              start_date: '2025-02-25 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'UPDATE',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 16:18:09.367000',
              current_harvest: false,
              created_at: '2025-03-27 00:00:00.000000',
              updated_at: '2025-03-27 12:18:09.000000',
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: 'Anderson Feitosa',
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            },
            {
              harvest_id: 'fe1571a0-7835-4de7-99aa-558b966c08c5',
              harvest_name: '2015 - 2016',
              start_date: '2025-03-11 00:00:00.000000',
              end_date: '2025-03-27 00:00:00.000000',
              action: 'INSERT',
              performed_by: '3bfc384c-02b3-40b2-9479-cbfacd28761c',
              performed_at: '2025-03-27 13:36:03.405567',
              current_harvest: false,
              created_at: '2025-03-27 00:00:00.000000',
              updated_at: null,
              deleted_at: null,
              created_by_name: 'Anderson Feitosa',
              updated_by_name: null,
              deleted_by_name: null,
              performed_by_name: 'Anderson Feitosa'
            }
          ]}
        /> */}
      {/* <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="2011 - present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">
              Creative Director
            </h3>
            <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
            <p>
              Creative Direction, User Experience, Visual Design, Project
              Management, Team Leading
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2010 - 2011"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">Art Director</h3>
            <h4 className="vertical-timeline-element-subtitle">
              San Francisco, CA
            </h4>
            <p>
              Creative Direction, User Experience, Visual Design, SEO, Online
              Marketing
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2008 - 2010"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">Web Designer</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Los Angeles, CA
            </h4>
            <p>User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2006 - 2008"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">Web Designer</h3>
            <h4 className="vertical-timeline-element-subtitle">
              San Francisco, CA
            </h4>
            <p>User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="April 2013"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            icon={<School />}
          >
            <h3 className="vertical-timeline-element-title">
              Content Marketing for Web, Mobile and Social Media
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Online Course
            </h4>
            <p>Strategy, Social Media</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="November 2012"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            icon={<School />}
          >
            <h3 className="vertical-timeline-element-title">
              Agile Development Scrum Master
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Certification
            </h4>
            <p>Creative Direction, User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2002 - 2006"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            icon={<School />}
          >
            <h3 className="vertical-timeline-element-title">
              Bachelor of Science in Interactive Digital Media Visual Imaging
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Bachelor Degree
            </h4>
            <p>Creative Direction, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            icon={<Star />}
          />
        </VerticalTimeline> */}
      {/* </div> */}
      <Outlet />
    </>
  );
};
export default HarvestList;
