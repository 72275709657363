/* eslint-disable prettier/prettier */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { toast } from 'react-toastify';
import { shade, setLightness } from 'polished';
import TerrainIcon from '@mui/icons-material/Terrain';
import CloseIcon from '@mui/icons-material/Close';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import { generateColor } from '../../../../util';
import Button from '../../../../components/Button';
import CardSelect from '../../../../components/CardSelect';
import Toast from '../../../../components/Toast';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import * as S from './styled';
import { getDepth, setDepth } from '../../../../helpers/parseDephPoint';
import { appConfig } from '../../../../config';
import { MenuButtom } from '../../../../components/MenuButtom';

interface Legend {
  color: string;
  description: string;
  range: string;
  label: string;
}

interface LegendData {
  legends: Legend[];
}

interface NematodeLegend {
  status: number;
  success: boolean;
  message: string;
  data: LegendData;
  error: any | null;
}

interface ColorItem {
  subdivision: string;
  hexaColor: string;
  depth: string;
  description: string;
}

interface Data {
  colorList: ColorItem[];
}

interface ReportNematodeColorsResponse {
  status: number;
  success: boolean;
  message: string;
  data: Data;
  error: any | null;
}

export enum EReportType {
  none = 'Selecione',
  soilAnalysis = 'Análise de solo',
  nematodeAnalysis = 'Analise de Nematóides'
}

type reports = keyof typeof EReportType;

const reportMenuOptions = [
  {
    text: EReportType.none,
    icon: <CloseIcon />,
    value: 'none' as keyof typeof EReportType
  },
  {
    text: EReportType.soilAnalysis,
    icon: <TerrainIcon />,
    value: 'soilAnalysis' as keyof typeof EReportType
  },
  {
    text: EReportType.nematodeAnalysis,
    icon: <CoronavirusIcon />,
    value: 'nematodeAnalysis' as keyof typeof EReportType
  }
];

const Results = () => {
  const [param, setParam] = useState('');
  const [nematodeParam, setNematodeParam] = useState<NematodesParamOptionsProps | undefined>(undefined);
  const [nematodeSubParam, setNematodeSubParam] = useState<NematodesSubParamOptionsProps | undefined>(undefined);
  const [layer, setLayer] = useState<string | undefined>(undefined);
  const [resultsVisible, setResultsVisible] = useState(true);
  const [addOtherMapsVisible, setAddOtherMapsVisible] = useState(false);
  const [listMapsVisible, setListMapsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseParams, setResponseParams] = useState();
  const [material, setMaterial] = useState<MaterialsProps | undefined>(undefined);

  interface NematodesParamOptionsProps {
    name: string;
    value: string;
    handle?: (param: string) => Promise<void>;
    subParams: Array<NematodesSubParamOptionsProps>;
    materials: Array<MaterialsProps>;
    layers: Array<LayerProps>;
  }

  interface NematodesSubParamOptionsProps {
    name: string;
    value: string;
    handle?: (subParam: string) => Promise<void>;
  }

  interface MaterialsProps {
    name: string;
    value: string;
    subParams?: string;
  }

  interface LayerProps {
    name: string;
    value: string;
  }

  const ParamOptions = [
    'Argila',
    'MO',
    'MO x Arg',
    'PH',
    'Ca',
    'Mg',
    'K',
    'AI',
    'CTC',
    'V%',
    'P',
    'Sal. AI',
    'S'
  ];

  const DepthOptions = [
    '0-10',
    '0-20',
    '0-20-40',
    '0-10-20',
    '0-10-20-40',
    '0-10-20-30-40',
    '0-10-20-30-40-60'
  ];

  const mapId = nextId();
  const [background, setBackground] = useState('');
  const [fertilizeList, setFertilizeList] = useState([]);
  const [fertilize, setFertilize] = useState('');
  const [mapType, setMapType] = useState('results');
  const [disabledAddBtn, setDisabledAddBtn] = useState(true);

  const {
    dashboardState,
    setDashboardState,
    setSubdivisions,
    resultVisible,
    setResultVisible,
    setShowResult,
    setFields,
    subdivisions,
    setReportType,
    reportType
  } = useContext(DashboardContext);
  const { token, user, role } = useAuth();
  const [perfil, modulo, subModulo] = role.split('|');
  const {
    selectedFarmId,
    setListSubdivisions,
    renderSubdivisions,
    setLegendResultsValue,
    setLegendResultsValue1,
    setLegendResultsValue2,
    setLegendResultsValue3,
    setLegendResultsValue4,
    legendResultsValue1,
    renderSubdivisionsWithColors,
    listSubdivisions,
    removeMappingGeometries,
    removePointGeometries,
    renderedMapEditId,
    setRenderedMapEditId,
    renderedMaps,
    renderFarm,
    selectedFarmGeoJson,
    mapRef,
    setListFields,
    renderFields,
    refMap,
    removeGeometriesColoredSubdivisions,
    removeRecommendationZone,
    swipeShow,
    setSwipeShow,
    swipeActived,
    setSwapActived,
    activedSwipe,
    addSwipe,
    removeGeometriesSwipe,
    removeSwipe,
    setBtnColor,
    removeColoredRecommendationZone,
    setParameterResults,
    setParameterResults1,
    setParameterResults2,
    setParameterResults3,
    setParameterResults4,
    parameterResults1,
    renderSubdivisionsWithColorsDepth2040,
    setTableResults,
    tableResults,
    contactList,
    setContactList,
    setChatList,
    renderRecommendationZoneWithColors,
    setLegendRecommendationValue,
    recommendationMapSubtitle,
    setRecommendationMapSubtitle,
    recommZoneList,
    recommendationsZoneList,
    setRecommendationsZoneList,
    renderRecommendationZones,
    setRecommZoneList,
    // fertilize,
    // setFertilize
    currentHarvest
  } = useMap();

  useEffect(() => {
    console.log('color', generateColor(2));
    if (renderedMapEditId && renderedMaps) {
      setLayer(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].layer
      );
      setParam(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].param
      );
      setFertilize(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0]
          .fertilize
      );
      setMapType(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0]
          .mapType,
      );
      setBackground(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].color
      );
    }
    removeRecommendationZone();
  }, []);

  const handleGetSubParams = async (sub: string) => {
    try {
      setSwipeShow(false);
      setSwapActived(false);
      try {
        removeGeometriesColoredSubdivisions();
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', sub],
          ['depth', '0-20'],
          ['harvestId', currentHarvest]
        ]);
        const result = await api.get<ReportNematodeColorsResponse>(
          `${appConfig.apiHosts.report}/ReportNematode/SubParams`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subdivisonsColors = [];
        subdivisonsColors.push(result.data.data.colorList);

        if (result.data.data.colorList.length >= 1) {
          setLegendResultsValue([{
            color: result.data.data.colorList[0].hexaColor,
            range: 'Presente ',
          }]);

          setParameterResults(result.data.data.colorList[0].description);
          renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0]);
          setSwipeShow(true);
          setResultVisible(true);
          return;
        }
          toast(
            <Toast
              title="Atenção"
              content="Não foi possível encontrar resultados para o parâmetro selecionado"
            />,
            { type: 'error' }
          );
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.log('Error getting sub params', error);
    }
  };

  const handleGetRace = async (parameter: string) => {
    setSwipeShow(false);
    setSwapActived(false);
    try {
      removeGeometriesColoredSubdivisions();
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['harvestId', currentHarvest],
        ['depth', '0-20']
      ]);
      const result = await api.get<ReportNematodeColorsResponse>(
        `${appConfig.apiHosts.report}/ReportNematode/Race`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const subdivisonsColors = [];
      subdivisonsColors.push(result.data.data.colorList);

      renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0]);
      const combinations = new Set();
      const colors = [];
      result.data.data.colorList.forEach((color) => {
        if (!combinations.has(color.description)) {
          combinations.add(color.description);
          colors.push({
            color: color.hexaColor,
            description: color.description
          });
        }
      });
      setLegendResultsValue(colors);
      setParameterResults('Heterodera glycines - Raça');
      setSwipeShow(true);
      setResultVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  const NematodesParamOptions = [
    {
      name: 'Pratylenchus brachyurus',
      value: 'pratylenchus_brachyurus',
      subParams: [],
      materials: [
        {
          name: 'Raiz',
          value: 'root'
        },
        {
          name: 'Solo',
          value: 'soil'
        },
      ],
      layers: []
    },
    {
      name: 'Helicotylenchus Spp',
      value: 'helicotylenchus_spp',
      subParams: [],
      materials: [
        {
          name: 'Raiz',
          value: 'root'
        },
        {
          name: 'Solo',
          value: 'soil'
        },
      ],
      layers: []
    },
    {
      name: 'Meloidogyne Spp',
      value: 'meloidogyne_spp',
      subParams: [
        {
          name: 'Meloidogyne javanica',
          value: 'meloidogyne_javanica',
          handle: handleGetSubParams
        },
        {
          name: 'Meloidogyne incognita',
          value: 'meloidogyne_incognita',
          handle: handleGetSubParams
        }
      ],
      materials: [
        {
          name: 'Solo',
          value: 'soil'
        },
        {
          name: 'Raiz',
          value: 'root'
        }
      ],
      layers: []
    },
    {
      name: 'Rotylenchulus reniformis',
      value: 'rotylenchulus_reniformis',
      subParams: [],
      materials: [
        {
          name: 'Solo',
          value: 'soil'
        },
        {
          name: 'Raiz',
          value: 'root'
        }
      ],
      layers: []
    },
    {
      name: 'Hetereodera glycines',
      value: 'heterodera_glycines',
      subParams: [
        {
          name: 'Juvenis',
          value: 'juveniles'
        },
        {
          name: 'Cistos',
          value: 'cists'
        }
      ],
      materials: [
        {
          name: 'Solo',
          value: 'soil',
          subParams: 'juveniles'
        },
        {
          name: 'Raiz',
          value: 'root',
          subParams: 'juveniles'
        },
        {
          name: 'Viáveis',
          value: 'viable',
          subParams: 'cists'
        },
        {
          name: 'Inviáveis',
          value: 'unviable',
          subParams: 'cists'
        }
      ],
      layers: []
    },
    {
      name: 'Hetereodera glycines - raça',
      value: 'heterodera_glycines_race',
      handle: handleGetRace,
      subParams: [],
      materials: [],
      layers: []
    } as NematodesParamOptionsProps
  ];

  const getSubdivisions = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  useEffect(() => {
    getSubdivisions();
    getFields();
  }, [refMap]);

  const getNematodeLayers = async (nematode: string | undefined) => {
    try {
      if (!nematode) {
        return [];
      }
      const params = new URLSearchParams();
      params.append('farmId', selectedFarmId,);
      params.append('nematode', nematode);
      params.append('harvestId', currentHarvest);

      const res = await api.get(
        `${appConfig.apiHosts.report}/ReportNematode/GetNematodeLayersByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return res.data.data.nematodeLayers;
    } catch (error) {
      console.log('Error getting nematode layers', error);
      return [];
    }
  };

  const handleViewParam = async (parameter, depth) => {
    setSwipeShow(false);
    setSwapActived(false);
    try {
      if (depth === '20-40' || depth === '0-20-40') {
        removeGeometriesColoredSubdivisions();
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);
        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);

        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subdivisonsColors = [];
        subdivisonsColors.push(resp02040.data.data);

        resp0102040.data.data.forEach((color) => {
          subdivisonsColors[0].push(color);
        });
        console.log('subdivisonsColors', subdivisonsColors);
        console.log('listSubdivisions', listSubdivisions);
        renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0]);
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', setDepth(depth)]
        ]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        // const responseParams = response.data.data;
        renderSubdivisionsWithColors(listSubdivisions, response.data.data);
      }

      if (parameter && depth) {
        setSwipeShow(true);
      }
      setResultVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewParamNematode = async (parameter: string, depth: string) => {
    setSwipeShow(false);
    setSwapActived(false);
    try {
      removeGeometriesColoredSubdivisions();
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['parameter', parameter],
        ['depth', depth],
        ['harvestId', currentHarvest]
      ]);
      const result = await api.get<ReportNematodeColorsResponse>(
        `${appConfig.apiHosts.report}/ReportNematode/Colors`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const subdivisonsColors = [];
      subdivisonsColors.push(result.data.data.colorList);

      console.log('subdivisonsColors', subdivisonsColors);
      console.log('listSubdivisions', listSubdivisions);

      renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0]);

      if (parameter && depth) {
        setSwipeShow(true);
      }
      setResultVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data);
      setParameterResults(parameter);

      if (renderedMaps.length === 0) {
        setLegendResultsValue1(response.data.data);
      }
      if (renderedMaps.length === 1) {
        setLegendResultsValue2(response.data.data);
      }
      if (renderedMaps.length === 2) {
        setLegendResultsValue3(response.data.data);
      }
      if (renderedMaps.length === 3) {
        setLegendResultsValue4(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateLegendNematode = async (parameter: string) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get<NematodeLegend>(
        `${appConfig.apiHosts.report}/ReportNematode/Legend`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data.legends);
      setParameterResults(response.data.data.legends[0].label);

      if (renderedMaps.length === 0) {
        setLegendResultsValue1(response.data.data.legends);
      }
      if (renderedMaps.length === 1) {
        setLegendResultsValue2(response.data.data.legends);
      }
      if (renderedMaps.length === 2) {
        setLegendResultsValue3(response.data.data.legends);
      }
      if (renderedMaps.length === 3) {
        setLegendResultsValue4(response.data.data.legends);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRenderedMap = () => {
    const renderedMap = renderedMaps?.filter(
      (value) => value.id === renderedMapEditId
    )[0];
    renderedMap.layer = layer;
    renderedMap.param = param;
    renderedMap.fertilize = fertilize;
    renderedMap.color = background;
    renderedMap.mapType = mapType;
  };

  const getReport = async () => {
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTableResults(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getReportNematode = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['harvestId', currentHarvest]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportNematode/Report`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTableResults(response.data.data.nematodes);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (!selectedFarmId) {
  //     return toast(
  //       <Toast
  //         title="Por favor selecione uma fazenda"
  //         content="Para que as subdivisões sejam carregadas é nescessário selecionar uma fazenda"
  //       />,
  //       { type: 'warning' }
  //     );
  //   }
  //   getSubdivisions();
  //   setResultVisible(true);
  //   setLegendResultsValue([]);
  //   removeMappingGeometries();
  //   removePointGeometries();
  //   removeColoredRecommendationZone();
  //   getReport();
  // }, []);

  const handleClick = () => {
    renderedMaps.push({
      id: mapId,
      map: `Mapa ${renderedMaps.length + 1}`,
      param,
      layer,
      fertilize,
      color: background,
      recommendationType: fertilize,
      mapType
    });
  };

  const generateColoredZone = async (color) => {
    setSwipeShow(false);
    setSwapActived(false);
    setRecommendationMapSubtitle([]);
    setLegendRecommendationValue([]);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const selectedRecommZoneBytFert = response.data.data.filter(
        (v) => v.fertilize === fertilize
      );

      const selectedRecommZone = [];

      recommZoneList.forEach(async (v) => {
        if (selectedRecommZoneBytFert.length > 0) {
          await selectedRecommZoneBytFert.forEach((zone) => {
            if (
              zone.recommendation_zone_id ===
              v.features[0].properties.recommendation_zone_id
            ) {
              selectedRecommZone.push(v);
            }
          });
        }
      });

      const quantity = selectedRecommZoneBytFert
        .map((value) => value.quantity)
        .filter((value, index, self) => self.indexOf(value) === index);

      const itemFilter = [];

      selectedRecommZoneBytFert.forEach(async (item) => {
        if (itemFilter.length <= 0) {
          itemFilter.push(item);
        } else {
          itemFilter.forEach((value) => {
            if (value?.fertilize !== item.fertilize) {
              itemFilter.push(item);
            }
          });
        }
      });

      const sort = await selectedRecommZoneBytFert.sort(
        (n1, n2) => n2.quantity - n1.quantity
      );

      const cores = selectedRecommZoneBytFert.map((value) => {
        sort.forEach((qt, index) => {
          if (value.quantity === qt.quantity) {
            value = {
              ...value,
              color: `${setLightness((index + 1) / 10, color)}`
            };
          }
        });
        return value;
      });

      cores.forEach((item, index) => {
        recommendationMapSubtitle.push({
          quantity: item.quantity,
          fertilize: item.fertilize,
          measurement_unit: item.measurement_unit,
          color: `${setLightness((index + 1) / 10, color)}`
        });
      });

      renderRecommendationZoneWithColors(selectedRecommZone, cores);
      setLegendRecommendationValue(recommendationMapSubtitle);
      if (fertilize && background) {
        setSwipeShow(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFertilizesList = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setRecommendationsZoneList(response.data.data);

      response.data.data.forEach((result) => {
        if (fertilizeList.length <= 0) {
          fertilizeList.push(result);
        } else {
          const fertFilter = fertilizeList?.filter(
            (v) => v.fertilize === result.fertilize
          );

          if (fertFilter.length <= 0) {
            fertilizeList.push(result);
          }
        }
      });

      if (renderedMapEditId) {
        const fertFilter = fertilizeList.filter(
          (v) =>
            v.fertilize ===
            renderedMaps?.filter((value) => value.id === renderedMapEditId)[0]
              .fertilize
        )[0];
        setFertilize(fertFilter);
      }

      await setBackground(
        renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].color
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getRecommZoneList = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que sejam carregados os resultados de análise é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }

      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationZoneListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { status, data } = response;

      if (status !== 200) return;

      setRecommZoneList(data);
      // renderRecommendationZones(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleChangeComplete = (color) => {
    setBackground(color);
    generateColoredZone(color);
  };

  const handleChangeReportType = (report: keyof typeof EReportType) => {
    try {
      setReportType(report);
      if (tableResults.length === 0) {
        switch (report) {
          case 'nematodeAnalysis':
            getReportNematode();
            break;

          case 'soilAnalysis':
            getReport();
            break;

          case 'none':
            return;

          default:
            break;
        }
      }
    } catch (error) {
      console.log('Error while setting report type');
    }
  };

  const handleSetMaterial = async (nematode: NematodesParamOptionsProps, material: MaterialsProps) => {
    try {
      let parameter: string | null = null;
      const picked = nematodeParam.materials.find((value) => value.value === material.value);
      if (!picked) {
        return toast(
          <Toast
            title="Erro"
            content="Material não encontrado"
          />,
          { type: 'error' }
        );
      }

      if (material.subParams && nematodeSubParam?.value) {
        parameter = `${nematodeParam?.value}_${nematodeSubParam?.value}_${material?.value}`;
      } else {
        parameter = `${nematodeParam?.value}_${material?.value}`;
      }

      const availableLayers = await getNematodeLayers(parameter);
      if (nematodeParam?.value && material?.value) {
        await handleGenerateLegendNematode(parameter);
      }
      if (availableLayers.length === 0) {
        setNematodeParam((prev) => ({ ...prev, layers: availableLayers }));
        setMaterial(material);
        return;
      }
      const layersAvailable = availableLayers.map((layer) => {
        return {
          name: layer,
          value: layer
        };
      });
      setNematodeParam((prev) => ({ ...prev, layers: layersAvailable }));
      setMaterial(material);
      if (layersAvailable.length === 1) {
        setLayer(layersAvailable[0].value);
        handleViewParamNematode(parameter, layersAvailable[0].value);
      }
    } catch (error) {
      console.log('Error setting nematode', error);
    }
  };

  useEffect(() => {
    getFertilizesList();
    getRecommZoneList();
  }, []);

  useEffect(() => {
    if (background !== '' && fertilize !== '') {
      handleChangeComplete(background);
    }
  }, [background, fertilize]);

  // useEffect(() => {
  //   if (nematodeParam?.value && material?.value) {
  //     handleGenerateLegendNematode(`${nematodeParam.value}_${material?.value}`);
  //   }
  // }, [nematodeParam, material]);

  useEffect(() => {
    handleGenerateLegend(param);
  }, [param]);

  useEffect(() => {
    getSubdivisions();
  }, [param]);

  useEffect(() => {
    if (mapType === 'results' && layer !== '' && param !== '') {
      setDisabledAddBtn(false);
    } else if (
      mapType === 'recommendationMap' &&
      fertilize !== '' &&
      background
    ) {
      setDisabledAddBtn(false);
    } else {
      setDisabledAddBtn(true);
    }
  }, [param, layer, fertilize, background]);

  useEffect(() => {
    setDisabledAddBtn(true);
    setParam('');
    setLayer('');
    setFertilize('');
    setBackground('');
    setLegendRecommendationValue('');
    removeColoredRecommendationZone();
    removeGeometriesColoredSubdivisions();
  }, [mapType]);

  return (
    <S.Container>
      <>
        {resultsVisible && selectedFarmId && (
          <>
            <h1>Resultados de Análise</h1>
            <hr />
            <p style={{ marginBottom: 3, fontSize: 18 }}>Tipo de Análise</p>
            <MenuButtom
              selected={reportType}
              label={EReportType[reportType]}
              options={reportMenuOptions}
              onChange={handleChangeReportType}
            />
            <p>Selecione o tipo de Mapa que deseja visualizar</p>
            <S.ZoneCardActions>
              {subModulo.includes('SubResultadosAnalise') && (
                <S.ActionButtonCard
                  status={mapType === 'results'}
                  onClick={() => setMapType('results')}
                >
                  Resultados de Análise
                </S.ActionButtonCard>
              )}
              {subModulo.includes('Recomendacao') && (
                <S.ActionButtonCard
                  status={mapType === 'recommendationMap'}
                  onClick={() => setMapType('recommendationMap')}
                >
                  Mapa de Recomendação
                </S.ActionButtonCard>
              )}
            </S.ZoneCardActions>
          </>
        )}
      </>
      {mapType === 'results' && EReportType[reportType] === EReportType.soilAnalysis && (
        <>
          <p>
            Utilize as opções abaixo para adicionar as camadas desejadas no mapa
            ao lado
          </p>
          <S.Content>
            {!selectedFarmId ? (
              <h3>Por favor selecione uma fazenda</h3>
            ) : (
              <>
                {!listMapsVisible && (
                  <>
                    <S.TitleBox>Escolha o parâmetro</S.TitleBox>
                    <S.DivTags>
                      {ParamOptions.map((value) => {
                        return (
                          <CardSelect
                            text={value}
                            onClick={() => {
                              setParam(value);
                              handleViewParam(value, layer);
                            }}
                            selected={param === value}
                          />
                        );
                      })}
                    </S.DivTags>

                    <S.TitleBox>Escolha a camada</S.TitleBox>
                    <S.DepthContainer>
                      <CardSelect
                        text={getDepth(DepthOptions[0])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[0]));
                          handleViewParam(param, getDepth(DepthOptions[0]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[0])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[3])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[3]));
                          handleViewParam(param, getDepth(DepthOptions[3]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[3])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[2])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[2]));
                          handleViewParam(param, getDepth(DepthOptions[2]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[2])}
                      />
                      <br />
                    </S.DepthContainer>
                    <S.DepthContainer style={{ marginBottom: '21px' }}>
                      <CardSelect
                        text={getDepth(DepthOptions[1])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[1]));
                          handleViewParam(param, getDepth(DepthOptions[1]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[1])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[5])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[5]));
                          handleViewParam(param, getDepth(DepthOptions[5]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[5])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[6])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[6]));
                          handleViewParam(param, getDepth(DepthOptions[6]));
                          getReport();
                        }}
                        selected={layer === getDepth(DepthOptions[6])}
                      />
                    </S.DepthContainer>
                  </>
                )}
              </>
            )}
          </S.Content>
        </>
      )}

      {mapType === 'results' && EReportType[reportType] === EReportType.nematodeAnalysis && (
        <>
          <p>
            Utilize as opções abaixo para adicionar as camadas desejadas no mapa
            ao lado
          </p>
          <S.Content>
            {!selectedFarmId ? (
              <h3>Por favor selecione uma fazenda</h3>
            ) : (
              <>
                {!listMapsVisible && (
                  <>
                    <S.TitleBox>Escolha o parâmetro</S.TitleBox>
                    <S.DivTags>
                      {NematodesParamOptions.map((nematode) => {
                        return (
                          <CardSelect
                            text={nematode.name}
                            textStyles={{ fontStyle: 'italic' }}
                            onClick={async () => {
                              setMaterial(undefined);
                              setLayer('');
                              setNematodeSubParam(undefined);
                              if (nematode.handle) {
                                await nematode.handle(nematode.value);
                              }
                              setNematodeParam(nematode);
                            }}
                            selected={nematodeParam?.value === nematode.value}
                          />
                        );
                      })}
                    </S.DivTags>

                    {nematodeParam?.subParams.length > 0 &&
                      (
                        <>
                          <S.TitleBox>Sub-parâmetros</S.TitleBox>
                          <S.DepthContainer>
                            {
                              nematodeParam?.subParams.map((subParam) => {
                                return (
                                  <CardSelect
                                    text={subParam.name}
                                    onClick={async () => {
                                      setMaterial(undefined);
                                      setLayer('');

                                      if (subParam.handle) {
                                        await subParam.handle(subParam.value);
                                      }

                                      setNematodeSubParam(subParam);
                                    }}
                                    selected={nematodeSubParam?.value === subParam.value}
                                  />
                                );
                              })}
                            <br />
                          </S.DepthContainer>
                        </>
                      )
                    }

                    {nematodeParam?.materials.length > 0 && (
                      <>
                        <S.TitleBox>Escolha o material</S.TitleBox>
                        <S.DepthContainer>
                          {nematodeParam?.materials.map((mat) => {
                            if (mat?.subParams) {
                              if (mat.subParams === nematodeSubParam?.value) {
                                return (
                                  <CardSelect
                                    text={mat.name}
                                    onClick={async () => {
                                      setLayer('');
                                      await handleSetMaterial(nematodeParam, mat);
                                    }}
                                    selected={material?.value === mat.value}
                                  />
                                );
                              }
                              return;
                            }
                            return (
                              <CardSelect
                                text={mat.name}
                                onClick={async () => {
                                  setLayer('');
                                  await handleSetMaterial(nematodeParam, mat);
                                }}
                                selected={material?.value === mat.value}
                              />
                            );
                          })}
                          <br />
                        </S.DepthContainer>
                      </>
                    )}

                    {nematodeParam?.layers.length > 0 && (
                      <>
                        <S.TitleBox>Escolha a camada</S.TitleBox>
                        <S.DepthContainer>
                          {nematodeParam?.layers.map((l) => {
                            return (
                              <CardSelect
                                text={l.name}
                                onClick={() => {
                                  let parameter: string | null = null;
                                  const picked = nematodeParam.materials.find((value) => value.value === material.value);
                                  if (!picked) {
                                    return toast(
                                      <Toast
                                        title="Erro"
                                        content="Material não encontrado"
                                      />,
                                      { type: 'error' }
                                    );
                                  }

                                  if (material.subParams && nematodeSubParam?.value) {
                                    parameter = `${nematodeParam?.value}_${nematodeSubParam?.value}_${material?.value}`;
                                  } else {
                                    parameter = `${nematodeParam?.value}_${material?.value}`;
                                  }

                                  setLayer(l.value);
                                  handleViewParamNematode(parameter, l.value);
                                }}
                                selected={layer === l?.value}
                              />
                            );
                          })}
                          <br />
                        </S.DepthContainer>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </S.Content>
        </>
      )}

      {mapType === 'recommendationMap' && (
        <>
          {!selectedFarmId ? (
            <h3>Por favor selecione uma fazenda</h3>
          ) : (
            <S.Content>
              <S.TitleBox>Escolha o fertilizante/corretivo</S.TitleBox>
              <S.DivTags>
                {fertilizeList.map((value) => {
                  return (
                    <CardSelect
                      text={value.fertilize}
                      onClick={() => {
                        setFertilize(value);
                        // handleViewParam(value, layer);
                      }}
                      selected={fertilize === value}
                    />
                  );
                })}
              </S.DivTags>
              <p>
                Utilize as opções abaixo e selecione uma cor para visualizar o
                mapa de recomendação
              </p>
              <S.ContentSelectColor>
                <S.ColorPickerContainer>
                  <S.ColorArea
                    color="#800000"
                    selected={background}
                    onClick={() => {
                      handleChangeComplete('#800000');
                    }}
                  />
                  <S.ColorArea
                    color="#000040"
                    selected={background}
                    onClick={() => handleChangeComplete('#000040')}
                  />
                  <S.ColorArea
                    color="#004000"
                    selected={background}
                    onClick={() => handleChangeComplete('#004000')}
                  />
                  <S.ColorArea
                    color="#BE3C08"
                    selected={background}
                    onClick={() => handleChangeComplete('#BE3C08')}
                  />
                  <S.ColorArea
                    color="#400040"
                    selected={background}
                    onClick={() => handleChangeComplete('#400040')}
                  />
                  <S.ColorArea
                    color="#BA9910"
                    selected={background}
                    onClick={() => handleChangeComplete('#BA9910')}
                  />
                </S.ColorPickerContainer>
              </S.ContentSelectColor>
            </S.Content>
          )}
        </>
      )}

      {!renderedMapEditId && selectedFarmId && renderedMaps.length < 4 && (
        <Button
          text="Adicionar outro mapa"
          onClick={() => {
            handleClick();
            setDashboardState('dashboard@renderedMaps');
          }}
          className="buttonAddMap"
          disabled={disabledAddBtn}
        />
      )}
      {selectedFarmId && renderedMaps.length <= 4 && renderedMapEditId && (
        <Button
          text={
            renderedMapEditId ? 'Editar Parametros' : 'Adicionar outro mapa'
          }
          onClick={() => {
            handleEditRenderedMap();
            setRenderedMapEditId();
            setDashboardState('dashboard@renderedMaps');
          }}
          className="buttonAddMap"
        />
      )}
    </S.Container>
  );
};
export default Results;
