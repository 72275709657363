/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { v4 as uuid } from 'uuid';
import {
  dateTimeFriendly,
  formatDate,
  parseDate,
  parseToJSDate
} from '../../../../../../util';
import * as S from './styled';

import { DashboardContext } from '../../../../index';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';

const p = formatDate(parseToJSDate('10/10/2020 00:00:00'), 'DD/MM/YYYY');

const TableNematode = () => {
  const { token } = useAuth();
  const { selectedFarmId, tableResults } = useMap();
  const { farms, setFarms } = useContext(DashboardContext);

  const [filter, setFilter] = useState([
    {
      id: 0,
      type: 'Sem Filtro',
      collumn: '',
      values: []
    },
    {
      id: 1,
      type: 'Profundidade',
      collumn: 'prof',
      values: [...new Set(tableResults.map((result) => result.prof))]
    },
    {
      id: 2,
      type: 'Safra',
      collumn: 'safra',
      values: [...new Set(tableResults.map((result) => result.safra))]
    },
    {
      id: 3,
      type: 'Laboratorio',
      collumn: 'laboratorio',
      values: [...new Set(tableResults.map((result) => result.laboratorio))]
    },
    {
      id: 4,
      type: 'Subdivisão',
      collumn: 'sub01',
      values: [...new Set(tableResults.map((result) => result.sub01))]
    },
    {
      id: 5,
      type: 'Talhão',
      collumn: 'campo',
      values: [...new Set(tableResults.map((result) => result.campo))]
    }
  ]);
  const [selectedFilter, setSelectedFilter] = useState('Sem Filtro');
  const [selectedValue, setSelectedValue] = useState('');
  const data = {
    columns: [
      { field: 'id', hide: true },
      { headerName: 'Proprietário', field: 'farm_owner', width: 300 },
      {
        headerName: 'Propriedade',
        field: 'farm_name',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Safra',
        field: 'harvest_name',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Data da Amostra',
        field: 'sample_date_formated',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Núm. Amostra',
        field: 'sample_id',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Subdivisão',
        field: 'field_subdivision',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Campo',
        field: 'field_name',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Profundidade',
        field: 'depth',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Pratylenchus brachyurus (solo)',
        field: 'pratylenchus_brachyurus_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Pratylenchus brachyurus (raiz)',
        field: 'pratylenchus_brachyurus_root',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Helicotylenchus spp (solo)',
        field: 'helicotylenchus_spp_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Helicotylenchus spp (raiz)',
        field: 'helicotylenchus_spp_root',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Meloidogyne spp (solo)',
        field: 'meloidogyne_spp_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Meloidogyne spp (raiz)',
        field: 'meloidogyne_spp_root',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Rotylenchulus reniformis (solo)',
        field: 'rotylenchulus_reniformis_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Rotylenchulus reniformis (raiz)',
        field: 'rotylenchulus_reniformis_root',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Heterodera glycines (juvenis - solo)',
        field: 'heterodera_glycines_juveniles_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Heterodera glycines (juvenis - raiz)',
        field: 'heterodera_glycines_juveniles_root',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Heterodera glycines (cistos viáveis)',
        field: 'heterodera_glycines_cists_viable',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Heterodera glycines (cistos não viáveis)',
        field: 'heterodera_glycines_cists_unviable',
        dataGeneratorUniquenessEnabled: true,
        width: 300,
        editable: false
      },
      {
        headerName: 'Ovos (solo)',
        field: 'eggs_soil',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Ovos (raiz)',
        field: 'eggs_root',
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false
      },
      {
        headerName: 'Meloidogyne incognita',
        field: 'meloidogyne_incognita',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Meloidogyne javanica',
        field: 'meloidogyne_javanica',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 1',
        field: 'predominant_race1',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 2',
        field: 'predominant_race2',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 3',
        field: 'predominant_race3',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 4',
        field: 'predominant_race4',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 4+',
        field: 'predominant_race4_plus',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 5',
        field: 'predominant_race5',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 6',
        field: 'predominant_race6',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 9',
        field: 'predominant_race9',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 10',
        field: 'predominant_race10',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 14',
        field: 'predominant_race14',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Raça predominante 14+',
        field: 'predominant_race14_plus',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 1',
        field: 'tendency_race1',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 2',
        field: 'tendency_race2',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 3',
        field: 'tendency_race3',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 4',
        field: 'tendency_race4',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 4+',
        field: 'tendency_race4_plus',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 5',
        field: 'tendency_race5',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 6',
        field: 'tendency_race6',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 9',
        field: 'tendency_race9',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 10',
        field: 'tendency_race10',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 14',
        field: 'tendency_race14',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Tendência raça 14+',
        field: 'tendency_race14_plus',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Criado por',
        field: 'created_by_user',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      },
      {
        headerName: 'Data de criação',
        field: 'created_at_formated',
        dataGeneratorUniquenessEnabled: true,
        width: 250,
        editable: false
      }
    ],
    rows: tableResults?.map((tR) => {
      return {
        id: tR.report_nematode_id,
        created_at_formated: dateTimeFriendly(tR.created_at),
        sample_date_formated: formatDate(
          parseToJSDate(tR.sample_date, 'DD/MM/YYYY'),
          'DD/MM/YYYY'
        ),
        ...tR
      };
    })
  };

  return (
    <S.tableContainer>
      <hr />
      {/* <h1>Tabela</h1> */}
      {tableResults === '' ? (
        <S.TableRecomm>
          <tr>
            <td>{null}</td>
          </tr>
        </S.TableRecomm>
      ) : (
        <>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={data.rows}
              columns={data.columns}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
          {/* <S.FilterContainer>
            Filtros
            <S.SelectFilterContainer>
              <SelectInput
                placeholder="Filtro"
                id="filter_type"
                name="filter_type"
                options={filter.map((value) => value.type)}
                value={selectedFilter}
                onChange={(e) => {
                  setSelectedFilter(e);
                }}
                style={{ width: 200 }}
              />
              {selectedFilter !== 'Sem Filtro' && (
                <SelectInput
                  placeholder="Valor"
                  id="filter_values"
                  name="filter_values"
                  options={
                    filter.filter((vl) => vl.type === selectedFilter)[0].values
                  }
                  value={selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e);
                  }}
                  style={{ width: 200 }}
                />
              )}
            </S.SelectFilterContainer>
          </S.FilterContainer>

          <S.TableRecomm>
            <tr className="title">
              <td>Proprietário</td>
              <td>Propriedade</td>
              <td>Município</td>
              <td>UF</td>
              <td>Laboratorio</td>
              <td>Observação</td>
              <td>Safra</td>
              <td>Data</td>
              <td>N. Lab.</td>
              <td>Original</td>
              <td>Campo</td>
              <td>Sub01</td>
              <td>Sub02</td>
              <td>Sub03</td>
              <td>AB</td>
              <td>Prof</td>
              <td>P res IAC</td>
              <td>P res AgrAn.</td>
              <td>P remanescente</td>
              <td>pH_água</td>
              <td>pH_CaCl2</td>
              <td>P</td>
              <td>K</td>
              <td>Ca_Mg</td>
              <td>Ca</td>
              <td>Mg</td>
              <td>Al</td>
              <td>H</td>
              <td>MO</td>
              <td>Areia</td>
              <td>Silte</td>
              <td>Argila</td>
              <td>Sb</td>
              <td>CTC</td>
              <td>V%</td>
              <td>CaMg</td>
              <td>CaK</td>
              <td>MgK</td>
              <td>Ca%</td>
              <td>Mg%</td>
              <td>Al%</td>
              <td>K%</td>
              <td>H%</td>
              <td>Sat. Al</td>
              <td>Zn</td>
              <td>Cu</td>
              <td>Fe</td>
              <td>Mn</td>
              <td>S</td>
              <td>B</td>
              <td>Ação</td>
            </tr>
            {tableResults.map((item) =>
              selectedFilter === 'Sem Filtro' ? (
                <tr className="content">
                  <td>{item.proprietario}</td>
                  <td>{item.propriedade}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                  <td>{item.laboratorio}</td>
                  <td>{item.observacao}</td>
                  <td>{item.safra}</td>
                  <td>{item.data}</td>
                  <td>{item.nLab}</td>
                  <td>{item.original}</td>
                  <td>{item.campo}</td>
                  <td>{item.sub01}</td>
                  <td>{item.sub02}</td>
                  <td>{item.sub03}</td>
                  <td>{item.ab}</td>
                  <td>{item.prof}</td>
                  <td>{item.pResIAC}</td>
                  <td>{item.pResAgrAn}</td>
                  <td>{item.pRemanescente}</td>
                  <td>{item.pH_agua}</td>
                  <td>{item.pH_CaCl2}</td>
                  <td>{item.p}</td>
                  <td>{item.k}</td>
                  <td>{item.ca_Mg}</td>
                  <td>{item.ca}</td>
                  <td>{item.mg}</td>
                  <td>{item.al}</td>
                  <td>{item.h}</td>
                  <td>{item.mo}</td>
                  <td>{item.areia}</td>
                  <td>{item.silte}</td>
                  <td>{item.argila}</td>
                  <td>{item.sb}</td>
                  <td>{item.ctc}</td>
                  <td>{item.v}</td>
                  <td>{item.caMg}</td>
                  <td>{item.caK}</td>
                  <td>{item.mgK}</td>
                  <td>{item.cap}</td>
                  <td>{item.mgp}</td>
                  <td>{item.alp}</td>
                  <td>{item.kp}</td>
                  <td>{item.hp}</td>
                  <td>{item.satAl}</td>
                  <td>{item.zn}</td>
                  <td>{item.cu}</td>
                  <td>{item.fe}</td>
                  <td>{item.mn}</td>
                  <td>{item.s}</td>
                  <td>{item.b}</td>
                  <td>
                    <Button className="buttonTable" text="Aplicar" />
                  </td>
                </tr>
              ) : selectedFilter !== 'Sem Filtro' &&
                item[
                  filter.filter((f) => f.type === selectedFilter)[0].collumn
                ] === selectedValue ? (
                <tr className="content">
                  <td>{item.proprietario}</td>
                  <td>{item.propriedade}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                  <td>{item.laboratorio}</td>
                  <td>{item.observacao}</td>
                  <td>{item.safra}</td>
                  <td>{item.sata}</td>
                  <td>{item.nLab}</td>
                  <td>{item.original}</td>
                  <td>{item.campo}</td>
                  <td>{item.sub01}</td>
                  <td>{item.sub02}</td>
                  <td>{item.sub03}</td>
                  <td>{item.ab}</td>
                  <td>{item.prof}</td>
                  <td>{item.pResIAC}</td>
                  <td>{item.pResAgrAn}</td>
                  <td>{item.pRemanescente}</td>
                  <td>{item.pH_agua}</td>
                  <td>{item.pH_CaCl2}</td>
                  <td>{item.p}</td>
                  <td>{item.k}</td>
                  <td>{item.ca_Mg}</td>
                  <td>{item.ca}</td>
                  <td>{item.mg}</td>
                  <td>{item.al}</td>
                  <td>{item.h}</td>
                  <td>{item.mo}</td>
                  <td>{item.areia}</td>
                  <td>{item.silte}</td>
                  <td>{item.argila}</td>
                  <td>{item.sb}</td>
                  <td>{item.ctc}</td>
                  <td>{item.v}</td>
                  <td>{item.caMg}</td>
                  <td>{item.caK}</td>
                  <td>{item.mgK}</td>
                  <td>{item.cap}</td>
                  <td>{item.mgp}</td>
                  <td>{item.alp}</td>
                  <td>{item.kp}</td>
                  <td>{item.hp}</td>
                  <td>{item.satAl}</td>
                  <td>{item.zn}</td>
                  <td>{item.cu}</td>
                  <td>{item.fe}</td>
                  <td>{item.mn}</td>
                  <td>{item.s}</td>
                  <td>{item.b}</td>
                  <td>
                    <Button className="buttonTable" text="Aplicar" />
                  </td>
                </tr>
              ) : null
            )}
          </S.TableRecomm> */}
        </>
      )}
    </S.tableContainer>
  );
};

export default TableNematode;
