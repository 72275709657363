import dayjs, { ManipulateType, UnitTypeLong } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createHash } from 'crypto';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

export const uuid = () => {
  return uuidv4();
};

export const localDateTime = (date: Date | undefined): string => {
  if (!date) {
    date = new Date();
  }
  const padZero = (num: number) => (num < 10 ? `0${num}` : num);
  const yyyy = date.getFullYear();
  const mm = padZero(date.getMonth() + 1);
  const dd = padZero(date.getDate());
  const hh = padZero(date.getHours());
  const mn = padZero(date.getMinutes());
  const ss = padZero(date.getSeconds());
  return `${yyyy}-${mm}-${dd}T${hh}:${mn}:${ss}Z`;
};

export const phoneMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const generateColor = (...factors) => {
  // Create a unique string based on the order and combination of factors
  const factorStr = factors.join('-');

  // Generate a hash from the string
  const hash = createHash('md5').update(factorStr).digest('hex');

  // Convert the hash to an RGB color code by spreading the influence across more characters
  const r = parseInt(hash.substring(0, 2), 16);
  const g = parseInt(hash.substring(2, 4), 16);
  const b = parseInt(hash.substring(4, 6), 16);
  const a = parseInt(hash.substring(6, 8), 16) / 255; // Alpha for better differentiation

  return `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
};

export const delay = (milliseconds: number) => {
  return new Promise((resolve: any) => {
    setTimeout(resolve, milliseconds);
  });
};

export const parseDate = (dateString: string, format: string) => {
  if (!format) {
    format = 'DD/MM/YYYY HH:mm:ss';
  }
  return dayjs(dateString, format);
};

export const formatDate = (
  date: dayjs.Dayjs | string | Date,
  format?: string
) => {
  if (!format) {
    format = 'YYYY-MM-DD HH:mm:ss';
  }
  return dayjs(date).format(format);
};

export const getCurrentDate = (format?: string) => {
  if (!format) {
    format = 'YYYY-MM-DD HH:mm:ss';
  }
  return dayjs().format(format);
};

export const isFutureDate = (date: dayjs.Dayjs | string | Date) => {
  return dayjs(date).isAfter(dayjs());
};

export const isPastDate = (date: dayjs.Dayjs | string | Date) => {
  return dayjs(date).isBefore(dayjs());
};

export const modifyDate = (
  date: dayjs.Dayjs | string | Date,
  amount: number,
  unit: ManipulateType
) => {
  return dayjs(date).add(amount, unit).format('YYYY-MM-DD HH:mm:ss');
};

export const convertToUTC = (date: dayjs.Dayjs | string | Date) => {
  return dayjs(date).utc().format();
};

export const convertToTimezone = (
  date: dayjs.Dayjs | string | Date,
  tz: string
) => {
  return dayjs(date).tz(tz).format('YYYY-MM-DD HH:mm:ss');
};

export const getDateDifference = (
  date1: dayjs.Dayjs | string | Date,
  date2: dayjs.Dayjs | string | Date,
  unit: UnitTypeLong
) => {
  return dayjs(date1).diff(dayjs(date2), unit);
};

export const isDateBetween = (date, startDate, endDate) => {
  return (
    dayjs(date).isSameOrAfter(dayjs(startDate)) &&
    dayjs(date).isSameOrBefore(dayjs(endDate))
  );
};

export const dateTimeFriendly = (date: string | Date | dayjs.Dayjs) => {
  return dayjs(date).format('DD/MM/YYYY [às] HH:mm');
};

export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes === 0) return '0 Bytes';

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const index = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const formattedSize = (sizeInBytes / 1024 ** index).toFixed(2);

  return `${formattedSize} ${units[index]}`;
};

export const parseToJSDate = (
  dateString: string,
  format = 'DD/MM/YYYY HH:mm:ss'
): Date => {
  return dayjs(dateString, format).toDate();
};

export const dateNow = () => {
  return new Date().toISOString().split('T')[0];
};

export const dateTimeNow = () => {
  return dayjs().format('YYYY-MM-DDTHH:mm:ss');
};
