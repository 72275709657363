/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  MdDelete,
  MdModeEdit,
  MdKeyboardArrowRight,
  MdCheck
} from 'react-icons/md';
import { PuffLoader } from 'react-spinners';
import Checkbox from 'react-custom-checkbox';
import ConfirmDialog from './ConfirmDialog';
import DeletePointDialog from './DeletePointDialog';
import { DashboardContext } from '../../../..';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import { appConfig } from '../../../../../../config';

export const RegisterWalkSubdivisionContext = createContext({});

const RegisterWalkSubdivision = () => {
  const {
    selectedSubdivision,
    setDashboardState,
    subdivisionsMappings,
    setSubdivisionsMappings,
    collectionPointsList,
    setCollectionPointsList
  } = useContext(DashboardContext);
  const {
    createWalkSubdivision,
    walkOfSubdivision,
    selectedFarmId,
    removeMappingGeometries,
    renderFieldMappings,
    modifyWalkOfSubdivision,
    removeSubdivisionGeometries,
    removeAllInterections,
    renderPoint,
    removePointGeometries,
    renderCollectionPoints,
    renderDepthPoints,
    subdivision,
    modifyCollectionPoint,
    createCollectionPoints
  } = useMap();
  const { user, token } = useAuth();
  const [drawing, setDrawing] = useState(false);
  const { openModal } = useModal();
  const [selectDeleteWalkSubdivision, setSelectDeleteWalkSubdivision] =
    useState();
  const [selectDeleteCollectionPoint, setSelectDeleteCollectionPoint] =
    useState();
  const [loading, setLoading] = useState(false);
  const [depthPoints, setDepthPoints] = useState([]);
  const [walkingBySubdivision, setWalkingBySubdivision] = useState([]);
  const [pointDialog, setPointDialog] = useState(false);
  const [selectedPointId, setSelectedPointId] = useState([]);
  const [selectedWalkId, setSelectedWalkId] = useState([]);
  const [chekedAllPoints, setCheckedAllPoints] = useState(false);
  const [chekedAllWalking, setCheckedAllWalking] = useState(false);

  const registerWalkOfSubdivision = async () => {
    walkOfSubdivision.features[0].properties = {
      farm_id: selectedFarmId,
      fieldList: walkOfSubdivision.features[0].properties.fieldList,
      subdivision_id: selectedSubdivision.features[0].properties.subdivision_id,
      created_by: user.user_id
    };

    try {
      await api.post(
        `${appConfig.apiHosts.field}/FieldMapping/PostFieldMapping`,
        walkOfSubdivision,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(
        <Toast title="Caminhamento da Subdivisão cadastrado com sucesso" />,
        { type: 'success' }
      );
      setDashboardState('dashboard@harvestmap');
      removeAllInterections();
    } catch (error) {
      toast(
        <Toast title="Não foi possivel cadastar o caminhamento da subdivisão" />,
        { type: 'error' }
      );
    }
  };

  const getSubdivisionsMappings = async () => {
    setLoading(true);
    try {
      if (!selectedFarmId) return;
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/FieldMapping/GetActiveFieldMappingListByFarmIdWhereSubdivisionIdIsNotNull`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // eslint-disable-next-line no-console
      setSubdivisionsMappings(response.data.data);
      removeMappingGeometries();
      renderFieldMappings(
        response.data.data.filter(
          (value) =>
            value.features[0].properties.subdivision_id ===
              selectedSubdivision.features[0].properties.subdivision_id ?? value
        )
      );
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleDeleteWalkSubdivision = async (walkSubdivisionId) => {
    const params = new URLSearchParams([
      ['fieldMappingId', walkSubdivisionId],
      ['status', false]
    ]);
    try {
      await api.delete(
        `${appConfig.apiHosts.field}/FieldMapping/DeleteFieldMapping`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      removeMappingGeometries();
      getSubdivisionsMappings();
      toast(<Toast title="Caminhamento de Subdivisão removida com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover o caminhamento da subdivisão."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const getPoints = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const cPoints = await response.data.data.filter(
        (value) => value.features[0].properties.type === 'REFERENCIA_COLETA'
      );
      renderCollectionPoints(cPoints);
    } catch (error) {
      console.error(error);
    }
  };

  const getCollectionPointsList = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const pointsForCurrentSubdivision = response.data.data.filter(
        (point) =>
          point.features[0].properties.subdivision_id ===
          selectedSubdivision.features[0].properties.subdivision_id
      );

      const depthPoints = pointsForCurrentSubdivision
        .filter((point) => point.features[0].properties.type === 'PROFUNDIDADE')
        .sort((a, b) => {
          const nameA = a.features[0].properties.name || '';
          const nameB = b.features[0].properties.name || '';
          return nameA.localeCompare(nameB);
        });

      const collectionPoints = pointsForCurrentSubdivision
        .filter(
          (point) => point.features[0].properties.type === 'REFERENCIA_COLETA'
        )
        .sort((a, b) => {
          const dateA = new Date(a.features[0].properties.created_at);
          const dateB = new Date(b.features[0].properties.created_at);
          return dateA - dateB;
        });

      const orderedPoints = [...depthPoints, ...collectionPoints];

      removePointGeometries();
      setCollectionPointsList(orderedPoints);

      renderDepthPoints(depthPoints);
      renderCollectionPoints(collectionPoints);
    } catch (error) {
      console.error(error);
    }
  };

  const getCollectionPoint = async (id) => {
    try {
      const params = new URLSearchParams([['dataCollectionId', id]]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetDataCollectionPointByDataCollectionId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      renderPoint(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const deleteCollectionPoint = async (id) => {
    const params = new URLSearchParams([
      ['dataCollectionId', id],
      ['status', false]
    ]);
    try {
      await api.delete(
        `${appConfig.apiHosts.field}/DataCollectionPoint/DeleteDataCollectionPoint`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      removePointGeometries();
      getPoints();
      toast(<Toast title="Ponto de coleta removido com sucesso" />, {
        type: 'success'
      });
      getCollectionPointsList();
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover o ponto de coleta."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const getWalkingBySubdivision = async () => {
    const getWalking = await subdivisionsMappings.filter(
      (value) =>
        value.features[0].properties.subdivision_id ===
        selectedSubdivision.features[0].properties.subdivision_id
    );

    setWalkingBySubdivision(getWalking);
  };

  const deleteMultipleWalking = async () => {
    try {
      selectedWalkId.map(async (v) => {
        const params = new URLSearchParams([
          ['fieldMappingId', v],
          ['status', false]
        ]);
        await api.delete(
          `${appConfig.apiHosts.field}/FieldMapping/DeleteFieldMapping`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        removeMappingGeometries();
        getSubdivisionsMappings();
        setSelectedWalkId([]);
        setCheckedAllWalking(false);
      });
      toast(<Toast title="Caminhamento de Subdivisão removido com sucesso" />, {
        type: 'success'
      });
    } catch {
      toast(
        <Toast
          title="Não foi possível remover o caminhamento."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const deleteMultipleCollectionPoint = async () => {
    try {
      selectedPointId.map(async (v) => {
        const params = new URLSearchParams([
          ['dataCollectionId', v],
          ['status', false]
        ]);
        await api.delete(
          `${appConfig.apiHosts.field}/DataCollectionPoint/DeleteDataCollectionPoint`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        removePointGeometries();
        setSelectedPointId([]);
        setCheckedAllPoints(false);
        getPoints();
        getCollectionPointsList();
      });

      toast(<Toast title="Pontos de coleta removido com sucesso" />, {
        type: 'success'
      });
      getCollectionPointsList();
    } catch {
      toast(
        <Toast
          title="Não foi possível remover ponto de coleta."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const insertAllSelectedPoints = () => {
    setSelectedPointId(
      collectionPointsList.map(
        (value) => value.features[0].properties.datacollection_id
      )
    );
  };

  const insertAllSelectedWalking = () => {
    setSelectedWalkId(
      subdivisionsMappings.map(
        (value) => value.features[0].properties.field_mapping_id
      )
    );
  };

  useEffect(() => {
    getSubdivisionsMappings();
    getCollectionPointsList();
    getWalkingBySubdivision();
  }, []);

  return (
    <RegisterWalkSubdivisionContext.Provider
      value={{
        handleDeleteWalkSubdivision,
        deleteCollectionPoint,
        setPointDialog
      }}
    >
      <S.Container>
        <SimpleButton
          text="Voltar"
          onClick={() => {
            removeMappingGeometries();
            removeSubdivisionGeometries();
            removeAllInterections();
            setDashboardState('dashboard@harvestmap');
          }}
        />
        <h1>Subdivisão {subdivision.features[0].properties.description}</h1>
        <hr />
      </S.Container>
      <S.Content>
        <S.Title>Caminhamento</S.Title>
        <S.DelContainer>
          {walkingBySubdivision.length > 0 && (
            <S.DeletePointSelectedButton
              onClick={() => {
                if (selectedWalkId.length > 0) {
                  return setSelectedWalkId([]), setCheckedAllWalking(false);
                }
                insertAllSelectedWalking();
                setCheckedAllWalking(true);
              }}
            >
              <Checkbox
                checked={chekedAllWalking}
                icon={
                  <MdCheck color="#507919" size={16} style={{ margin: 0 }} />
                }
                borderColor="#A0BD39"
                borderRadius={2}
                borderWidth={1}
                size={13}
                style={{ cursor: 'pointer' }}
              />
              <span className="selectAll">Selecionar todos</span>
            </S.DeletePointSelectedButton>
          )}
          {walkingBySubdivision.length > 0 && (
            <S.DeletePointSelectedButton
              onClick={() => {
                deleteMultipleWalking();
              }}
              type="button"
            >
              <MdDelete size={18} style={{ paddingBottom: 3 }} />
              <span>Deletar</span>
            </S.DeletePointSelectedButton>
          )}
        </S.DelContainer>
        <S.WalksSUbdivisionContainer>
          {walkingBySubdivision.length > 0 ? (
            subdivisionsMappings.map(
              (value) =>
                value.features[0].properties.subdivision_id ===
                  selectedSubdivision.features[0].properties.subdivision_id && (
                  <S.WalksSUbdivisionCard>
                    <S.WalksSUbdivisionHeader>
                      <Checkbox
                        checked={selectedWalkId.includes(
                          value.features[0].properties.field_mapping_id
                        )}
                        icon={
                          <MdCheck
                            color="#507919"
                            size={16}
                            style={{ margin: 0 }}
                          />
                        }
                        borderColor="#A0BD39"
                        borderRadius={2}
                        borderWidth={1}
                        onChange={() => {
                          if (
                            selectedWalkId.includes(
                              value.features[0].properties.field_mapping_id
                            )
                          ) {
                            return selectedWalkId.splice(
                              selectedWalkId.indexOf(
                                value.features[0].properties.field_mapping_id
                              ),
                              1
                            );
                          }
                          selectedWalkId.push(
                            value.features[0].properties.field_mapping_id
                          );
                        }}
                        size={12}
                        style={{ cursor: 'pointer' }}
                      />
                      <S.WalkSubdivisionTitle>
                        {value.features[0].properties.description}
                      </S.WalkSubdivisionTitle>
                    </S.WalksSUbdivisionHeader>
                    <S.WalkSubdivisionInformations />
                    <S.WalkSubdivisionActions>
                      <button
                        type="button"
                        onClick={() => {
                          modifyWalkOfSubdivision(value);
                          setDashboardState('dashboard@edit_walk_subdivision');
                        }}
                      >
                        Editar
                        <MdModeEdit />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          openModal();
                          setSelectDeleteWalkSubdivision(value);
                        }}
                      >
                        Deletar
                        <MdDelete />
                      </button>
                    </S.WalkSubdivisionActions>
                  </S.WalksSUbdivisionCard>
                )
            )
          ) : (
            <span>Sem Caminhamentos cadastrados</span>
          )}
        </S.WalksSUbdivisionContainer>
      </S.Content>
      <S.Content>
        <S.Title>Pontos de Coleta</S.Title>
        <S.DelContainer>
          {collectionPointsList.length > 0 && (
            <S.DeletePointSelectedButton
              onClick={() => {
                if (selectedPointId.length > 0) {
                  return setSelectedPointId([]), setCheckedAllPoints(false);
                }
                insertAllSelectedPoints();
                setCheckedAllPoints(true);
              }}
            >
              <Checkbox
                checked={chekedAllPoints}
                icon={
                  <MdCheck color="#507919" size={16} style={{ margin: 0 }} />
                }
                borderColor="#A0BD39"
                borderRadius={2}
                borderWidth={1}
                size={13}
                style={{ cursor: 'pointer' }}
              />
              <span className="selectAll">Selecionar todos</span>
            </S.DeletePointSelectedButton>
          )}
          {collectionPointsList.length > 0 && (
            <S.DeletePointSelectedButton
              onClick={() => {
                deleteMultipleCollectionPoint();
              }}
              type="button"
            >
              <MdDelete size={18} style={{ paddingBottom: 3 }} />
              <span>Deletar</span>
            </S.DeletePointSelectedButton>
          )}
        </S.DelContainer>

        <S.CollectionPointsContainer>
          {collectionPointsList.length > 0 ? (
            collectionPointsList.map((value, index) => (
              <S.CollectionPoint
                key={value.features[0].properties.datacollection_id}
              >
                <S.CollectionPointHeader>
                  <Checkbox
                    checked={selectedPointId.includes(
                      value.features[0].properties.datacollection_id
                    )}
                    icon={
                      <MdCheck
                        color="#507919"
                        size={16}
                        style={{ margin: 0 }}
                      />
                    }
                    borderColor="#A0BD39"
                    borderRadius={2}
                    borderWidth={1}
                    onChange={() => {
                      if (
                        selectedPointId.includes(
                          value.features[0].properties.datacollection_id
                        )
                      ) {
                        return selectedPointId.splice(
                          selectedPointId.indexOf(
                            value.features[0].properties.datacollection_id
                          ),
                          1
                        );
                      }
                      selectedPointId.push(
                        value.features[0].properties.datacollection_id
                      );
                    }}
                    size={12}
                    style={{ cursor: 'pointer' }}
                  />
                  <h4>
                    {value.features[0].properties.name || `Ponto ${index + 1}`}{' '}
                    - {value.features[0].properties.subdivision_description}
                    {value.features[0].properties.depth &&
                      ` (${value.features[0].properties.depth})`}
                  </h4>
                  <button
                    type="button"
                    onClick={() => {
                      getCollectionPoint(
                        value.features[0].properties.datacollection_id
                      );
                    }}
                  >
                    <MdKeyboardArrowRight size={24} color="#444444" />
                  </button>
                </S.CollectionPointHeader>
                <S.CollectionPointActions>
                  <button
                    type="button"
                    onClick={() => {
                      setPointDialog(true);
                      openModal();
                      setSelectDeleteCollectionPoint(
                        value.features[0].properties.datacollection_id
                      );
                    }}
                  >
                    Deletar
                    <MdDelete />
                  </button>
                </S.CollectionPointActions>
              </S.CollectionPoint>
            ))
          ) : (
            <span>Sem pontos de coleta cadastrados</span>
          )}
        </S.CollectionPointsContainer>
      </S.Content>
      {pointDialog ? (
        <DeletePointDialog
          messageDialog="Deseja realmente deletar o ponto de coleta ?"
          collectionPoint={selectDeleteCollectionPoint}
        />
      ) : (
        <ConfirmDialog
          messageDialog="Deseja realmente deletar o caminhamento da subdivisão ?"
          walkSubdivision={selectDeleteWalkSubdivision}
        />
      )}
    </RegisterWalkSubdivisionContext.Provider>
  );
};

export default RegisterWalkSubdivision;
