import React from 'react';
import LottieFile from 'react-lottie';
import erase from '../../assets/animations/delete.json';
import exclamation from '../../assets/animations/exclamation.json';

export const animations = {
  delete: erase,
  exclamation
};

interface ILottie {
  animation: keyof typeof animations;
}

export const Lottie = ({ animation }: ILottie) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[animation],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <LottieFile
      options={defaultOptions}
      height={3000}
      width={300}
      isStopped={false}
      isPaused={false}
    />
  );
};
