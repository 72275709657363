import React from 'react';
import { Typography } from '@mui/material';
import * as S from './styled';

interface CardSelectProps {
  text: string;
  textStyles?: React.CSSProperties;
  selected?: boolean;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLButtonElement>) => void;
  onClick: () => void;
}

const CardSelect = ({
  text,
  textStyles,
  selected = false,
  disabled = false,
  onChange = undefined,
  onClick
}: CardSelectProps) => {
  return (
    <S.Container
      selected={selected}
      onClick={onClick}
      disabled={disabled}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <S.TextCustom
        selected
        style={{
          margin: 0,
          color: selected ? '#fff' : '#000',
          fontWeight: 'normal',
          fontSize: '0.8rem',
          ...textStyles
        }}
      >
        {text}
      </S.TextCustom>
    </S.Container>
  );
};

export default CardSelect;
