/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import Swipe from 'ol-ext/control/Swipe';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { v4 as uuid } from 'uuid';
import filesize from 'filesize';
import Carousel from '../../components/Carousel';
import DynamicLegend from '../../components/DynamicLegend';
import FilterCard from '../../components/FilterCard';
import FilterCloudCard from '../../components/FilterCloudCard';
import FlagEnviroment from '../../components/FlagEnviroment';
import Header from '../../components/Header';
import NavBar from '../../components/NavBar';
import SelectSatelliteCard from '../../components/SelectSatelliteCard';
import TimeLine from '../../components/TimeLine';
import UploadShapefileCard from '../../components/UploadShapefileCard';
import WebMap from '../../components/WebMap';
import WebMap1 from '../../components/WebMap1';
import WebMap2 from '../../components/WebMap2';
import WebMap3 from '../../components/WebMap3';
import WebMap4 from '../../components/WebMap4';
import { appConfig } from '../../config';
import { getMapScale } from '../../helpers/getMapScale';
import { useAuth } from '../../hooks/Auth';
import { useFullLoading, FullLoadingProvider } from '../../hooks/FullLoading';
import { useMap } from '../../hooks/Map';
import { useModal } from '../../hooks/Modal';
// import ResultReport from './components/Results/components/ResultReport';
import { dashboardStates } from './states';
import {
  ButtonContainer,
  Container,
  Content,
  MainContainer,
  SideNav,
  NavContentContainer
} from './styled';
import ResultReport from './components/Results/components/ResultReport';
import { EReportType } from './components/Results';

export const DashboardContext = createContext();

const Dashboard = () => {
  const { closeModal, openModal } = useModal();
  const [resultVisible, setResultVisible] = useState(false);
  const [recommendationVisible, setRecommendationVisible] = useState(false);
  const [options, setOptions] = useState(['Argila', 'Matéria Orgânica', 'pH', 'Cálcio', 'Magnésio (Mg)', 'Potássio (K)',
    'Alumínio (Al)', 'CTC', 'Saturação por Bases (V%)', 'Saturação de Alumínio na CTC efetiva (m%)',
    'Fósforo', 'Enxofre (S)']);
  const [selectedMonitoring, setSelectedMonitoring] = useState('');
  const [selectedOption, setSelectedOption] = useState('pH_água');
  const [labels, setLabels] = useState(['T1-A', 'T1-B']);
  const [results, setResults] = useState([65, 59]);
  const [farms, setFarms] = useState([]);
  const [fields, setFields] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [collectionPointsList, setCollectionPointsList] = useState([]);
  const [selectedSubdivision, setSelectedSubdivisions] = useState();
  const [subdivisionsMappings, setSubdivisionsMappings] = useState([]);
  const [selectedHarvestMap, setSelectedHarvestMap] = useState();
  const [visibleMultiMaps, setVisibleMultiMaps] = useState(false);
  const [dashboardState, setDashboardState] = useState('dashboard@farm');
  const [hideNav, setHideNav] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [chatNotifications, setChatNotifications] = useState([]);
  const [hideCarouselOptions, sethideCarouselOptions] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [selectedManagement, setSelectedManagement] = useState('');
  const [itemTypeSelected, setItemTypeSelected] = useState('');
  const [openedModal, setOpendedModal] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFilesScale, setUploadedFilesScale] = useState([]);
  const [itemSelectedVisible, setItemSelectedVisible] = useState('');
  const [updateScreen, setUpdateScreen] = useState(false);
  const [selectedAdmItem, setSelectedAdmItem] = useState();
  const [currentModal, setCurrentModal] = useState('');
  const [problemId, setProblemId] = useState('');
  const [selectedProblem, setSelectedProblem] = useState('');
  const [selectedRecommendation, setSelectedRecommendation] = useState('');
  const [problemList, setProblemList] = useState([]);
  const [listTypeManager, setListTypeManager] = useState([]);
  const [selectTypeProblemList, setSelectTypeProblemList] = useState([]);
  const [selectTypeCultureList, setSelectTypeCultureList] = useState([]);
  const [selectTypeRecoomendationList, setSelectTypeRecoomendationList] = useState([]);
  const [selectTypeApplicationList, setSelectTypeApplicationList] = useState([]);
  const [selectTypeAlertList, setSelectTypeAlertList] = useState([]);
  const [selectTypeRoutineList, setSelectTypeRoutineList] = useState([]);
  const [selectTypeCoverageList, setSelectTypeCoverageList] = useState([]);
  const [selectedTypeTaskList, setSelectedTypeTaskList] = useState([]);
  const [selectedRoutine, setSelectedRoutine] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState([]);
  const [selectTypeCustomerRecommendationList, setSelectTypeCustomerRecommendationList] = useState([]);
  const [selectedTypeAppZoneList, setSelectedTypeAppZoneList] = useState([]);
  const [selectedTypeProductList, setSelectedTypeProductList] = useState([]);
  const [selectedTypeGrowCropList, setSelectedTypeGrowCropList] = useState([]);
  const [selectedTypeVolumeList, setSelectedTypeVolumeList] = useState([]);
  const [selectedTypeAppTimeList, setSelectedTypeAppTimeList] = useState([]);
  const [selectedTypeAppModeList, setSelectedTypeAppModeList] = useState([]);
  const [selectedCustomerRecommendation, setSelectedCustomerRecommendation] = useState([]);
  const [selectTypePeriodList, setSelectTypePeriodList] = useState([]);
  const [selectedPrague, setSelectedPrague] = useState();
  const [selectedWeed, setSelectedWeed] = useState();
  const [selectedDisease, setSelectedDisease] = useState();
  const [prague_cropName, setPrague_CropName] = useState([]);
  const [prague_plantName, setPrague_PlantName] = useState([]);
  const [disease_cropName, setDisease_cropName] = useState([]);
  const [disease_plantName, setDisease_plantName] = useState([]);
  const [imageDiagrammaticScale, setImageDiagrammaticScale] = useState([]);
  const [listPragues, setListPragues] = useState([]);
  const [filePhotoScale, setFilePhotoScale] = useState([]);
  const [cropTypeName, setCropTypeName] = useState('');
  const [oldCropTypeName, setOldCropTypeName] = useState('');
  const [cropSubTypeName, setCropSubTypeName] = useState('');
  const [reportType, setReportType] = useState('none');
  const [harvests, setHarvests] = useState([]);
  const updateFile = useCallback((id, data) => {
    setUploadedFiles((state) =>
      state.map((file) => (file.id === id ? { ...file, ...data } : file))
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile) => {
      const data = new FormData();
      try {
        if (uploadedFile.file) {
          data.append('file', uploadedFile.file, uploadedFile.name);
        }

        updateFile(uploadedFile.id, {
          uploaded: true,
          id: uuid(),
          url: uploadedFile.url,
        });
      } catch (err) {
        console.error(
          `Houve um problema para fazer upload da imagem ${uploadedFile.name} no servidor FireBase`
        );
        console.error(err);

        updateFile(uploadedFile.id, { error: true, });
      }
    },
    [updateFile]);

  const handleUpload = useCallback(
    (files) => {
      const newUploadedFiles = files.map((file) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: '',
        legend: ''
      }));
      setUploadedFiles((state) => state.concat(newUploadedFiles));
      newUploadedFiles.forEach(processUpload);
    },
    [processUpload]
  );

  const deleteFile = useCallback((id) => {
    // api.delete(`posts/${id}`);
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
    setUploadedFilesScale((state) => state.filter((file) => file.id !== id));
  }, []);

  const handleActionSideNavigation = useCallback(() => {
    setHideNav(!hideNav);
  }, [hideNav]);

  const handleCarousel = useCallback(() => {
    sethideCarouselOptions(!hideCarouselOptions);
  }, [hideCarouselOptions]);

  const { user, token, getNotifications } = useAuth();
  // const {  } = useFullLoading();
  const {
    controlState,
    removeGeometriesColoredSubdivisions,
    setSwapActived,
    refMap,
    removeGeometriesSwipe,
    activedSwipe,
    setBtnColor,
    renderedMaps,
    selectedFarmId,
    setShowButtonChat,
    showButtonCarousel,
    setShowButtonCarousel,
    renderFarm,
    selectedFarmGeoJson,
    setRenderedMaps,
    removeColoredRecommendationZone,
    disableTileMap,
    setShowChat,
    disableQueryNotification,
    setDisableQueryNotification
  } = useMap();
  const contextValues = {
    handleActionSideNavigation,
    dashboardState,
    setDashboardState,
    farms,
    setFarms,
    fields,
    setFields,
    subdivisions,
    setSubdivisions,
    subdivisionsMappings,
    setSubdivisionsMappings,
    selectedSubdivision,
    setSelectedSubdivisions,
    selectedHarvestMap,
    setSelectedHarvestMap,
    showResult,
    setShowResult,
    selectedOption,
    setSelectedOption,
    results,
    setResults,
    options,
    setOptions,
    labels,
    setLabels,
    hideCarouselOptions,
    sethideCarouselOptions,
    resultVisible,
    setResultVisible,
    visibleMultiMaps,
    setVisibleMultiMaps,
    recommendationVisible,
    setRecommendationVisible,
    collectionPointsList,
    setCollectionPointsList,
    notifications,
    setNotifications,
    chatNotifications,
    setChatNotifications,
    selectedManagement,
    setSelectedManagement,
    openedModal,
    setOpendedModal,
    uploadedFiles,
    deleteFile,
    handleUpload,
    itemSelectedVisible,
    setItemSelectedVisible,
    itemTypeSelected,
    setItemTypeSelected,
    updateScreen,
    setUpdateScreen,
    selectedAdmItem,
    setSelectedAdmItem,
    currentModal,
    setCurrentModal,
    problemId,
    setProblemId,
    setUploadedFiles,
    selectedProblem,
    setSelectedProblem,
    selectedRecommendation,
    setSelectedRecommendation,
    problemList,
    setProblemList,
    listTypeManager,
    setListTypeManager,
    selectTypeProblemList,
    setSelectTypeProblemList,
    selectTypeCultureList,
    setSelectTypeCultureList,
    selectTypeRecoomendationList,
    setSelectTypeRecoomendationList,
    selectTypeApplicationList,
    setSelectTypeApplicationList,
    selectTypeAlertList,
    setSelectTypeAlertList,
    selectTypeRoutineList,
    setSelectTypeRoutineList,
    selectTypeCoverageList,
    setSelectTypeCoverageList,
    selectedTypeTaskList,
    setSelectedTypeTaskList,
    selectedRoutine,
    setSelectedRoutine,
    selectedTechnician,
    setSelectedTechnician,
    selectTypeCustomerRecommendationList,
    setSelectTypeCustomerRecommendationList,
    selectedTypeAppZoneList,
    setSelectedTypeAppZoneList,
    selectedTypeProductList,
    setSelectedTypeProductList,
    selectedTypeGrowCropList,
    setSelectedTypeGrowCropList,
    selectedTypeVolumeList,
    setSelectedTypeVolumeList,
    selectedTypeAppTimeList,
    setSelectedTypeAppTimeList,
    selectedTypeAppModeList,
    setSelectedTypeAppModeList,
    selectedCustomerRecommendation,
    setSelectedCustomerRecommendation,
    selectTypePeriodList,
    setSelectTypePeriodList,
    selectedPrague,
    setSelectedPrague,
    selectedWeed,
    setSelectedWeed,
    selectedDisease,
    setSelectedDisease,
    prague_cropName,
    setPrague_CropName,
    prague_plantName,
    setPrague_PlantName,
    listPragues,
    setListPragues,
    uploadedFilesScale,
    setUploadedFilesScale,
    disease_cropName,
    setDisease_cropName,
    disease_plantName,
    setDisease_plantName,
    imageDiagrammaticScale,
    setImageDiagrammaticScale,
    filePhotoScale,
    setFilePhotoScale,
    cropTypeName,
    setCropTypeName,
    oldCropTypeName,
    setOldCropTypeName,
    cropSubTypeName,
    setCropSubTypeName,
    setSelectedMonitoring,
    selectedMonitoring,
    setReportType,
    reportType,
    setHarvests,
    harvests
  };

  useEffect(() => {
    handleActionSideNavigation();
    setResultVisible(false);
    setRecommendationVisible(false);
    removeColoredRecommendationZone();
  }, []);

  useEffect(() => {
    if (dashboardState === 'dashboard@report') {
      disableTileMap(false);
    }
    if (dashboardState !== 'dashboard@report' && dashboardState !== 'dashboard@createdReports') {
      disableTileMap(true);
    }
  }, [dashboardState]);

  useEffect(() => {
    if (dashboardState === 'dashboard@report') {
      setShowButtonCarousel(false);
    }
  }, [dashboardState]);

  useEffect(() => {
    if (showResult === true) {
      setDisableQueryNotification(false);
    } else {
      setDisableQueryNotification(true);
    }
  }, [showResult]);

  useEffect(() => {
    { /* Erro de digitação */ }
    if (activedSwipe && dashboardState !== 'dashboard@results') {
      refMap.getControls().forEach((c) => {
        if (c instanceof Swipe) {
          refMap.removeControl(c);
          removeGeometriesSwipe();
        }
      });
      setSwapActived(false);
      setBtnColor(false);
    }
  }, [dashboardState]);

  useEffect(() => {
    setShowButtonCarousel(false);
  }, [selectedFarmId]);

  useEffect(() => {
    sethideCarouselOptions(true);

    if (dashboardState === 'dashboard@results' || dashboardState === 'dashboard@resultsMap') {
      setShowButtonChat(true);
    } else {
      setShowButtonChat(false);
    }

    getNotifications(user.email, token).then((response) => {
      setNotifications(response.notifications);
      setChatNotifications(response.chatNotifications);
    });
  }, [dashboardState]);

  useEffect(() => {
    if (renderedMaps.length > 0) {
      renderFarm(selectedFarmGeoJson);
    }
    if (selectedFarmId !== '') {
      renderFarm(selectedFarmGeoJson);
    }
  }, [refMap]);

  useEffect(() => {
    if (dashboardState !== 'dashboard@renderedMaps' && dashboardState !== 'dashboard@results') {
      setRenderedMaps([]);
    }

    if (dashboardState !== 'dashboard@results') {
      removeGeometriesColoredSubdivisions();
      setShowResult(false);
    }
    // setShowChat(false);
  }, [dashboardState]);

  return (
    <FullLoadingProvider>
      <DashboardContext.Provider value={contextValues}>
        <Container>
          {appConfig.enviroment === 'DEV' && <FlagEnviroment />}
          <Header fullname={user.fullname} />
          <Content>
            <NavBar />
            <SideNav isOpened={hideNav} dataManagement={dashboardState === 'dashboard@dataManagement' || dashboardState === 'dashboard@pestMonitoring' || dashboardState === 'dashboard@knowledgeBase'}>
              <NavContentContainer>
                {hideNav && dashboardStates.map((value) => {
                  return dashboardState === value.path &&
                    value.component !== undefined ? (
                    <div key={value.id} style={{ flex: 1 }}>{value.component}</div>
                  ) : null;
                })}
              </NavContentContainer>
              {dashboardState !== 'dashboard@dataManagement' && dashboardState !== 'dashboard@pestMonitoring' && dashboardState !== 'dashboard@knowledgeBase' ? (
                <ButtonContainer isOpened={hideNav}>
                  <button type="button" onClick={handleActionSideNavigation}>
                    <HiOutlineArrowNarrowRight size={28} />
                  </button>
                </ButtonContainer>
              ) : null}
            </SideNav>
            {
              dashboardState !== 'dashboard@renderedMaps' &&
                dashboardState !== 'dashboard@dataManagement' &&
                dashboardState !== 'dashboard@pestMonitoring' &&
                dashboardState !== 'dashboard@knowledgeBase'
                ? (
                  <MainContainer>
                    <WebMap />
                    {controlState === undefined && null}
                    {controlState === 'cloud-filter' && <FilterCloudCard />}
                    {controlState === 'upload-shapefile' && <UploadShapefileCard />}
                    {controlState === 'field-filter' && <FilterCard />}
                    {controlState === 'satellite' && <SelectSatelliteCard />}

                    {hideCarouselOptions && !showResult && dashboardState !== 'dashboard@report' && dashboardState !== 'dashboard@createdReports' && <DynamicLegend />}
                    <TimeLine
                      style={{ display: hideCarouselOptions === true ? 'none' : '' }}
                    />
                  </MainContainer>
                ) : (
                  <>
                    {renderedMaps.length === 1 && (
                      <MainContainer numberOfMaps={1}>
                        <WebMap1 numberOfMaps={1} />
                      </MainContainer>
                    )}
                    {renderedMaps.length === 2 && (
                      <MainContainer numberOfMaps={2}>
                        <WebMap1 numberOfMaps={2} />
                        <WebMap2 numberOfMaps={2} />
                      </MainContainer>
                    )}
                    {renderedMaps.length === 3 && (
                      <MainContainer numberOfMaps={3}>
                        <WebMap1 numberOfMaps={3} />
                        <WebMap2 numberOfMaps={3} />
                        <WebMap3 numberOfMaps={3} />
                      </MainContainer>
                    )}
                    {renderedMaps.length === 4 && (
                      <MainContainer numberOfMaps={4}>
                        <WebMap1 numberOfMaps={4} />
                        <WebMap2 numberOfMaps={4} />
                        <WebMap3 numberOfMaps={4} />
                        <WebMap4 numberOfMaps={4} />
                      </MainContainer>
                    )}
                  </>
                )
            }

            {dashboardState !== 'dashboard@results' && dashboardState !== 'dashboard@resultsMap' && showButtonCarousel && (
              <>
                {hideCarouselOptions === false ? (
                  <>
                    <button
                      type="button"
                      onClick={handleCarousel}
                      style={{
                        padding: 10,
                        top: 400,
                        right: 130,
                        position: 'absolute',
                        background: '#FFF',
                        border: 0,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8
                      }}
                    >
                      <HiOutlineArrowNarrowRight />
                    </button>
                    <Carousel hide={!hideCarouselOptions} />
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={handleCarousel}
                      style={{
                        padding: 10,
                        top: 400,
                        right: 0,
                        position: 'absolute',
                        background: '#FFF',
                        border: 0,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8
                      }}
                    >
                      <HiOutlineArrowNarrowLeft />
                    </button>
                  </>
                )}
              </>
            )}
          </Content>
        </Container>
      </DashboardContext.Provider>
    </FullLoadingProvider>

  );
};

export default Dashboard;
