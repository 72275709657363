/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Dialog, Paper, Slide, Typography } from '@mui/material';

import { delay } from '../../util';
import { Lottie, animations } from '../Lottie';
import { Icon } from '../Icons';
import Modal, { ModalHandles } from '../Modal';
import {
  ButtonContainer,
  ConfirmContainer,
  MessageContainer,
  AnimationContainer
} from './style';

interface IConfirm {}

export interface ConfirmBaseProps {
  message: string;
  animation?: keyof typeof animations;
  labelConfirm?: string;
  labelCancel?: string;
  direction?: 'up' | 'down' | 'left' | 'right';
  initial?: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export interface IConfirmHandles {
  open: (base: ConfirmBaseProps) => void;
  close: () => void;
}

let Transition: any = null;

const Confirm: React.ForwardRefRenderFunction<IConfirmHandles, IConfirm> = (
  {}: IConfirm,
  ref
) => {
  const [opts, setOpts] = useState<ConfirmBaseProps | null>(null);
  const [confirmState, setConfirmState] = useState<boolean>(false);
  const modalRef = useRef<ModalHandles>(
    null
  ) as React.MutableRefObject<ModalHandles>;

  useImperativeHandle(ref, () => {
    return {
      open: (base: ConfirmBaseProps) => {
        setOpts(base);
        modalRef.current?.openModal();
      },
      close: () => {
        setOpts(null);
        modalRef.current?.closeModal();
      }
    };
  });

  const handlePressConfirm = () => {
    setConfirmState(true);
    opts
      ?.onConfirm?.()
      .then(() => {
        delay(500).then(() => {
          setConfirmState(false);
          modalRef.current?.closeModal();
        });
      })
      .catch(() => {
        modalRef.current?.closeModal();
      });
  };

  const handlePressCancel = () => {
    opts?.onCancel?.();
    modalRef.current?.closeModal();
  };

  useEffect(() => {
    function Transitions(
      props: TransitionProps & { children: React.ReactElement<any, any> },
      ref: React.Ref<unknown>
    ) {
      return (
        <Slide direction={opts?.direction ?? 'down'} ref={ref} {...props} />
      );
    }
    Transition = React.forwardRef(Transitions);
  }, [opts?.direction]);

  const handleClose = () => {
    console.log('close');
  };

  return (
    <Modal ref={modalRef}>
      <Dialog
        open={opts?.initial ?? true}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <ConfirmContainer>
          <Box
            component={Paper}
            elevation={24}
            variant="elevation"
            sx={{
              display: 'flex',
              width: '360px',
              height: '400px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              p: 1
            }}
          >
            <AnimationContainer>
              <Lottie animation={opts?.animation ?? 'exclamation'} />
            </AnimationContainer>
            <MessageContainer>
              <Typography>{opts?.message ?? 'Confimar operação'}</Typography>
            </MessageContainer>
            <ButtonContainer>
              <LoadingButton
                fullWidth
                style={{ width: '95%', marginTop: '10px' }}
                loadingPosition="start"
                endIcon={<Icon iconName="Delete" />}
                loading={confirmState}
                color="error"
                variant="contained"
                onClick={handlePressConfirm}
              >
                {opts?.labelConfirm ?? 'Confirmar'}
              </LoadingButton>
              <LoadingButton
                fullWidth
                style={{ width: '95%', marginTop: '10px' }}
                loadingPosition="start"
                endIcon={<Icon iconName="Close" />}
                variant="contained"
                onClick={handlePressCancel}
              >
                {opts?.labelCancel ?? 'Cancelar'}
              </LoadingButton>
            </ButtonContainer>
          </Box>
        </ConfirmContainer>
      </Dialog>
    </Modal>
  );
};
export default React.forwardRef(Confirm);
