/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import logo from '../../assets/MARCA_FUNDACAO_MT_versao_vertical_colorida.png';
import Button from '../../components/Button';
import FlagEnviroment from '../../components/FlagEnviroment';
import Input from '../../components/Input';
import Toast from '../../components/Toast';
import { appConfig } from '../../config';
import { useAuth } from '../../hooks/Auth';
import { useMap } from '../../hooks/Map';
import {
  Background,
  CheckboxContainer,
  Container,
  Content,
  ErrorContainer,
  Form,
  PasswordOptions,
  SignUp,
  LogoContainer
} from './styled';
import { DateUtils } from '../../util/Date.Utils';

const SignIn = () => {
  const { setDisableQueryNotification } = useMap();
  const navigate = useNavigate();
  const signInValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail é inválido')
      .required('E-mail é obrigatório'),
    password: Yup.string()
      .required('Senha é obrigatória')
      .min(6, 'Senha deve conter no minimo 6 caracteres')
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const farmId = queryParams.get('farmId');
    if (farmId) {
      localStorage.setItem('farmId', farmId);
    } else {
      localStorage.removeItem('farmId');
    }
  }, []);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(signInValidationSchema)
  });
  const { signIn } = useAuth();
  const onSubmit = async (data) => {
    try {
      await signIn({
        email: data.email,
        password: data.password
      });
      navigate('/dashboard');
    } catch (error) {
      toast(
        <Toast
          title="E-mail ou senha incorretos"
          content="Verifique as credenciais e tente novamente."
        />,
        {
          type: 'error'
        }
      );
    }
  };

  useEffect(() => {
    setDisableQueryNotification(false);
  }, []);

  return (
    <Container>
      {appConfig.enviroment === 'DEV' && <FlagEnviroment />}
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <LogoContainer>
            <img src={logo} alt="Fundação MT" />
          </LogoContainer>
          <span>Minha conta</span>
          <p>Faça login para acessar a plataforma.</p>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            ref={register}
            error={!!errors.email?.message}
          />
          <ErrorContainer>{errors.email?.message}</ErrorContainer>
          <Input
            type="password"
            name="password"
            placeholder="Senha"
            ref={register}
            error={!!errors.password?.message}
          />
          <ErrorContainer>{errors.password?.message}</ErrorContainer>
          <PasswordOptions>
            <CheckboxContainer>
              <label htmlFor="remember-password">
                Lembrar senha
                <input
                  type="checkbox"
                  name="remember-password"
                  id="remember-password"
                />
                <span />
              </label>
            </CheckboxContainer>
            <Link to="/forgot-password">Esqueceu sua senha?</Link>
          </PasswordOptions>
          <Button text="Entrar" />
          {/* <SignUp>
            <p>Ainda não é cadastrado? </p>
            <Link to="/signup">Criar conta</Link>
          </SignUp> */}
        </Form>
      </Content>
      <Background>
        <p>
          © {DateUtils.getYearFull()} Fundação MT - Todos os direitos estão
          reservados.
        </p>
      </Background>
    </Container>
  );
};

export default SignIn;
