import axios from 'axios';
import Cookies from 'js-cookie';

const token = Cookies.get('auth_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_MS_FARM
});

api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default api;
