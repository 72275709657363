import React, { useImperativeHandle, useState } from 'react';
import { Modal as ModalCustom } from '@mui/material';

interface ModalProps {
  children: React.ReactElement;
  hidebackdrop?: boolean;
}

export interface ModalHandles {
  openModal: () => void;
  closeModal: () => void;
}

const Modal: React.ForwardRefRenderFunction<ModalHandles, ModalProps> = (
  { children, hidebackdrop },
  ref
) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    console.log('openModal');
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      openModal,
      closeModal
    };
  });

  return (
    <ModalCustom
      open={isOpen}
      onClose={closeModal}
      style={{ backdropFilter: 'blur(0px)' }}
      hideBackdrop={hidebackdrop}
    >
      {children}
    </ModalCustom>
  );
};

export default React.forwardRef(Modal);
