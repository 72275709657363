import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import Button from '../../../../../../components/Button';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import { appConfig } from '../../../../../../config';
import { parseCoordinatesMultiPolygonTo3857 } from '../../../../../../helpers/parseCoordinates';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

const RegisterSubdivision = () => {
  const [pointDepth, setPointDepth] = useState('');
  const [oneClicked, setOneClicked] = useState(false);
  const { setDashboardState, dashboardState, setSubdivisions, subdivisions } =
    useContext(DashboardContext);
  const {
    drawendSubdivision,
    setDrawendSubdivision,
    selectedFarmId,
    createPointOfSubdivision,
    drawendSubdivisionAndPoint,
    pointOfSubdivision,
    subdivision,
    setSubdivision,
    setDrawendSubdivisionAndPoint,
    removeAllInterections,
    removeSubdivisionGeometries,
    setPointOfSubdivision,
    selectedFields,
    createSubdivision,
    geomList,
    setSelectedFields,
    setDrawing,
    drawing,
    renderSubdivisions,
    setListSubdivisions,
    removePointGeometries,
    removeAllDrawingInteractions
  } = useMap();
  const { user, token } = useAuth();
  const [fieldListArr, setFieldListArr] = useState([]);
  const [isLoadingSubdivisions, setIsLoadingSubdivisions] = useState(false);
  const [collectionPoints, setCollectionPoints] = useState([]);

  useEffect(() => {
    if (subdivisions === undefined) {
      setIsLoadingSubdivisions(true);
    } else {
      setIsLoadingSubdivisions(false);
    }
  }, [subdivisions]);

  useEffect(() => {
    setDrawing(false);
    setDrawendSubdivision(true);
    return geomList;
  }, [geomList]);

  const getSubdivisions = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      pointDepth &&
      pointOfSubdivision &&
      pointOfSubdivision.features &&
      !collectionPoints.some(
        (point) =>
          point.point.features[0].geometry.coordinates[0] ===
            pointOfSubdivision.features[0].geometry.coordinates[0] &&
          point.point.features[0].geometry.coordinates[1] ===
            pointOfSubdivision.features[0].geometry.coordinates[1]
      )
    ) {
      const newPoint = JSON.parse(JSON.stringify(pointOfSubdivision));

      setCollectionPoints([
        ...collectionPoints,
        {
          point: newPoint,
          depth: pointDepth
        }
      ]);

      createPointOfSubdivision();
    }
  }, [pointOfSubdivision]);

  const registerSubdivision = async () => {
    const result1 = selectedFields.filter((data) => typeof data === 'object');
    result1.map((item, id) => {
      return fieldListArr.push(item.getProperties().field_id);
    });
    subdivision.properties = {
      farm_id: selectedFarmId,
      fieldList: fieldListArr,
      status_id: 'f85e20f6-3ab4-481e-9a0e-e53c581f2bdf',
      created_by: user.user_id
    };

    if (subdivision.geometry.type === 'Polygon') {
      subdivision.geometry.type = 'MultiPolygon';
      subdivision.geometry.coordinates[0] = [
        ...subdivision.geometry.coordinates
      ];
    }

    const parseSubdivision = {
      type: 'FeatureCollection',
      features: [subdivision]
    };

    const newSubdivision = await parseCoordinatesMultiPolygonTo3857(
      parseSubdivision
    );
    try {
      const response = await api.post(
        `${appConfig.apiHosts.field}/Subdivision/PostSubdivision`,
        newSubdivision,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      collectionPoints.forEach(async (point, index) => {
        point.point.features[0].properties = {
          farm_id: selectedFarmId,
          subdivision_id:
            response.data.data.features[0].properties.subdivision_id,
          depth: point.depth,
          type: 'PROFUNDIDADE',
          description: '',
          name: `Ponto ${index + 1}`,
          automatically_generated: false,
          created_by: user.user_id
        };

        await api.post(
          `${appConfig.apiHosts.field}/DataCollectionPoint/PostDataCollectionPoint`,
          point.point,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      });

      toast(<Toast title="Subdivisão cadastrada com sucesso" />, {
        type: 'success'
      });
      setDashboardState('dashboard@harvestmap');
    } catch (error) {
      setDrawendSubdivisionAndPoint(false);
      removePointGeometries();
      setPointDepth('');
      getSubdivisions();
      toast(<Toast title="Não foi possivel salvar a subdivisão" />, {
        type: 'error'
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.SimpleButtonContainer>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              createSubdivision('clear');
              setSelectedFields([]);
              setPointOfSubdivision({});
              setDashboardState('dashboard@harvestmap');
              removeSubdivisionGeometries();
              removeAllInterections();
              setSubdivisions([]);
              setDrawing(false);
              removeAllDrawingInteractions();
            }}
          />
        </S.SimpleButtonContainer>
        <S.Title>Subdivisão</S.Title>
        <S.SubTitle>
          Para habilitar o desenho da sua subdivisão, selecione pelo menos um
          talhão no mapa.
          <br />
          <br />
        </S.SubTitle>

        <S.Container>
          {/* Feedback de carregamento de subdivisão */}
          {selectedFields <= 0 ? null : !drawing ? (
            <Button
              text={
                isLoadingSubdivisions
                  ? 'Carregando subdivisões...'
                  : 'Iniciar Desenho'
              }
              onClick={() => {
                if (selectedFields.length > 0) {
                  createSubdivision('draw');
                  setOneClicked(true);
                }
              }}
              disabled={oneClicked || isLoadingSubdivisions}
            />
          ) : (
            <Button text="Desenho Já Iniciado" disabled />
          )}
        </S.Container>

        <S.SelectContainer>
          <S.SubTitle>Selecione a camada do seu ponto de coleta:</S.SubTitle>
          <FormControl style={{ width: '100%', marginTop: '15px' }}>
            <InputLabel>Selecione a camada</InputLabel>
            <Select
              label="Selecione a camada"
              placeholder="Cultura"
              name="pointDepth"
              value={pointDepth}
              onChange={(e) => {
                setPointDepth(e.target.value);
                setDrawendSubdivisionAndPoint(false);
                createPointOfSubdivision();
              }}
              disabled={drawendSubdivision}
            >
              <MenuItem value="0-10" key="0-10">
                0-10
              </MenuItem>
              <MenuItem value="0-20" key="0-20">
                0-20
              </MenuItem>
              <MenuItem value="0-20-40" key="0-20-40">
                0-20-40
              </MenuItem>
              <MenuItem value="0-10-20" key="0-10-20">
                0-10-20
              </MenuItem>
              <MenuItem value="0-10-20-40" key="0-10-20-40">
                0-10-20-40
              </MenuItem>
              <MenuItem value="0-10-20-30-40" key="0-10-20-30-40">
                0-10-20-30-40
              </MenuItem>
              <MenuItem value="0-10-20-30-40-60" key="0-10-20-30-40-60">
                0-10-20-30-40-60
              </MenuItem>
            </Select>
          </FormControl>
        </S.SelectContainer>
      </S.Content>
      {collectionPoints.length > 0 && (
        <S.CollectionPointsWrapper>
          <S.CollectionPointsContainer>
            <S.Title>Pontos de Coleta ({collectionPoints.length})</S.Title>
            <S.PointsList>
              {collectionPoints.map((point, index) => (
                <S.PointItem key={index}>
                  <S.PointInfo>
                    <strong>Ponto {index + 1}</strong> | Camada: {point.depth}
                  </S.PointInfo>
                </S.PointItem>
              ))}
            </S.PointsList>
          </S.CollectionPointsContainer>
        </S.CollectionPointsWrapper>
      )}

      <S.ButtonsContainer>
        {/* Feedback de uso das subdivisions */}
        <Button
          text="Salvar Subdivisão"
          disabled={drawendSubdivision}
          onClick={() => {
            registerSubdivision();
            setDrawendSubdivisionAndPoint(true);
            removeAllInterections();
            createSubdivision('clear');
            setSelectedFields([]);
            setSubdivisions([]);
            setPointOfSubdivision({});
          }}
        />
        <Button
          text="Cancelar"
          style={{
            background: '#FFF',
            color: '#79AC34',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 5%)'
          }}
          onClick={() => {
            createSubdivision('clear');
            setSelectedFields([]);
            removeSubdivisionGeometries();
            removeAllInterections();
            setPointOfSubdivision({});
            setSubdivisions([]);
            setDrawing(false);
            removeAllDrawingInteractions();
            setDashboardState('dashboard@harvestmap');
          }}
        />
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default RegisterSubdivision;
