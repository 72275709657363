import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CollectionPointsWrapper = styled.div`
  height: 200px;
  position: relative;
`;

export const CollectionPointsContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  height: 100%;
`;

export const PointsList = styled.div`
  height: calc(100% - 30px);
  overflow-y: auto;
  padding-right: 5px;
`;

export const PointItem = styled.div`
  padding: 8px;
  margin: 5px 0;
  background: #f5f5f5;
  border-radius: 4px;
`;

export const PointInfo = styled.div`
  color: #333;
  font-size: 14px;
`;

export const Title = styled.h3`
  margin: 16px 0 16px 0;
  font-size: 1rem;
`;

export const TitlePointCollection = styled.h3`
  margin: 0 0 0 0;
  font-size: 1rem;
`;

export const SubTitle = styled.span`
  color: #a3a3a3;
  font-size: 0.8rem;
  text-align: justify;
`;

export const SelectContainer = styled.div`
  margin: 20px 0;
`;

export const ButtonsContainer = styled.div``;
export const SimpleButtonContainer = styled.div``;
