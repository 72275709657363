/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { Profile } from '../../../Profile/ProfileList/utils/profile.types';

import ProfileApi from '../../../../../../../../APi/FarmApi/ProfileApi';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';

export const useUserProfileListFormRequest = () => {
  const {
    token,
    user: { organizacao_id, user_id }
  } = useAuth();
  const profileApi = useMemo(() => new ProfileApi(token), []);
  const useApi = useMemo(() => new UserAPI(token), []);
  const [filterProfile, setFilterProfile] = useState<string>('');
  const [useUserFilter, setUseFilter] = useState<string>('');
  const { id } = useParams();
  const ProfileDate = useQuery({
    queryKey: [profileApi.getProfile, { filterProfile }],
    queryFn: () =>
      profileApi.ProfileList(filterProfile, organizacao_id, user_id)
  });

  function profileMap(date: any): Profile {
    return {
      id: date.userprofile_id,
      name: date.description
    };
  }

  const UserData = useQuery({
    queryKey: [useApi.getUser, { useUserFilter }],
    queryFn: async () => useApi.UserList(useUserFilter, organizacao_id, user_id)
  });

  function userMap(User: any) {
    return {
      id: User.user_id,
      name: User.fullname
    };
  }

  return {
    request: {
      itemsProfiles: ProfileDate.data?.data.data.map(profileMap) ?? [],
      itemsUser: id
        ? UserData.data?.data.data
            ?.filter((x: any) => x.user_id === id)
            .map(userMap) ?? []
        : UserData.data?.data.data.map(userMap)
    },
    filter: {
      setFilterProfile,
      setUseFilter
    }
  };
};
