/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { SubModule } from '../../utils/systemModule,types';
import SubModuleApi from '../../../../../../../../APi/FarmApi/SubModuleApi';

export const useSubModuleListRequest = () => {
  const {
    token,
    user: { organizacao_id }
  } = useAuth();
  const subModuleApi = useMemo(() => new SubModuleApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function systemModuleMap(date: any, index): SubModule {
    return {
      idEndity: date.id,
      id: index,
      name: date.name,
      activated: date.active ? 'Ativado' : 'Desativado',
      moduleId: date.moduleId,
      moduleName: date.moduleName,
      acronym: date.acronym
    };
  }

  const systemModuleQuerry = useQuery({
    queryKey: [subModuleApi.getSubModule, { filter, organizacao_id }],
    queryFn: async () => subModuleApi.GetSubModule(filter, organizacao_id)
  });

  return {
    request: {
      items: systemModuleQuerry.data?.data.data.map(systemModuleMap) ?? []
    },
    setFilter
  };
};
