import React, { useEffect, useState } from 'react';
import { MdCloudUpload } from 'react-icons/md';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  Checkbox
} from '@mui/material';
import api from 'services/api';
import { useMap } from 'hooks/Map';
import { useAuth } from 'hooks/Auth';
import { toast } from 'react-toastify';

import { appConfig } from 'config';
import { formatFileSize } from '../../util';
import Toast from '../Toast';
import { CardButtom } from './styled';
import { EReportType } from '../../pages/Dashboard/components/Results';

import ExcelPNG from '../../assets/excel.png';

export interface Harvest {
  harvest_id: string;
  harvest_name: string;
  start_date: string;
  end_date: string;
  created_by: string;
  created_at: string;
  updated_by: string | null;
  updated_at: string | null;
  deleted_by: string | null;
  deleted_at: string | null;
  active: boolean;
  current_harvest: boolean;
  created_by_name: string;
  updated_by_name: string | null;
  deleted_by_name: string | null;
}

export interface HarvestCreateResponse {
  status: number;
  success: boolean;
  message: string;
  data: Harvest;
  exception: any;
  error: any;
}

interface Pagination {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemsPerPage: number;
  page: number;
  totalPages: number;
  totalItems: number;
  harvests: Harvest[];
}

export interface HarvestListApiResponse {
  status: number;
  success: boolean;
  message: string;
  data: Pagination;
  exception: any | null;
  error: any | null;
}

const enum EFileMimeType {
  'officedocument.spreadsheetml' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel' = 'application/vnd.ms-excel',
  'application/xls' = 'application/xls',
  'google-earth.kmz' = 'application/vnd.google-earth.kmz',
  'google-earth.kml' = 'application/vnd.google-earth.kml+xml',
  'zip' = 'zip',
  'application/zip' = 'application/zip',
  'x-zip-compressed' = 'application/x-zip-compressed',
  'application/pdf' = 'application/pdf'
}

export const xlsMimeTypes = [
  EFileMimeType['officedocument.spreadsheetml'],
  EFileMimeType['application/vnd.ms-excel'],
  EFileMimeType['application/xls']
];

const fileTypeOptions = [
  {
    value: EReportType.none,
    label: 'Selecione',
    accept: undefined,
    errorMessage: undefined,
    errorHint: undefined
  },
  {
    value: EReportType.soilAnalysis,
    label: 'Análise de Solo',
    accept: xlsMimeTypes,
    errorMessage: 'Arquivo de resultado inválido. Formato não suportado.',
    errorHint: 'Formatos aceitos: .xlsx ou .xls'
  },
  {
    value: EReportType.nematodeAnalysis,
    label: 'Análise de Nematóides',
    accept: xlsMimeTypes,
    errorMessage: 'Arquivo de resultado inválido. Formato não suportado.',
    errorHint: 'Formatos aceitos: .xlsx ou .xls'
  }
];

export const ReportUpload = () => {
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState('Selecione');
  const [current, setCurrent] = useState<Harvest | undefined>(undefined);
  const [harvestId, setHarvestId] = useState<string | undefined>(undefined);
  const [currentHarvest, setCurrentHarvest] = useState<boolean>(true);
  const [harvestList, setHarvestList] = useState<Harvest[]>([]);
  const [harvestOptions, setHarvestOptions] = useState<React.ReactNode[]>([]);
  const [selectedFile, setSelectedFile] = useState<{
    file: File | null;
    date: Date;
  }>({ date: new Date(), file: null });

  const { user, token } = useAuth();
  const { selectedFarmId } = useMap();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFileType('Selecione');
    setSelectedFile({
      file: null,
      date: new Date()
    });
  };

  const getHarvestList = async () => {
    try {
      const url = `${appConfig.apiHosts.manager}/harvest/List?page=1&itemsPerPage=25`;
      const response = await api.get<HarvestListApiResponse>(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const harvestCurrent = response.data.data.harvests.find(
        (item) => item.current_harvest
      );
      if (harvestCurrent) {
        setCurrent(harvestCurrent);
        setHarvestId(harvestCurrent.harvest_id);
      }
      setHarvestList(response.data.data.harvests);
      const noneOption = (
        <MenuItem key={undefined} value={undefined}>
          Selecione
        </MenuItem>
      );
      const options = response.data.data.harvests.map((option) => (
        <MenuItem key={option.harvest_id} value={option.harvest_id}>
          {option.harvest_name}
        </MenuItem>
      ));
      setHarvestOptions([noneOption, ...options]);
    } catch (error) {
      console.error('Error on getHarvestList', error);
      return setHarvestList([]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qtdArray = event?.target?.files?.length;
    const reportChosen = fileTypeOptions.find(
      (option) => option.value === fileType
    );

    if (
      !reportChosen.accept
        .join(', ')
        .includes(event?.target?.files[qtdArray - 1].type)
    ) {
      toast(
        <Toast
          title={reportChosen.errorMessage}
          content={reportChosen.errorHint}
        />,
        {
          type: 'error',
          autoClose: 5000
        }
      );
      return;
    }

    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile({
        file: event.target.files[0],
        date: new Date()
      });
    } else {
      setSelectedFile({
        file: null,
        date: new Date()
      });
    }
  };
  const sendNematodeReport = async (file: File) => {
    const formData = new FormData();

    formData.append('file', file);
    let response = null;
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id],
        ['harvestId', harvestId]
      ]);
      response = await api.post(
        `${appConfig.apiHosts.report}/ReportNematode/CreateNematodeReport`,
        formData,
        {
          params,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status !== 200) {
        toast(
          <Toast
            title="Relátorio não foi importado"
            content={response.data.message}
          />,
          {
            type: 'error',
            autoClose: 15000
          }
        );
        return;
      }

      toast(<Toast title="Relátorio importado com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Relátorio não foi importado"
          content={error.response.data.message}
        />,
        {
          type: 'error',
          autoClose: 15000
        }
      );
    }
  };

  const handleUploadFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      sendNematodeReport(file);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleUpload = () => {
    if (!selectedFile.file) {
      toast(<Toast title="Selecione um arquivo  para enviar." />, {
        type: 'error',
        autoClose: 5000
      });
      return;
    }
    if (selectedFile && fileType) {
      handleUploadFile(selectedFile.file);
      handleClose();
    }
  };

  const formatDateTime = (date: Date) => {
    return `${date.toLocaleDateString('pt-BR')} às ${date.toLocaleTimeString(
      'pt-BR'
    )}`;
  };

  useEffect(() => {
    getHarvestList();
  }, []);

  return (
    <div>
      <CardButtom onClick={handleOpen}>
        <label htmlFor="upload-file-farm" className="label-report-upload">
          <MdCloudUpload size={22} color="#9FBC38" />
          <div>
            <span>Fazer o upload de relatório</span>
            <br />
            <span>Formatos aceitos: .xlsx ou .xls</span>
          </div>
        </label>
      </CardButtom>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          style={{ flexDirection: 'column' }}
        >
          <DialogTitle>Arquivo de resultado</DialogTitle>
          <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
            <DialogTitle
              style={{
                margin: '5px 0',
                padding: 0,
                fontSize: 14
              }}
            >
              Tipo do arquivo
            </DialogTitle>
            <Select
              fullWidth
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              displayEmpty
            >
              {fileTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            {/* File Input */}
            {fileType && (
              <>
                {currentHarvest && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      gap: 10,
                      alignItems: 'center'
                    }}
                  >
                    <Checkbox
                      checked={harvestId === current?.harvest_id}
                      onChange={(e) => {
                        setCurrentHarvest(e.target.checked);
                        setHarvestId(
                          e.target.checked ? current?.harvest_id : undefined
                        );
                      }}
                    />
                    <Typography variant="body2">
                      {currentHarvest
                        ? `Safra atual ${current?.harvest_name}`
                        : 'Safra anterior'}
                    </Typography>
                  </div>
                )}

                {!currentHarvest && (
                  <>
                    <DialogTitle
                      style={{
                        margin: '5px 0',
                        padding: 0,
                        fontSize: 14
                      }}
                    >
                      Safra de referência
                    </DialogTitle>
                    <Select
                      value={harvestId}
                      onChange={(e) => {
                        setHarvestId(e.target.value as string);
                        setCurrentHarvest(
                          current?.harvest_id === e.target.value
                        );
                      }}
                      displayEmpty
                      sx={{ width: 200 }}
                    >
                      {harvestOptions}
                    </Select>
                  </>
                )}

                {!selectedFile.file && (
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ mt: 2, width: 200 }}
                  >
                    Escolher arquivo
                    <input
                      type="file"
                      hidden
                      accept={
                        fileTypeOptions
                          .find((option) => option.value === fileType)
                          ?.accept?.join(', ') || '*'
                      }
                      onChange={handleFileChange}
                    />
                  </Button>
                )}

                {selectedFile.file && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      backgroundColor: '#cbcbcb',
                      padding: 4,
                      borderRadius: 4,
                      marginTop: 10
                    }}
                  >
                    <div>
                      {selectedFile && (
                        <>
                          {selectedFile?.file && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: 5
                                }}
                              >
                                Arquivo:
                              </span>
                              {selectedFile?.file?.name}
                            </Typography>
                          )}
                          {selectedFile?.file?.size && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: 5
                                }}
                              >
                                Tamanho:
                              </span>
                              {formatFileSize(selectedFile?.file?.size)}
                            </Typography>
                          )}
                          {selectedFile?.file?.type && (
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 1,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: 5
                                }}
                              >
                                Formato:
                              </span>
                              <img src={ExcelPNG} alt="Excel" width={20} />
                            </Typography>
                          )}
                          {selectedFile?.file?.lastModified && (
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 1,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: 5
                                }}
                              >
                                Modificado em:
                              </span>
                              {formatDateTime(
                                new Date(selectedFile.file.lastModified)
                              )}
                            </Typography>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '25%'
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setSelectedFile({
                            file: null,
                            date: new Date()
                          });
                        }}
                        sx={{ mt: 2 }}
                      >
                        Remover
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </DialogContent>

          {/* Actions */}
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              color="success"
              disabled={!fileType || !selectedFile}
            >
              Enviar arquivo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
