import styled, { StyledComponent } from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;

  h1 {
    color: #444444;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  h3 {
    color: #444444;
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  hr {
    border: solid 1px #dcdcdc;
    margin-bottom: 16px;
  }

  p {
    text-align: left;
    font-size: 12px;
    color: #8d8d8d;
    opacity: 1;
    margin-bottom: 10px;
  }

  .buttonAddMap {
    background-color: #ffffff;
    border: 1px solid #79ac34;
    color: #79ac34;

    &:disabled {
      border: 1px solid #dcdcdc;
      color: #dcdcdc;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchContainer = styled.div`
  margin-bottom: 16px;

  .selectInput {
    overflow: auto;
  }
`;

export const TitleBox = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  width: 100%;
  height: 55px;
  background-color: #ffffff;
  color: #79ac34;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  border: 1px solid #79ac34;
  border-radius: 8px;
  opacity: 1;
`;

export const DivTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 16px;
`;

export const DepthContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: 8px;
  width: 100%;
  margin-bottom: 5px;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 15px 10px;
  margin: 16px 0;
`;

interface ColorAreaProps {
  color: string;
  selected: string;
}

export const ColorArea = styled.div<ColorAreaProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: ${(props) =>
    props.selected === props.color ? props.color : '3px solid #FFF'};
`;

export const ContentSelectColor = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .cardContainer {
    height: 90%;
    overflow: auto;
    scrollbar-width: thin;
  }

  .positionButton {
    height: 100%;
  }
`;

export const ZoneCardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ZoneCardText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    font-size: 0.9rem;
  }

  > span {
    font-size: 0.7rem;
  }
`;

export const ViewAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ZoneCardActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;

  .actionBtn {
    display: flex;
    background: transparent;
    border: none;

    & + button {
      //margin-left: 10px;
    }
    > span {
      color: #79ac34;
      font-size: 0.8rem;
    }
    > svg {
      width: 16px;
      height: 16px;
      color: #79ac34;
    }
  }
`;
interface ActionButtonCardProps {
  status: boolean;
}

export const ActionButtonCard = styled.button<ActionButtonCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.status ? '#79ac34' : 'transparent')};
  border: 1px solid #79ac34;
  color: ${(props) => (props.status ? '#FFF' : '#79ac34')};
  padding: 5px;
  width: 140px;
  font-size: 14px;
  border-radius: 5px;
  //margin: 10px 0;
  margin-bottom: 21px;
`;
