/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as Yup from 'yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  Toolbar,
  Button,
  TextField
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import * as s from './utils/profileForm.style';
import useProfileForm from './hooks/useProfileForm';
import { useModal } from '../../../../../../../hooks/Modal';
import { ProfileFormProps } from '../ProfileList/utils/profile.types';
import Toast from '../../../../../../../components/Toast';
import { useAuth } from '../../../../../../../hooks/Auth';
import { EnhancedTableToolbar } from '../../../../../../../components/OrderedTable/components/EnhancedTableToolbar';
import { useOrganizacaoListRequest } from '../../Organizacao/OrganizacaoList/hooks/useOrganizacaoListRequest';
import { useSubModuleListRequest } from '../../SubModule/SubModuleList/hooks/useSubModuleListRequest';
import useModuleProfileForm from '../../ModuleProfile/ModuleProfileForm/hooks/useModuleProfileForm';
import { useModuleProfileFormRequest } from '../../ModuleProfile/ModuleProfileForm/hooks/useModuleProfileFormRequest';
import api from '../../../../../../../APi/FarmApi/configFarmApi';

const LoadingOverlay = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}
  >
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px 40px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      <div
        style={{
          width: '40px',
          height: '40px',
          border: '4px solid #f3f3f3',
          borderTop: '4px solid #A0BD39',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}
      />
      <Typography variant="h6" style={{ color: '#333' }}>
        Salvando perfil...
      </Typography>
    </div>
  </div>
);

const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleSheet);

const ProfileForm: FC<ProfileFormProps> = (props) => {
  const { modalCreatUserStyles } = useModal();
  const { role } = useAuth();
  const navigate = useNavigate();
  const [checkedModules, setCheckedModules] = useState<{
    [key: string]: boolean;
  }>({});
  const moduleProfileRequest = useModuleProfileFormRequest();
  const { request: organizacaoRequest } = useOrganizacaoListRequest();
  const { request: subModuleRequest } = useSubModuleListRequest();
  const {
    form,
    onSelectActivated,
    title,
    mensageSuccess,
    mensage,
    request,
    verific: { verificarOrganizacao },
    profileDate: { nomeOrganizacao: oldNomeOrganizacao }
  } = useProfileForm(props);
  const { state } = useLocation();
  const { id, organizationId } = state || {};
  const name = state?.name?.replace(/^\[.*\] - /, '');

  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const isUsuario: boolean = sistema.includes('Usuario');
  const { type } = props;
  const [profileResponse, setProfileResponse] = useState<any>(null);
  const showSelect: boolean = type === 'new' && (isMaster || isAdmin);
  const isOwner = form.methods.watch('isFarmOwner');
  const queryClient = useQueryClient();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFarmOwner, setIsFarmOwner] = useState(false);
  const [checkedParentModules, setCheckedParentModules] = useState<{
    [key: string]: boolean;
  }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const FormValidationSchema = Yup.object().shape({
    description: Yup.string()
      .trim()
      .required('Nome do perfil é obrigatório')
      .min(3, 'Nome do perfil deve ter pelo menos 3 caracteres'),
    organizacao_id: Yup.string().required('Organização é obrigatória')
  });
  const groupedSubModules = useMemo(() => {
    const modules = [
      {
        id: '938fde13-c8f3-4498-aa92-880fb4f3d8c7',
        name: 'Home',
        acronym: 'Home'
      },
      ...(moduleProfileRequest.listModule || [])
    ];
    const result = {};
    modules.forEach((module) => {
      result[module.id] = {
        moduleName: module.name,
        moduleAcronym: module.acronym,
        subModules: []
      };
    });

    subModuleRequest.items?.forEach((subModule) => {
      const parentModule = modules.find(
        (m) => subModule.moduleName === m.name || subModule.moduleId === m.id
      );

      if (parentModule) {
        result[parentModule.id].subModules.push(subModule);
      }
    });

    return result;
  }, [moduleProfileRequest.listModule, subModuleRequest.items]);

  const handleModuleChange = (moduleId: string, checked: boolean) => {
    setCheckedParentModules({
      ...checkedParentModules,
      [moduleId]: checked
    });
    if (!checked) {
      const newCheckedModules = { ...checkedModules };
      groupedSubModules[moduleId].subModules.forEach((subModule) => {
        newCheckedModules[subModule.idEndity] = false;
      });
      setCheckedModules(newCheckedModules);
    }
  };

  const handleSubModuleChange = (
    moduleId: string,
    subModuleId: string,
    checked: boolean
  ) => {
    setCheckedModules({
      ...checkedModules,
      [subModuleId]: checked
    });

    if (checked) {
      setCheckedParentModules({
        ...checkedParentModules,
        [moduleId]: true
      });
    }
  };

  const initializeModuleChecks = (userRole) => {
    if (!userRole || !groupedSubModules) return;

    const [_, modulesPart, subModulesPart] = userRole.split('|');
    const activeModules = modulesPart.split('&').filter(Boolean);
    const activeSubModules = subModulesPart.split('&').filter(Boolean);

    const initialModuleStates = {};
    Object.entries(groupedSubModules).forEach(([moduleId, data]) => {
      initialModuleStates[moduleId] = activeModules.includes(
        data.moduleAcronym
      );
    });

    const initialSubModuleStates = {};
    Object.values(groupedSubModules).forEach((moduleData) => {
      moduleData.subModules.forEach((subModule) => {
        initialSubModuleStates[subModule.idEndity] = activeSubModules.includes(
          subModule.acronym
        );
      });
    });

    setCheckedParentModules(initialModuleStates);
    setCheckedModules(initialSubModuleStates);
  };
  useEffect(() => {
    if (groupedSubModules && profileResponse && !isInitialized) {
      initializeModuleChecks(profileResponse.data.data.role);
      setIsInitialized(true);
    }
  }, [groupedSubModules, profileResponse, isInitialized]);

  useEffect(() => {
    if (type === 'edit' && id && groupedSubModules && !isInitialized) {
      const loadProfile = async () => {
        try {
          const response = await api.get(
            `/Farm/RetornarPerfil?id=${id}&organizacao_id=${organizationId}`
          );

          const cleanedName = name.replace(/^\[.*\] - /, '');
          const userRole = response.data.data.role;

          form.methods.setValue('isFarmOwner', response.data.data.isFarmOwner);
          setIsFarmOwner(response.data.data.isFarmOwner);
          form.methods.setValue('description', cleanedName);
          form.methods.setValue('organizacao_id', organizationId);

          setProfileResponse(response);

          initializeModuleChecks(userRole);
          setIsInitialized(true);
        } catch (error) {
          toast.error('Erro ao carregar dados do perfil');
        }
      };

      loadProfile();
    }
  }, [id, type, organizationId, groupedSubModules, isInitialized]);

  useEffect(() => {
    if (form.statusCode.codeStatus === 200) {
      toast(
        <Toast
          title={mensageSuccess[type].title}
          content={mensageSuccess[type].content}
        />,
        {
          type: 'success'
        }
      );
    }
    if (form.statusCode.codeStatus === 409) {
      toast(
        <Toast
          title={form.statusCode.message}
          content={form.statusCode.message}
        />,
        {
          type: 'warning'
        }
      );
    }
  }, [form.statusCode]);

  const handleMarkAll = () => {
    const allParentModules = {};
    const allSubModules = {};

    Object.entries(groupedSubModules || {}).forEach(([moduleId, data]) => {
      allParentModules[moduleId] = true;
      data.subModules.forEach((subModule) => {
        allSubModules[subModule.idEndity] = true;
      });
    });

    setCheckedParentModules(allParentModules);
    setCheckedModules(allSubModules);
  };

  const handleUnmarkAll = () => {
    const allParentModules = {};
    const allSubModules = {};

    Object.entries(groupedSubModules || {}).forEach(([moduleId, data]) => {
      allParentModules[moduleId] = false;
      data.subModules.forEach((subModule) => {
        allSubModules[subModule.idEndity] = false;
      });
    });

    setCheckedParentModules(allParentModules);
    setCheckedModules(allSubModules);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (type === 'edit') {
        await api.put('/Farm/Perfil', {
          userprofile_id: id,
          description: form.methods.getValues('description'),
          active: true,
          organizacao_id: isFarmOwner
            ? null
            : form.methods.getValues('organizacao_id'),
          isFarmOwner,
          nomeOrganizacao: isFarmOwner
            ? form.methods.getValues('nomeOrganizacao')
            : ''
        });

        const selectedModules = Object.entries(checkedParentModules)
          .filter(([_, isChecked]) => isChecked)
          .map(([moduleId]) => groupedSubModules[moduleId].moduleAcronym);

        const selectedSubModules = Object.entries(checkedModules)
          .filter(([_, isChecked]) => isChecked)
          .map(([subModuleId]) => {
            const subModule = Object.values(groupedSubModules)
              .flatMap((m) => m.subModules)
              .find((sub) => sub.idEndity === subModuleId);
            return subModule.acronym;
          });

        await api.put('/Farm/AtualizarModulosESubModulosDoPerfil', {
          profileId: id,
          moduleAcronyms: selectedModules,
          subModuleAcronyms: selectedSubModules
        });

        queryClient.invalidateQueries('ListarPerfis');
        toast.success('Perfil atualizado com sucesso!');
        navigate(-1);
        return;
      }
      const profileData = {
        description: form.methods.getValues('description'),
        active: true,
        organizacao_id: isFarmOwner
          ? null
          : form.methods.getValues('organizacao_id'),
        isFarmOwner,
        nomeOrganizacao: isFarmOwner
          ? form.methods.getValues('nomeOrganizacao')
          : ''
      };

      const response = await api.post('/Farm/Perfil', profileData);
      const profileId = response.data.data.userprofile_id;

      const selectedModules = Object.entries(checkedParentModules)
        .filter(([_, isChecked]) => isChecked)
        .map(([moduleId]) => groupedSubModules[moduleId].moduleAcronym);

      const selectedSubModules = Object.entries(checkedModules)
        .filter(([_, isChecked]) => isChecked)
        .map(([subModuleId]) => {
          const subModule = Object.values(groupedSubModules)
            .flatMap((m) => m.subModules)
            .find((sub) => sub.idEndity === subModuleId);
          return subModule.acronym;
        });

      await api.put('/Farm/AtualizarModulosESubModulosDoPerfil', {
        profileId,
        moduleAcronyms: selectedModules,
        subModuleAcronyms: selectedSubModules
      });
      queryClient.invalidateQueries('ListarPerfis');
      toast.success('Perfil criado com sucesso!');
      navigate(-1);
    } catch (error) {
      console.error('Erro na submissão:', error);
      toast.error('Erro ao criar perfil');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              )
          }}
          style={{ backgroundColor: '#A0BD39' }}
        >
          <Typography
            sx={{ flex: '1 1 100%', color: '#fff' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title[type]}
          </Typography>
        </Toolbar>
      </Paper>
      <div style={{ display: 'flex', gap: 48, marginTop: 20, width: '80%' }}>
        <div style={{ flex: 0.8 }}>
          <Typography style={{ marginBottom: 8 }}>Nome</Typography>
          <Controller
            name="description"
            control={form.control}
            render={({ value, onChange }) => (
              <TextField
                variant="outlined"
                fullWidth
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '45px',
                    borderRadius: '10px',
                    '& fieldset': {
                      border: '1px solid #000'
                    }
                  }
                }}
              />
            )}
          />
          {!isUsuario && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFarmOwner}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    setIsFarmOwner(newValue);
                    const currentDescription =
                      form.methods.getValues('description');
                    const currentOrganizacao = newValue
                      ? form.methods.getValues('organizacao_id')
                      : form.methods.getValues('nomeOrganizacao');

                    if (newValue) {
                      form.methods.setValue(
                        'nomeOrganizacao',
                        currentOrganizacao
                      );
                      form.methods.setValue('organizacao_id', '');
                    } else {
                      form.methods.setValue(
                        'organizacao_id',
                        currentOrganizacao
                      );
                      form.methods.setValue('nomeOrganizacao', '');
                    }
                    form.methods.setValue('description', currentDescription);
                  }}
                />
              }
              disabled={type === 'edit'}
              label="É proprietário de fazenda?"
            />
          )}
        </div>
        {isFarmOwner ? (
          <div style={{ flex: 0.8 }}>
            <Typography style={{ marginBottom: 8 }}>
              Nome da Organização
            </Typography>
            <Controller
              name="nomeOrganizacao"
              control={form.control}
              render={({ value, onChange }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  value={
                    type === 'edit'
                      ? organizacaoRequest.items?.find(
                          (org) => org.idEndity === organizationId
                        )?.name
                      : value || ''
                  }
                  disabled={type === 'edit'}
                  onChange={({ target }) => onChange(target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '45px',
                      borderRadius: '10px',
                      '& fieldset': {
                        border: '1px solid #000'
                      }
                    }
                  }}
                />
              )}
            />
          </div>
        ) : (
          <div style={{ flex: 0.8 }}>
            <Typography style={{ marginBottom: 8 }}>Organização</Typography>
            <FormControl fullWidth style={{ height: 60 }}>
              <Controller
                name="organizacao_id"
                control={form.control}
                render={({ onChange, value, field }) => (
                  <Select
                    {...field}
                    style={{
                      borderRadius: '10px',
                      border: '1px solid #000',
                      height: '45px'
                    }}
                    value={value}
                    disabled={type === 'edit'}
                    onChange={(data) => onChange(data.target.value)}
                  >
                    {organizacaoRequest.items?.map((org) => (
                      <MenuItem key={org.idEndity} value={org.idEndity}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>
        )}
      </div>
      <hr
        style={{
          margin: '16px 0',
          height: '1px',
          border: 'none',
          backgroundColor: '#000'
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Typography
          style={{
            color: '#000',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            textAlign: 'center'
          }}
        >
          Módulos
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 6 }}>
          <Button
            variant="contained"
            style={{
              width: '200px',
              height: '40px',
              flexShrink: 0,
              borderRadius: '5px',
              background: '#A0BD39'
            }}
            onClick={handleMarkAll}
          >
            Marcar Todos
          </Button>
          <Button
            variant="contained"
            style={{
              width: '200px',
              height: '40px',
              flexShrink: 0,
              borderRadius: '5px',
              background: '#A0BD39'
            }}
            onClick={handleUnmarkAll}
          >
            Desmarcar Todos
          </Button>
        </div>
        <hr
          style={{
            margin: '0px 0',
            height: '1px',
            border: 'none',
            backgroundColor: '#000'
          }}
        />

        {Object.entries(groupedSubModules || {}).map(([moduleId, data]) => (
          <React.Fragment key={moduleId}>
            <Toolbar
              style={{
                backgroundColor: '#A0BD39',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              sx={{
                pl: { sm: 1.9 },
                pr: { xs: 1, sm: 1 }
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedParentModules[moduleId] || false}
                    onChange={(e) =>
                      handleModuleChange(moduleId, e.target.checked)
                    }
                  />
                }
                label={
                  <Typography
                    sx={{ flex: '1 1 100%', color: '#fff' }}
                    variant="subtitle1"
                  >
                    {data.moduleName}
                  </Typography>
                }
              />
              <Button
                variant="contained"
                style={{
                  height: '30px',
                  borderRadius: '5px',
                  background: '#608929',
                  marginRight: '10px'
                }}
                onClick={() => {
                  const newCheckedModules = { ...checkedModules };
                  data.subModules.forEach((subModule) => {
                    newCheckedModules[subModule.idEndity] = true;
                  });
                  setCheckedModules(newCheckedModules);
                  setCheckedParentModules({
                    ...checkedParentModules,
                    [moduleId]: true
                  });
                }}
              >
                Selecionar Todos
              </Button>
            </Toolbar>
            {data.subModules.map((subModule) => (
              <React.Fragment key={subModule.id}>
                <FormControlLabel
                  style={{ marginLeft: 6 }}
                  control={
                    <Checkbox
                      checked={checkedModules[subModule.idEndity] || false}
                      onChange={(e) =>
                        handleSubModuleChange(
                          moduleId,
                          subModule.idEndity,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={subModule.name}
                />
                <hr
                  style={{
                    margin: '-8px 0',
                    height: '1px',
                    border: 'none',
                    backgroundColor: '#E0E0E0'
                  }}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          display: 'flex',
          gap: '10px',
          zIndex: 1000
        }}
      >
        <button
          type="button"
          style={{
            padding: '10px',
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#dcdcdc',
            width: '125px'
          }}
          onClick={() => navigate(-1)}
        >
          Cancelar
        </button>
        <button
          type="button"
          style={{
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid',
            backgroundColor: form.formValid ? '#dcdcdc' : '#608929',
            color: 'white',
            width: '125px'
          }}
          onClick={handleSubmit}
          disabled={form.formValid}
        >
          {isSubmitting
            ? 'Enviando...'
            : type === 'edit'
            ? 'Atualizar'
            : 'Registrar'}
        </button>
      </div>
      {isSubmitting && <LoadingOverlay />}
    </Box>
  );
};

export default ProfileForm;
