import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../../../../../../hooks/Auth';
import OrganizacaoApi from '../../../../../../../../APi/FarmApi/OrganizacaoApi';
import { Organizacao } from '../../utils/organizacao.types';

export const useOrganizacaoListRequest = () => {
  const {
    token,
    user: { organizacao_id, user_id }
  } = useAuth();
  const organizacaoApi = useMemo(() => new OrganizacaoApi(token), []);
  const [filter, setFilter] = useState<string>('');

  function organizacaoMap(organizacao: any, index): Organizacao {
    return {
      idEndity: organizacao.id,
      id: index + 1,
      name: organizacao.name,
      activated: organizacao.active ? 'Ativado' : 'Desativado'
    };
  }
  const organizacaoQuery = useQuery({
    queryKey: [organizacaoApi.getOrganizacao, { filter }],
    queryFn: async () =>
      organizacaoApi.GetOrganizacao(filter, organizacao_id, user_id)
  });

  return {
    request: {
      items: organizacaoQuery.data?.data.data.map(organizacaoMap) ?? []
    },
    setFilter
  };
};
