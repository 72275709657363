import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Checkbox,
  Tooltip,
  Typography,
  IconButton,
  Toolbar,
  alpha,
  ThemeProvider,
  createTheme
} from '@mui/material';
import Confirm, { IConfirmHandles } from 'components/Confirm';
import { Icon } from 'components/Icons';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  sortable?: boolean;
}

export interface Data {
  [key: string]: any;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#212529'
    }
  }
});

interface Result {
  documents: Data[];
  next: string | null;
  previous: string | null;
  total: number;
}

interface SortableTableProps {
  label: string;
  keyOrder: string;
  idKey: string;
  fetch: (page: number, rowsPerPage: number) => Promise<Result>;
  columns: Column[];
  transform: (list: any[]) => Data[];
  paginated?: boolean;
  onCreate?: {
    label: string;
    onClick: () => void;
  };
  onEdit?: {
    label: string;
    onClick: (item: any) => void;
  };
  onDelete?: {
    label: string;
    onClick: (id: string | string[]) => Promise<void>;
  };
  onFilter?: {
    label: string;
    onClick: () => void;
  };
  onShow?: {
    label: string;
    onClick: (item: any) => void;
  };
  extraButtons?: {
    none?: { label: string; onClick: () => void; icon: React.ReactNode }[];
    one?: { label: string; onClick: () => void; icon: React.ReactNode }[];
    many?: { label: string; onClick: () => void; icon: React.ReactNode }[];
  };
  // lineButtons?: {
  //   label: string;
  //   buttons: { label: string; onClick: () => void; icon: React.ReactNode }[];
  // };
}

export interface SortableTableHandles {
  update: (item: any) => void;
  append: (item: any[]) => void;
}

const SortableTable: React.ForwardRefRenderFunction<
  SortableTableHandles,
  SortableTableProps
> = (
  {
    fetch,
    transform,

    idKey,

    label,
    columns,
    keyOrder,

    onEdit,
    onCreate,
    onDelete,
    onFilter,
    onShow,

    paginated,
    extraButtons
  }: SortableTableProps,
  ref
) => {
  const confirmRef = useRef<IConfirmHandles>(
    null
  ) as React.MutableRefObject<IConfirmHandles>;
  const [next, setNext] = useState(null);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [lastSeeing, setLastSeeing] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [data, setData] = useState<Data[]>([]);
  const [orderBy, setOrderBy] = useState<string>(keyOrder);
  const [selected, setSelected] = useState<string[]>([]);

  const handleSort = (columnId: string) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > lastSeeing) {
      const result = await fetch(next, rowsPerPage);

      setData(result.documents);
      setNext(result.next);

      setLastSeeing(newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = transform(data).map((n: Data) => n.id.toString());
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOnDelete = async (ids: string[]) => {
    onDelete && onDelete?.onClick(ids);
    setSelected
      ? setSelected(selected.filter((id) => !ids.includes(id)))
      : setSelected([]);
  };

  const handleConfirmDelete = () => {
    confirmRef.current?.open({
      animation: 'delete',
      message: 'Deseja realmente excluir o registro?',
      labelConfirm: 'Excluir',
      onConfirm: async () => {
        await handleOnDelete(selected);
      },
      labelCancel: 'Cancelar',
      onCancel: () => {
        console.log('Cancelar');
      }
    });
  };

  const handleOnCrete = () => {
    onCreate && onCreate.onClick();
  };

  const handleOnEdit = () => {
    const item = data.find((item) => item[idKey] === selected[0]);
    console.log(item);
    if (item) {
      onEdit && onEdit.onClick(item);
    }
  };

  const handleShowDetails = () => {
    const item = data.find((item) => item[idKey] === selected[0]);
    if (item) {
      onShow.onClick(item);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = selected
        .slice(0, selectedIndex)
        .concat(selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  interface EnhancedTableToolbarProps {
    numSelected: number;
    label: string;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected, label } = props;

    return (
      <Toolbar
        sx={{
          width: '100%',
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: '#A0BD39'
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%', color: '#fff' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} {numSelected === 1 ? 'Selecionado' : 'Selecionados'}
          </Typography>
        ) : (
          <Typography
            sx={{
              flex: '1 1 100%',
              color: '#fff'
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {label}
          </Typography>
        )}
        {numSelected > 0 ? (
          numSelected > 1 ? (
            <>
              {
                <Tooltip title={'Apagar'} key={'delete-button-many'}>
                  <IconButton
                    onClick={handleConfirmDelete}
                    style={{ color: '#fff' }}
                  >
                    <Icon iconName="Delete" style={{ color: '#fff' }} />
                  </IconButton>
                </Tooltip>
              }
              {extraButtons?.many?.map((button, index) => (
                <Tooltip title={button.label} key={button.label + index}>
                  <IconButton onClick={button.onClick}>
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </>
          ) : (
            <>
              {onEdit && (
                <Tooltip title={onEdit.label} key={'edit-button-one'}>
                  <IconButton onClick={handleOnEdit}>
                    <Icon iconName="Edit" />
                  </IconButton>
                </Tooltip>
              )}
              {onShow && (
                <Tooltip title={onShow.label} key={'show-button-one'}>
                  <IconButton onClick={handleShowDetails}>
                    <Icon iconName="Visibility" />
                  </IconButton>
                </Tooltip>
              )}
              {
                <Tooltip title={'Apagar'} key={'delete-button-one'}>
                  <IconButton onClick={handleConfirmDelete}>
                    <Icon iconName="Delete" />
                  </IconButton>
                </Tooltip>
              }

              {extraButtons?.one?.map((button, i) => (
                <Tooltip
                  title={button.label}
                  key={`extra-button-one${button.label}${i}`}
                >
                  <IconButton onClick={button.onClick}>
                    {button.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </>
          )
        ) : (
          <>
            {onCreate && (
              <Tooltip title={onCreate.label} key={'onCreate-button'}>
                <IconButton onClick={handleOnCrete}>
                  <Icon iconName="Add" />
                </IconButton>
              </Tooltip>
            )}
            {onFilter && (
              <Tooltip title={onFilter.label} key={'onFilter-button'}>
                <IconButton onClick={handleOnCrete}>
                  <Icon iconName="FilterAlt" />
                </IconButton>
              </Tooltip>
            )}
            {extraButtons?.none?.map((button, i) => (
              <Tooltip
                title={button.label}
                key={`extra-button-none${button.label}${i}`}
              >
                <IconButton onClick={button.onClick}>{button.icon}</IconButton>
              </Tooltip>
            ))}
          </>
        )}
      </Toolbar>
    );
  };

  const defaultLabelDisplayedRows = ({ from, to, count }: any) => {
    return (
      <div style={{ width: 200, margin: 0, padding: 0 }}>
        <Typography sx={{ marginTop: 2 }}>
          {`${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`}
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const first = await fetch(0, rowsPerPage);
      setData(first.documents);
      setNext(first.next);
      setCount(first.total);
      setLastSeeing(0);
    })();
  }, []);

  useEffect(() => {
    if (orderBy && order) {
      data.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  }, [order, orderBy, data]);

  React.useImperativeHandle(ref, () => ({
    update: (item) => {
      const updated = data.map((i) => {
        if (i[idKey] === item[idKey]) {
          return item;
        }
        return i;
      });
      setData(updated);
    },
    append: (item: any[]) => {
      setData([...data, ...item]);
      setCount((prev) => prev + 1);
    }
  }));

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          padding: '10px'
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} label={label} />
        <TableContainer
          style={{
            paddingTop: 0,
            border: '1px solid #F0F0F0',
            borderRadius: 8
          }}
        >
          <Table size="small">
            <TableHead
              style={{
                background: '#EFEFEF'
              }}
            >
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    style={{
                      color: '#A0BD39'
                    }}
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {columns.map((column) => {
                  if (column.id === 'id') {
                    return null;
                  }
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        color: '#212529'
                      }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                })}
                {false && (
                  <TableCell
                    key={'actions'}
                    align="center"
                    style={{
                      color: '#212529'
                    }}
                  >
                    Ações
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transform(data)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row?.id?.toString());

                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row?.id?.toString())
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: '#A0BD39'
                          }}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'id') {
                          return null;
                        }

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            size="small"
                            style={{
                              color: '#666',
                              width: 'auto'
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                      {false && (
                        <TableCell
                          key={'actions'}
                          align="center"
                          style={{ color: '#666' }}
                        >
                          <Tooltip title={'dsdss'} key={'dsds'}>
                            <IconButton>Yolo</IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows
                  }}
                >
                  <TableCell colSpan={columns.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {paginated && (
          <TablePagination
            style={{ width: '100%', margin: 0, padding: 0, color: '#666' }}
            align="right"
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <Typography sx={{ marginTop: 2 }}>Linhas por página</Typography>
            }
            labelDisplayedRows={defaultLabelDisplayedRows}
            showLastButton
            showFirstButton
          />
        )}
        <Confirm ref={confirmRef} />
      </div>
    </ThemeProvider>
  );
};

export default forwardRef(SortableTable);
