import styled from 'styled-components';

export const title = styled.span`
  font-size: 24px;
  font-weight: 500;
`;
export const container = styled.div`
  display: flex;
  flex: 1;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  padding: 10px 10px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
`;

export const containerbotton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
`;

export const headerWithButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
`;
