import React, { useContext } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { DashboardContext } from '../../../../index';
import Table from '../Table';
import { Container } from './styled';
import TableNematode from '../TableNematode';

const ResultReport = () => {
  const { showResult, selectedOption, setSelectedOption, options, reportType } =
    useContext(DashboardContext);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <Container>
      {reportType === 'soilAnalysis' && <Table />}
      {reportType === 'nematodeAnalysis' && <TableNematode />}
    </Container>
  );
};
export default ResultReport;
