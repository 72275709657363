/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  TextField,
  Checkbox,
  useMediaQuery,
  Grid,
  FormControlLabel,
  Button,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import { Chrono } from 'react-chrono';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useFormik } from 'formik';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal, { ModalHandles } from 'components/Modal';
import { FormActions, FormActionsEnum } from 'Enums';
import { Harvest } from 'components/ReporUpload';
import { Close, Delete, Edit, Save, Visibility } from '@mui/icons-material';
import Toast from '../../../../../../../components/Toast';
import { useModal } from '../../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../../hooks/Auth';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';
import { ResearchField } from '../../../../../../../components/ResearchField';
import * as s from './styles';
import {
  uuid,
  dateNow,
  parseDate,
  dateTimeNow,
  formatDate,
  dateTimeFriendly
} from '../../../../../../../util';

export interface HarvestFormProps {
  onCreate?: (harvest: Harvest) => Promise<void>;
  onUpdate?: (harvest: Harvest) => void;
  onDetails?: () => void;
}

const items = [
  {
    title: 'May 1940',
    cardTitle: 'Dunkirk',
    url: 'http://www.history.com',
    cardSubtitle: 'Men of the British Expeditionary Force (BEF) wade out to..',
    cardDetailedText:
      'Men of the British Expeditionary Force (BEF) wade out to..',
    media: {
      type: 'IMAGE',
      source: {
        url: 'http://someurl/image.jpg'
      }
    }
  }
];

export interface HarvestFormHandles {
  create: () => void;
  update: (item: Harvest) => void;
  details: () => void;
  close: () => void;
}

const HarvestForm: React.ForwardRefRenderFunction<
  HarvestFormHandles,
  HarvestFormProps
> = ({ onCreate, onDetails, onUpdate }: HarvestFormProps, ref) => {
  const modalRef = React.useRef<ModalHandles>(null);
  const [formAction, setFormAction] = React.useState<FormActions | null>(null);

  const [modalIsOpen, setIsOpen] = React.useState(true);
  const { modalCreatUserStyles } = useModal();
  const isMobile = useMediaQuery('(max-width: 560px)');

  const navigate = useNavigate();
  const { role, user } = useAuth();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');

  const [context, setContext] = React.useState<Harvest | null>(null);

  const schema = yup.object().shape({
    harvest_id: yup.string(),
    harvest_name: yup
      .string()
      .required('Campo obrigatório.')
      .min(
        11,
        'Mínimo de 11 caracteres, no formato (YYYY - YYYY 2025 - 2026).'
      ),
    start_date: yup
      .string()
      .required('Campo obrigatório.')
      .test({
        name: 'start_date',
        message: 'Data deve ser menor que o fim.',
        test: (value, context) => {
          const start = parseDate(value, 'YYYY-MM-DD');
          const end = parseDate(context.parent.end_date, 'YYYY-MM-DD');
          return start.isBefore(end);
        }
      }),
    end_date: yup
      .string()
      .required('Campo obrigatório.')
      .test({
        name: 'end_date',
        message: 'Data deve ser maior que o início.',
        test: (value, context) => {
          const end = parseDate(value, 'YYYY-MM-DD');
          const start = parseDate(context.parent.start_date, 'YYYY-MM-DD');
          return end.isAfter(start);
        }
      }),
    created_by: yup.string(),
    created_at: yup.string(),
    active: yup.boolean().required('Campo obrigatório.')
  });

  const formik = useFormik({
    initialValues: {
      harvest_id: '',
      harvest_name: '',
      start_date: dateNow(),
      end_date: dateNow(),
      active: true,
      created_by: user.user_id,
      created_at: '',
      updated_by: null,
      updated_at: null,
      deleted_by: null,
      deleted_at: null,
      current_harvest: null
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!user.user_id) {
        toast.error('Usuário não encontrado.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true
        });
      }

      if (formAction === FormActionsEnum.create) {
        await onCreate({
          ...values,
          created_at: dateTimeNow()
        } as Harvest);
        return;
      }

      if (formAction === FormActionsEnum.update) {
        await onUpdate({
          ...values,
          updated_at: dateTimeNow()
        } as Harvest);
        return;
      }

      if (formAction === FormActionsEnum.show) {
        await onDetails();
        return;
      }

      toast.error('Ação não encontrada.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick
      });
    }
  });

  const title: Record<string, string> = {
    create: 'Cadastro de safra',
    update: 'Editar  safra',
    show: 'Detalhes da safra'
  };

  const modalContentDefault = {
    content: {
      width: '80vw',
      height: '70vh',
      inset: 0,
      transform: 'translate(11%, 10%)'
    }
  };

  const modalContentMobile = {
    content: {
      height: '100vh',
      inset: 0
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const openModal = () => {
    modalRef.current.openModal();
  };

  const closeModal = () => {
    modalRef.current.closeModal();
  };

  React.useImperativeHandle(ref, () => ({
    create: () => {
      formik.setValues({
        harvest_name: '',
        start_date: dateNow(),
        end_date: dateNow(),
        active: true,
        created_by: user.user_id
      });
      setFormAction(FormActionsEnum.create);
      openModal();
    },
    update: (item) => {
      formik.setValues({
        harvest_id: item.harvest_id,
        harvest_name: item.harvest_name,
        start_date: formatDate(item.start_date, 'YYYY-MM-DD'),
        end_date: formatDate(item.end_date, 'YYYY-MM-DD'),
        active: item.active,
        created_by: item.created_by,
        created_at: item.created_at,
        updated_by: user.user_id,
        updated_at: item.updated_at,
        deleted_by: item.deleted_by,
        deleted_at: item.deleted_at,
        current_harvest: item.current_harvest
      } as Harvest);
      setFormAction(FormActionsEnum.update);
      openModal();
    },
    details: (item) => {
      formik.setValues(item);
      setFormAction(FormActionsEnum.show);
      openModal();
    },
    close: () => {
      closeModal();
    }
  }));

  const DetailField = ({
    label,
    value,
    position = 'left',
    size = 6
  }: {
    label: string;
    value?: React.ReactNode;
    position?: 'left' | 'right';
    size?: number;
  }) => (
    <Grid
      item
      sm={size}
      display={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
      }}
    >
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          display: 'flex',
          alignSelf: position === 'left' ? 'flex-start' : 'flex-end',
          color: '#151B23'
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          display: 'flex',
          alignSelf: position === 'left' ? 'flex-start' : 'flex-end',
          fontWeight: '600',
          fontSize: 18,
          color: '#151B23'
        }}
      >
        {value ?? '—'}
      </Typography>
    </Grid>
  );

  const activeIcon = (active: FormActions) => {
    if (active === FormActionsEnum.create) {
      return <Save />;
    }
    if (active === FormActionsEnum.update) {
      return <Edit />;
    }
    if (active === FormActionsEnum.show) {
      return <Visibility />;
    }
    return null;
  };

  const activeLabel = (active: FormActions) => {
    if (active === FormActionsEnum.create) {
      return 'Salvar';
    }
    if (active === FormActionsEnum.update) {
      return 'Editar';
    }
    if (active === FormActionsEnum.show) {
      return 'Detalhes';
    }
    return null;
  };

  return (
    <Modal ref={modalRef}>
      <s.container>
        {formAction === FormActionsEnum.show ? (
          <s.Content>
            <s.headerWithButtons>
              <s.title>{title[formAction]}</s.title>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    formik.setValues({
                      harvest_id: formik.values.harvest_id,
                      harvest_name: formik.values.harvest_name,
                      start_date: formatDate(
                        formik.values.start_date,
                        'YYYY-MM-DD'
                      ),
                      end_date: formatDate(
                        formik.values.end_date,
                        'YYYY-MM-DD'
                      ),
                      active: formik.values.active,
                      created_by: user.user_id,
                      created_at: formik.values.created_at,
                      updated_by: user.user_id,
                      updated_at: formik.values.updated_at,
                      deleted_by: formik.values.deleted_by,
                      deleted_at: formik.values.deleted_at
                    });
                    setFormAction(FormActionsEnum.update);
                  }}
                  endIcon={<Edit />}
                >
                  Editar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => closeModal()}
                  endIcon={<Delete />}
                >
                  Excluir
                </Button>
              </div>
            </s.headerWithButtons>
            <Grid container spacing={2}>
              <DetailField
                size={12}
                position="right"
                label="Nome da safra"
                value={formik.values.harvest_name}
              />

              <DetailField
                position="left"
                label="Data de início"
                value={formatDate(formik.values.start_date, 'DD/MM/YYYY')}
              />
              <DetailField
                position="right"
                label="Data de fim"
                value={formatDate(formik.values.end_date, 'DD/MM/YYYY')}
              />

              <DetailField
                position="left"
                label="Safra atual"
                value={formik.values.current_harvest ? 'Sim' : 'Não'}
              />
              <DetailField
                position="right"
                label="Ativa"
                value={formik.values.active ? 'Sim' : 'Não'}
              />

              <DetailField
                size={4}
                position="left"
                label="Criado em"
                value={dateTimeFriendly(formik.values.created_at)}
              />
              <DetailField
                size={8}
                position="right"
                label="Criado por"
                value={formik.values.created_by_name}
              />
              {formik.values.updated_at && (
                <>
                  <DetailField
                    size={4}
                    position="left"
                    label="Atualizado em"
                    value={dateTimeFriendly(
                      formik.values.updated_at,
                      'DD/MM/YYYY HH:mm'
                    )}
                  />
                  <DetailField
                    size={8}
                    position="right"
                    label="Atualizado por"
                    value={formik.values.updated_by_name}
                  />
                </>
              )}
              {formik.values.deleted_at && (
                <>
                  <DetailField
                    size={4}
                    position="left"
                    label="Excluído em"
                    value={dateTimeFriendly(
                      formik.values.deleted_at,
                      'DD/MM/YYYY HH:mm'
                    )}
                  />
                  <DetailField
                    position="right"
                    size={8}
                    label="Excluído por"
                    value={formik.values.deleted_by_name}
                  />
                </>
              )}
            </Grid>
            <s.containerbotton>
              <Button variant="contained" onClick={closeModal}>
                Fechar
              </Button>
            </s.containerbotton>
          </s.Content>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <s.Content>
              <s.headerWithButtons>
                <s.title>{title[formAction]}</s.title>
              </s.headerWithButtons>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    name="harvest_name"
                    onChange={formik.handleChange}
                    value={formik.values.harvest_name}
                    label="Nome da safra"
                    variant="outlined"
                    fullWidth
                    error={!!formik.errors?.harvest_name}
                    helperText={
                      formik.errors?.harvest_name
                        ? formik.errors?.harvest_name
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="start_date"
                    onChange={formik.handleChange}
                    value={formik.values.start_date}
                    label="Data de início"
                    type="date"
                    variant="outlined"
                    fullWidth
                    error={!!formik.errors?.start_date}
                    helperText={
                      formik.errors?.start_date ? formik.errors?.start_date : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="end_date"
                    onChange={formik.handleChange}
                    value={formik.values.end_date}
                    label="Data de fim"
                    type="date"
                    variant="outlined"
                    fullWidth
                    error={!!formik.errors?.end_date}
                    helperText={
                      formik.errors?.end_date ? formik.errors?.end_date : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="active"
                        checked={formik.values.active}
                        onChange={(e) =>
                          formik.setFieldValue('active', e.target.checked)
                        }
                      />
                    }
                    label="Ativa"
                  />
                </Grid>
              </Grid>

              <s.containerbotton>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => closeModal()}
                  endIcon={<Close />}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  color="success"
                  endIcon={activeIcon(formAction)}
                >
                  {activeLabel(formAction)}
                </Button>
              </s.containerbotton>
            </s.Content>
          </form>
        )}
      </s.container>
    </Modal>
  );
};

export default React.forwardRef(HarvestForm);
