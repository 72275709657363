/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { fromLonLat } from 'ol/proj';
import { Feature, Overlay } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';

import { Pagination, Stack } from '@mui/material';
import { render } from 'react-dom';
import {
  HistoriesByRoutine,
  Routines
} from '../../../../../../../../services/get-histories-by-routine';
import { parseWKTPoint } from '../../../../../../../../util/parseWKTPoint';
import { useAuth } from '../../../../../../../../hooks/Auth';
import { useMap } from '../../../../../../../../hooks/Map';
import { formatDate } from '../../../../../../../../helpers/formatDate';
import { DashboardContext } from '../../../../../..';
import MonitoringPopup from './components/monitoring-popup';

let overlayElement: HTMLDivElement | null = null;
let currentOverlay: Overlay | null = null;

export function ViewCollectionMonitoring({
  data,
  doneRoutineList,
  totalPagesDoneRoutine,
  handleChangePageDoneRoutine
}: any) {
  const { token } = useAuth();
  const [map] = useState();
  const mapRef = useRef();

  const {
    raster,
    newMap,
    renderFarm,
    selectedFarmGeoJson,
    setRefMap3,
    refMap3,
    renderFields
  } = useMap();

  const { fields } = useContext(DashboardContext);

  const [collection, setCollection] = useState<Routines>(data);

  mapRef.current = map;

  const formatRoutineDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  function removeLayerMonitoringPoints() {
    refMap3.getLayers().forEach((layer) => {
      if (layer.get('name') === 'monitoring-points') {
        refMap3.removeLayer(layer);
      }
    });

    if (currentOverlay) {
      refMap3.removeOverlay(currentOverlay);
      currentOverlay = null;
    }
  }

  async function handleCollectionMonitoring(
    routine: Routines,
    originMap = null
  ) {
    setCollection(routine);

    const routines = await HistoriesByRoutine.getHistoriesByRoutine(
      routine.routine_id,
      token
    );

    const uniqueCoordinates = new Map();

    for (const historyRoutine of routines) {
      const coordinates = parseWKTPoint(historyRoutine.coordinates);

      if (
        !coordinates ||
        coordinates.length !== 2 ||
        coordinates.includes(null) ||
        coordinates.includes(undefined) ||
        coordinates.some(isNaN)
      ) {
        console.warn('Coordenadas inválidas:', coordinates);
        continue;
      }

      const transformedCoords = fromLonLat(coordinates);
      const key = `${transformedCoords[0]},${transformedCoords[1]}`;
      uniqueCoordinates.set(key, {
        coords: transformedCoords,
        data: historyRoutine
      });
    }

    const markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: '/assets/point.png',
        scale: 0.2
      })
    });

    const vectorSource = new VectorSource({
      features: Array.from(uniqueCoordinates.values()).map(
        ({ coords, data }) => {
          const marker = new Feature({
            geometry: new Point(coords),
            data
          });

          marker.setStyle(markerStyle);
          return marker;
        }
      )
    });

    const mapRef = refMap3 ?? originMap;

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 999
    });

    vectorLayer.set('name', 'monitoring-points');

    mapRef.addLayer(vectorLayer);

    if (!overlayElement) {
      overlayElement = document.createElement('div');
      overlayElement.id = 'overlay-container';
      overlayElement.style.width = '600px';
      overlayElement.style.height = '323px';

      document.body.appendChild(overlayElement);
    }

    if (!currentOverlay) {
      currentOverlay = new Overlay({
        element: overlayElement,
        positioning: 'bottom-left',
        offset: [5, -198],
        autoPan: true
      });

      mapRef.addOverlay(currentOverlay);
    }

    mapRef.un('click', handleMapClick);
    mapRef.on('click', handleMapClick);

    function handleMapClick(event) {
      const feature = mapRef.forEachFeatureAtPixel(event.pixel, (feat) => feat);

      if (feature) {
        const data = feature.get('data');
        if (data) {
          const coords = feature.getGeometry().getCoordinates();
          currentOverlay.setPosition(coords);

          const newData = {
            ...routine,
            reference_id: data.reference
          };

          render(
            <MonitoringPopup data={newData} token={token} />,
            overlayElement
          );
          return;
        }
      }

      currentOverlay.setPosition(undefined);
    }
  }

  const mapLayer = raster();

  useEffect(() => {
    const borderColor = '#5596A9';
    const maxZoom = 18;

    renderFarm(selectedFarmGeoJson, borderColor, maxZoom);
    renderFields(fields);

    setTimeout(() => {
      handleCollectionMonitoring(collection, refMap3);
    }, 1000 * 1);
  }, [refMap3]);

  useEffect(() => {
    let isMounted = true;

    const originMap = newMap(mapRef.current, mapLayer);
    setRefMap3(originMap);

    return () => {
      isMounted = false;
    };
  }, [mapRef]);

  return (
    <div
      style={{
        marginTop: 20,
        width: '100%',
        height: '100%',
        display: 'flex'
      }}
    >
      <div style={{ flex: 0.3 }}>
        <h3 style={{ margin: '10px 0px 10px 10px' }}>Monitoramento</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            overflowY: 'scroll',
            height: '68vh',
            marginBottom: 10,
            gap: 10
          }}
        >
          {doneRoutineList?.map((list) => (
            <div
              key={list.routine_id}
              onClick={() => {
                removeLayerMonitoringPoints();
                handleCollectionMonitoring(list);
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                border:
                  collection.routine_id === list.routine_id
                    ? '2px dashed #94B031'
                    : '1px solid #ccc',
                borderRadius: 10,
                textAlign: 'center',
                cursor: 'pointer',
                padding: 5
              }}
            >
              <p style={{ color: '#94B031', fontWeight: 'bold' }}>
                {list.title}
              </p>
              <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                {list.executor_name ?? ''} - {formatDate(list.end_date)} -{' '}
                {list?.end_date ? formatRoutineDate(list?.end_date) : '-'}
              </p>
              <p style={{ fontWeight: 'bold', fontSize: 12 }}>
                {list.encompass_name}
              </p>
            </div>
          ))}
        </div>
        <Stack spacing={1}>
          <Pagination
            count={totalPagesDoneRoutine}
            variant="outlined"
            shape="rounded"
            size="small"
            onChange={handleChangePageDoneRoutine}
          />
        </Stack>
      </div>
      <div
        ref={mapRef}
        style={{ flex: 1, border: '1px solid #ccc', borderRadius: 10 }}
      />
    </div>
  );
}
