/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import { HeadCell } from '../../../../../../../components/OrderedTable/utils/OrderedTable.types';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { Profile } from './utils/profile.types';
import CreateIcon from '../../../../../../../assets/Create.png';
import DeleteIcon from '../../../../../../../assets/Delete Trash.png';
import { useProfileListRequest } from './hooks/useProfileListRequest';
import ProfileApi from '../../../../../../../APi/FarmApi/ProfileApi';
import api from '../../../../../../../APi/FarmApi/configFarmApi';

const ProfileList: FC = () => {
  const navigate = useNavigate();
  const { request, setFilter } = useProfileListRequest();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const confirmationModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      height: '150px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '20px'
    }
  };

  const handleDeleteClick = (id: string) => {
    setSelectedId(id);
    setModalIsOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedId) {
      try {
        await api.delete(`/Farm/DeletarPerfil/${selectedId}`);
        toast.success('Perfil deletado com sucesso');
        request.refetch();
      } catch (error) {
        toast.error('Erro ao deletar perfil');
      }
    }
    setModalIsOpen(false);
  };

  const isFormRoute =
    location.pathname.includes('/new') || location.pathname.includes('/edit');
  if (isFormRoute) {
    return <Outlet />;
  }

  const handleDelete = async (id: string) => {
    try {
      await api.delete(`/Farm/DeletarPerfil/${id}`);
      request.refetch();
    } catch (error) {
      toast.error('Erro ao deletar perfil');
    }
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleEdit = (
    id: string,
    organizationId: string,
    isFarmOwn: boolean,
    name: string
  ) => {
    navigate(`./edit/${id}`, {
      state: {
        id,
        organizationId,
        isFarmOwn,
        name
      }
    });
  };

  const headCells: HeadCell<Profile>[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome'
    },
    {
      id: 'activated',
      numeric: false,
      disablePadding: false,
      label: 'Ativo'
    },
    {
      id: 'organizacao',
      numeric: false,
      disablePadding: false,
      label: 'Organização'
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Ações'
    }
  ];

  const rows = request.items.map((item, index) => ({
    ...item,
    organizacao: `${item.nomeOrganizacao}`,
    actions: (
      <TableCell padding="none">
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(
              item.idEndity,
              item.organizacaoId,
              item.isFarmOwner,
              item.name
            );
          }}
          style={{
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            padding: 0
          }}
        >
          <img
            src={CreateIcon}
            alt="Edit"
            style={{ width: '24px', height: '24px', flexShrink: 0 }}
          />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(item.idEndity);
          }}
          style={{
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            padding: 0
          }}
        >
          <img
            src={DeleteIcon}
            alt="Delete"
            style={{ width: '24px', height: '24px', flexShrink: 0 }}
          />
        </button>
      </TableCell>
    )
  }));

  return (
    <>
      <ResearchField onChange={setFilter} />
      <OrderedTable
        rows={rows}
        HeadCell={headCells}
        IconCreate={PostAddIcon}
        title="Perfil de Usuários"
        routeNameAdd="./new"
        routeNameEdit="./edit"
        checkBox={false}
        selectInLine
        hideEdit
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={confirmationModalStyle}
      >
        <h2>Confirmar exclusão</h2>
        <p>Tem certeza que deseja excluir este perfil?</p>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <button
            type="button"
            onClick={() => setModalIsOpen(false)}
            style={{
              padding: '8px 16px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={handleConfirmDelete}
            style={{
              padding: '8px 16px',
              backgroundColor: '#ff4444',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Confirmar
          </button>
        </div>
      </Modal>
      <Outlet />
    </>
  );
};

export default ProfileList;
