/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import EnhancedTableHead from './components/EnhancedTableHead/EnhancedTableHead';
import { EnhancedTableToolbar } from './components/EnhancedTableToolbar';
import { OrderedTableProps } from './utils/OrderedTable.types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function OrderedTable<T>(props: OrderedTableProps<T>) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [dense, setDense] = useState<boolean>(true);
  const [idaggregate, setIdaggregate] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const {
    HeadCell,
    rows,
    title,
    routeNameAdd = '',
    IconCreate,
    routeNameEdit = '',
    onDelete,
    checkBox = true,
    selectInLine = false,
    deleteHitch = false
  } = props;

  const handleRequestSort = (event: React.MouseEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.idEndity);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent, row: any) => {
    const selectedIndex = selected.indexOf(row.idEndity);
    const newSelected =
      selectedIndex === -1
        ? [row.idEndity]
        : selected.filter((x) => x !== row.idEndity);

    if (row?.idaggregate) setIdaggregate(row.idaggregate);

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function stableSort<U, R>(array: R[], comparator: (a: R, b: R) => number) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orde = comparator(a[0], b[0]);
      if (orde !== 0) {
        return orde;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const visibleRows = useMemo(
    () =>
      stableSort<T, any>(rows, getComparator(order, orderBy as string)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          routeNameAdd={routeNameAdd.toString()}
          IconCreate={IconCreate}
          routeNameEdit={routeNameEdit.toString()}
          selectedId={selected[0]}
          onDelete={onDelete}
          setSelected={setSelected}
          idaggregate={idaggregate}
          deleteHitch={deleteHitch}
          hideEdit={props.hideEdit}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy.toString()}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              HeadCell={HeadCell}
              checkBox={checkBox}
            />
            <TableBody>
              {visibleRows.map((row, inde) => {
                const isItemSelected = isSelected(row.idEndity);
                const labelId = `enhanced-table-checkbox-${inde}`;
                const dates = Object.keys(row);

                if (HeadCell.length < dates.length) {
                  dates.forEach((item, index) => {
                    const result = HeadCell.find((data) => data.id === item);
                    if (!result) delete dates[index];
                  });
                }
                const isGreen = idaggregate
                  ? isItemSelected && row.idaggregate === idaggregate
                  : isItemSelected;
                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (!checkBox && !selectInLine) return;

                      handleClick(event, row);
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={
                      idaggregate
                        ? isItemSelected && row.idaggregate === idaggregate
                        : isItemSelected
                    }
                    sx={{ cursor: 'pointer' }}
                    style={{
                      background: isGreen && '#A0BD39'
                    }}
                  >
                    {checkBox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          name={row.id}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                    )}
                    {dates.map((date, index) => (
                      <TableCell
                        component="th"
                        id={index === 0 ? labelId : ''}
                        scope="row"
                        padding="none"
                        style={{
                          paddingLeft: 15,
                          color: isGreen && '#fff'
                        }}
                      >
                        {row[date]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
    </Box>
  );
}

export default OrderedTable;
